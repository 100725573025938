.dueDateOuter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 4px 0;
  border-bottom: 1px solid #dbe3e6;
  width: 100%;
  min-height: 24px;
  position: relative;
}

.dueDateOuter :global(.ant-picker) {
  width: 100%;
}

.dueDateOuter :global(.ant-picker-input-wrap) {
  display: none;
}
