.container {
  width: 324px;
  min-height: 202px;
  max-height: 605px;
  overflow: auto;
  background-color: var(--borderColor);
  border-radius: 5px;
}

.projectsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 300px;
}

.emptyStateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 242px;
  gap: 10px;
}

.emptyStateImage {
  width: 110px;
  height: 110px;
}

.content {
  padding: 10px 12px 0px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.header {
  margin: 0px;
}

.listHeader {
  margin: 0px;
  padding: 0px 4px;
}

.footer {
  background-color: var(--borderColor);
  position: sticky;
  bottom: 0;
  padding: 10px 12px;
  box-shadow: 0px -2px 8px 0px rgba(0, 57, 128, 0.1);
}

.browseProjectsButton {
  width: 100%;
  border-radius: 4px;
}

.hidden {
  display: none;
}

.projectList {
  margin: 0px -4px;
}

.projectList ul {
  width: 100%;
}

.link {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 6px;
}

.projectItem {
  color: var(--colorTextBase);
  border-radius: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  display: block;
  padding: 2px 4px;
}

.projectItem:hover {
  background-color: var(--colorBgAccent3);
}

.divider {
  background: rgba(255, 255, 255, 1);
  margin: 12px 0px !important;
  height: 2px !important;
}

.starIcon {
  width: 18px;
  height: 18px;
  stroke: var(--colorSeverityImportant);
  fill: var(--colorSeverityImportant);
  margin-bottom: 1px;
}

.loadingMessage {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--borderColor);
  color: #848484;
  width: 100%;
  height: 210px;
  border-radius: 8px;
  font-weight: 600;
}

.loadingMessage p {
  max-width: 250px;
}
