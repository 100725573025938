.inputBorderSucccess :global(.ant-input-group) {
  border: 1.5px solid #3f71e0;
  border-radius: 5px;
  padding: 1px;
}

.inputBorderWarn :global(.ant-input-group) {
  border: 1.5px solid rgb(250, 173, 20);
  border-radius: 5px;
  padding: 1px;
}

.inputBorderError :global(.ant-input-group) {
  border: 1.5px solid red;
  border-radius: 5px;
  padding: 1px;
}

.inputBorder :global(.ant-input-group-addon) {
  border: 0;
}

.inputBorder :global(.ant-input-group .ant-input) {
  border: 0;
}

.urlInputItem :global(.ant-form-item-explain) {
  margin-top: 3px;
  margin-bottom: 5px;
}

.urlInputItem :global(.ant-input-group-addon:last-child) {
  background-color: var(--colorTextLightSolid);
}

.urlInputItem :global(.ant-tooltip-inner) {
  width: 150px;
  max-height: 150px;
  white-space: pre-wrap;
  font-size: 10px;
}

.validationIconSuccess {
  color: #52c41a;
}

.validationIconWarn {
  word-break: break-all;
}

.inputBorder :global(.ant-input-group .ant-input) {
  border: 0;
}

.inputBorder :global(.ant-input) {
  font-weight: 400;
}

.selectBefore :global(.ant-select-selector) {
  background-color: #f1f1f1 !important;
}

.inputBorder :global(.ant-input):focus {
  box-shadow: none;
}
