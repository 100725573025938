.welcome {
  border-radius: 10px;
  background-color: var(--colorBgAccent);
  padding: 0 30px 30px;
  position: relative;

  @media (width < 992px) {
    margin-right: 1rem;
  }
}

@media (max-width: 768px) {
  .welcome {
    max-width: 100%;
    width: fit-content;
  }
}

.welcome * {
  color: var(--colorText);
}

.welcomeHeader {
  margin-top: 30px;
  margin-bottom: 0.7em;
  font-weight: 700;
}

.welcomeHeader span {
  margin-right: 5px;
}

.welcome p {
  font-weight: 300;
}

.welcome a {
  color: var(--colorPrimary);
  font-weight: bold;
}
