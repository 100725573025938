.welcomeModal :global(.ant-modal-content) {
  padding: 0;
}

.welcomeModalContainer {
  padding: 30px 30px 40px 30px;
  text-align: center;
  background-color: #3f71e0;
  color: white;
  border-radius: 12px;
  background-image: url('./WelcomeContent/pattern-group.png');
  background-repeat: no-repeat;
  background-size: 150% 24%;
  background-position: -240px 101%;
}

.welcomeModalTitle {
  color: white;
  margin-bottom: 20px;
}

.welcomeModalText {
  width: 230px;
  margin: 0 auto 20px auto;
}

.userDetails {
  background: white;
  border-radius: 12px;
  padding: 40px 25px;
  border: 2px solid #d9e7f4;
}

.introVideoContainer {
  text-align: center;
  padding: 40px 20px;
}

.introVideoTitle {
  margin-bottom: 20px;
}

.introVideoText {
  margin-bottom: 20px;
}

.introVideo {
  margin-bottom: 20px;
  border: none;
}

.inputsContainer {
  text-align: left;
  color: #000000e0;
  margin-bottom: 20px;
}

.userNameInput {
  margin-bottom: 15px;
}
