.title {
  color: #19222a;
  font-size: 24px;
  font-weight: 900;
  text-align: center;
  margin: 0;
}

.form {
  margin: 20px 0 0 0;
}

.form :global(.ant-form-item-explain-error) {
  padding: 10px 0;
}

.center {
  text-align: center;
}