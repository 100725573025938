.removeIcon {
  color: #bfbfbf;
  max-width: 17px;
}

.membersBox {
  display: flex;
  justify-content: space-between;
  padding: 10px 0 5px 0;
  align-items: center;
  flex-wrap: wrap;
}

.membersLeft {
  max-width: 45%;
  width: 100%;
  display: flex;
  align-items: center;
}

.membersName {
  padding-left: 10px;
  font-weight: 300;
  min-width: 200px;
}

.controlsBox {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  max-width: 150px;
}

.displayName {
  position: relative;
}

.notConfirmed {
  font-style: oblique;
  font-size: 12px;
  width: 130px;
  color: rgb(132, 132, 132);
}

.invited {
  font-weight: 500;
}

.invitedBox {
  max-width: 100px;
  width: 100%;
  text-align: center;
}

.userSettings {
  max-width: 52.5%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.controlsPadded {
  padding: 4px 0;
}

.removeIcon {
  cursor: pointer;
}

.roleSelect {
  width: 100px;
}

.matchedTerm {
  background-color: #e7f7fc;
}

.innerEmail,
.userEmail {
  margin-left: 7px;
  font-size: 12px;
  color: #a8a6a6;
  max-width: 130px;
}

.lastUser {
  margin-bottom: 10px;
}

.innerDisplayName {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.editAccess {
  color: #406cff;
  font-weight: 300;
}

@media (max-width: 991px) {
  .membersBox {
    gap: 8px;
  }

  .membersLeft {
    max-width: 100%;
  }

  .userSettings {
    max-width: 100%;
  }
}