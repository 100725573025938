.popover {
  max-width: 220px;
}

.popoverContent p {
  display: flex;
  justify-content: space-between;
}

.popoverIcon {
  padding-right: 8px;
  padding-top: 3px;
  color: rgb(250, 173, 20);
}

.okContainer {
  display: flex;
  justify-content: flex-end;
}

.locationSave {
  background-color: #3f71e0;
}

.locationSave:hover {
  background-color: #1a49b0;
}

.locationSave:active,
.locationSave:focus,
.locationSave:visited {
  background-color: #133683;
}
