.container {
  position: absolute;
  top: -66px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  background: white;
  border: 1px solid #d5d1d1;
  padding: 11px 15px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 10px 10px 0 rgba(0, 57, 128, 0.2);
}

.undoButton {
  display: flex;
  align-items: center;
  color: #3f71e0;
  margin-left: 10px;
}

.undoButton:hover,
.undoButton:focus {
  background-color: white;
  color: #1a49b0;
  border-color: white;
}

.rotateIcon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.latestBulkAction {
  white-space: nowrap;
}

.checkContainer {
  border-radius: 50%;
  background-color: #52c41a;
  color: white;
  display: flex;
  align-items: center;
  margin-right: 10px;
  justify-content: center;
}

.checkIcon {
  width: 20px;
  height: 20px;
  padding: 2px;
}

.closeContainer {
  min-width: 30px;
  min-height: 30px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f5eeed;
  color: #d46060;
  cursor: pointer;
  margin-left: 10px;
}

.closeIcon {
  width: 14px;
  height: 14px;
}

.loaderIcon {
  margin-right: 10px;
}
