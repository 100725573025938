.multiSelectFilter {
  display: block;
}

.multiSelect {
  padding-top: 4px;
  padding-bottom: 4px;
  text-transform: capitalize;
}

.multiSelectCheckboxGroup {
  display: flex;
  flex-direction: column;
}
