.hero {
  background-color: #3f71e0;
  padding-bottom: 0;
  min-height: 100vh;
  margin-bottom: -2rem;
}

.inner {
  padding: 40px 28px 28px 28px;
}

.socialSsoIcon {
  margin-right: auto;
}

.submitSocialSso {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #e5e4e5;
  margin-top: 5px;
  font-size: 14px;
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

.submitSocialSso > span {
  margin-right: auto;
}

.submitSocialSso:hover,
.submitSocialSso:focus,
.submitSocialSso:visited {
  background-color: rgba(26, 73, 176, 0.15);
  border: 1px solid #3f71e0;
  color: var(--colorPrimary) !important;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.registerWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin-inline: auto;
  min-height: 100vh;
  background: #3f71e0;
  width: 100%;
}

@media (max-width: 990px) {
  .registerWrapper {
    padding: 0 12px 2rem 12px;
  }
}

.registerWrapper a {
  font-weight: bold;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background-color: #ffffff;
  box-shadow: 0 10px 10px 0 rgba(0, 57, 128, 0.2);
  margin: 2rem auto;
  position: relative;
  padding: 40px 28px 28px 28px;
  min-width: 350px;
  max-width: 600px;
  animation: fadeIn 0.5s ease-in-out forwards;
  opacity: 0;
  transform: translateY(10px);
  border: 3px solid #d9e7f4;
}

@media (min-width: 990px) {
  .formWrapper {
    width: 405px;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.title {
  color: #19222a;
  font-size: 24px;
  font-weight: 900;
  text-align: center;
  margin: 0;
  margin-bottom: 1rem;
}

.hero {
  max-width: 420px;
  position: relative;
  overflow: visible;
}

.form {
  margin: 1rem 0;
}

.form a {
  color: #3f71e0;
}

.form :global(.ant-form-item-required) {
  flex-direction: row-reverse;
}

/* this gets rid of the margin on the left for required labels */
.form :global(.ant-form-item-required::after) {
  content: unset;
}

.form :global(.ant-form-item-required:before) {
  color: inherit !important;
  margin-left: 4px;
}

.form :global(.ant-form-item-explain) {
  margin-top: 3px;
  margin-bottom: 5px;
  line-height: 1.3;
}

.form :global(.ant-form-item-extra) {
  margin-top: 3px;
  margin-bottom: 5px;
  line-height: 1.3;
}

.subscribeCopy {
  color: #656667;
  font-size: 13px;
}

.disclaimer {
  color: #848484;
  font-size: 13px;
  line-height: 20px;
  margin: 10px 0 0 0;
}

.submit {
  text-align: center;
  margin: 40px 0 0 0;
}

.recaptcha > div > div {
  margin: 0 auto;
}

.accountLink {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.countrySelect label {
  white-space: nowrap;
}

.hideVisibilty {
  visibility: hidden;
  width: 0;
  height: 0;
  margin: 0;
  pointer-events: none;
}

.or {
  text-transform: uppercase;
  color: #848484;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  margin-block: 1rem;
  font-size: 12px;
}

.or::before,
.or::after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #848484;
  vertical-align: middle;
  margin: 0 10px;
}

.signUpButton {
  margin-inline: auto;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: #ffffff;
  background-color: #3f71e0;
  border-radius: 20px;
  padding: 8px 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  line-height: 22px;
}

.signUpButton:hover {
  color: #ffffff;
  background-color: #1a49b0;
  text-decoration: none;
}

.signUpButton:active,
.signUpButton:focus {
  background-color: #133683;
  color: #fff;
  text-decoration: none;
}

.backToWebsite {
  text-align: center;
  position: absolute;
  bottom: -3rem;
  left: 50%;
  transform: translateX(-50%);
}

.backToWebsite a {
  color: #ffffff;
  text-align: center;
}

.formWebsiteWrapper {
  display: flex;
  flex-direction: column;
}

@media (min-width: 990px) {
  .formWebsiteWrapper {
    padding-left: 40px;
    padding-right: 20px;
    margin: auto;
  }
}

@media (max-width: 990px) {
  .registerWrapper {
    flex-direction: row;
    padding-top: 5rem;
  }
}
