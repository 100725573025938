.container {
  max-width: 460px;
  min-width: 350px;
  position: relative;
}

.header {
  margin: 0;
  letter-spacing: 0.1px;
}

.urlInput {
  cursor: default;
  height: 40px;
  font-family: 'Courier New', monospace !important;
  font-weight: 100;
  letter-spacing: 0.2px;
  font-size: 14px;
  border: none;
  outline: none;
}

.urlInput:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.copy {
  display: block;
  margin: auto 0;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 0.2px;
  padding-inline: 10px;
  color: #3f71e0;
  cursor: pointer;
}

.subHeader {
  cursor: default;
  color: #666;
  font-weight: 500;
  font-size: 14px;
}

.urlInputBox {
  display: flex;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  justify-content: space-between;
}

.urlInputBox {
  margin-top: 35px;
}

.endBlock,
.middleBlock {
  padding: 10px 0 10px 0;
}

.close {
  position: absolute;
  right: 20px;
  top: 20px;
}
