.links {
  min-width: 30%;
}

.contact span {
  color: var(--colorPrimary) !important;
}

.owner {
  padding: 15px;
  border-radius: 10px;
  background-color: var(--colorBgAccent);
}

.ownerInfo {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.avatar {
  margin-right: 10px;
}

.ownerName {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.contact {
  background-color: var(--colorTextLightSolid);
  color: var(--colorPrimary);
}

.contact:hover {
  background-color: var(--colorTextLightSolid) !important;
}

.waveHand {
  display: inline-block;
  transform-origin: bottom center;
  transform: rotate(-7deg);
  animation: wave 1s 3;
  animation-delay: 0.6s;
}

.linksHeader {
  margin-top: 0;
  margin-bottom: 10px;
  line-height: 24px;
  font-weight: 700;
}