.createdContainer {
  padding: 10px 0 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.created {
  color: #848484;
  font-size: 12px;
  font-weight: bold;
}

.creator {
  color: #848484;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 70px;
}
