.disableAndDelete {
  width: fit-content;
}

.deleteIcon,
.disableIcon,
.enableIcon {
  width: 16px;
  height: 16px;
  margin: 0 8px 3px 0;
}

.moreIcon {
  color: #3f71e0;
  width: 30px;
  padding: 0 3px;
  border-radius: 4px;
}

.moreIcon:hover {
  color: #1a49b0;
}

.moreIcon:focus {
  color: #133683;
}
.active,
.active:hover {
  color: white;
  background-color: #1a49b0;
}

.active:focus {
  color: white;
  background-color: #133683;
}

.menuItem span {
  display: flex;
  align-items: center;
}

.moreMenu .menuItem {
  display: flex;
  align-items: center;
  font-size: 12px;
  min-width: 124px;
}

.moreMenu .menuItem:first-child {
  border-radius: 6px 6px 0 0;
}

.moreMenu .menuItem:last-child {
  border-radius: 0 0 6px 6px;
}

.moreMenu {
  box-shadow: 0 0 4px 3px rgb(0 0 0 / 20%);
  padding: 0;
  border-radius: 6px;
}

.moreMenu
  :global(.ant-dropdown-menu-item-active:not(.ant-dropdown-menu-item-danger)) {
  background-color: #e6e6e6;
}

.unreadIcon {
  margin-right: 8px;
}

.menuItem {
  display: flex;
  align-items: center;
}
