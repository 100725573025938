.title {
  font-size: 24px;
  color: #373737;
  align-content: left;
  line-height: 25px;
}

.para {
  font-size: 14px;
  line-height: 14px;
  color: #373737;
}

.counterContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-height: 32px;
  justify-content: space-between;
  width: 266px;
}

.counterInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-height: 32px;
  width: 88px;
  border: 1px solid #4bd7f5;
  border-radius: 2px;
}

.counter {
  display: flex;
  flex-direction: column;
  max-height: 32px;
}

.count {
  max-height: 30px;
}

.counter :global(.ant-btn-icon-only.ant-btn-sm) {
  height: 15px;
  width: 21px;
  background-color: #d9d9d9;
  border-radius: 0;
  color: #373737;
}

.counterUpButton {
  border-radius: 0 2px 0 0;
  box-shadow: inset 0 -0.5px 0 0 #d9d9d9, inset 1px 0 0 0 #d9d9d9;
}

.counterDownButton {
  border-radius: 0 0 2px 0;
  box-shadow: inset 0 0.5px 0 0 #d9d9d9, inset 1px 0 0 0 #d9d9d9;
}

.messagingContainer {
  margin-top: 19px;
  margin-bottom: 19px;
}

.confirmMessage {
  height: 140px;
  width: 540px;
  background-color: #f1f1f1;
  padding: 23px;
}

.messageTitle {
  color: #373737;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 20px;
}

.upgradeTo {
  color: #406cff;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 16px;
  text-align: right;
  padding: 0;
}

.actions {
  display: flex;
  justify-content: left;
  margin-top: 1.5em;
}

.button {
  padding: 0 30px;
}
