.button {
  display: flex;
  align-items: center;
  color: var(--blueColorIcons);
  justify-content: center;
  background-color: transparent;
}

.button:hover {
  color: #1a49b0 !important;
  border-color: transparent;
  background-color: transparent;
}

.dropdown {
  margin-left: 1rem;
}

.filterIcon {
  width: 20px;
  height: 20px;
}

.item {
  font-size: 12px;
}

.item div {
  padding-bottom: 0 !important;
}

.radioItem {
  font-size: 12px;
  display: flex;
  align-items: center;
}

@media (max-width: 640px) {
  .filterIcon {
    margin-right: 2px;
  }
}
