.column {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 300px;
  outline: 0;
  flex-shrink: 0;
  background-color: var(--colorBgAccent);
  border-radius: 15px;
  border: var(--columnGreyBorder);
}

.column :global(.simplebar-content-wrapper),
.column :global(.simplebar-content) {
  display: flex;
}

.column :global(.simplebar-content) {
  flex-grow: 1;
  width: 100%;
}

.column :global(.simplebar-scrollbar) {
  width: 5px;
}

.cardDraggingColumn {
  z-index: 100;
  position: relative;
}

.cardDraggingColumn :global(.simplebar-track) {
  visibility: hidden !important;
}

.cardsContainer {
  z-index: 4;
  overflow: hidden;
  flex-grow: 1;
  position: relative;
  flex-direction: column;
}

.cardsContainer :global(.simplebar-offset) {
  right: 0 !important;
  padding-bottom: 8px;
}

.cardsContainer :global(.simplebar-scrollbar) {
  left: 4px;
}

.cardsContainer :global(.simplebar-scrollbar::before) {
  left: 0px;
  right: 0px;
}

.dragging {
  z-index: 100001;
  background: red;
  position: relative;
}

.heightPreservingContainer:empty {
  min-height: calc(var(--child-height));
  box-sizing: border-box;
}

@media (max-width: 1600px) {
  :global(body > div:not(.details-hidden)) .column {
    width: 250px;
  }
}
