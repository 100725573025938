.videoPreview :global(.ant-modal-content) {
  background-color: transparent;
}

.videoPreview :global(.ant-modal-body) {
  padding: 0;
}

.closeIcon {
  background-color: black;
  border-radius: 15px;
  color: white;
  padding: 10%;
}
