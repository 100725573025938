.iframeContainer {
  padding: 16px;
  width: 100%;
}

.harvestIframe {
  display: block;
  width: 100%;
  border: none;
}
