.leftPanel {
  background: white;
  width: 360px;
  border-radius: 10px 0px 0px 10px;
  padding: 48px 18px 18px 18px;
  display: flex;
  flex-direction: column;
}

.descriptionOuter {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.shortDescriptionOuter {
  flex-grow: unset;
}

.description textarea {
  padding: 0 0 4px 4px;
}

.description :global(.ant-input-textarea-show-count)::after {
  text-align: left;
  color: #666666;
}

.descriptionFooter {
  display: flex;
  align-items: center;
  margin-top: -19px;
  justify-content: flex-end;
  z-index: 1;
}

.attachIcon {
  width: 18px;
  height: 18px;
}

.emojiOuter {
  background: unset;
  border: none;
  padding: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: -1px;
}

.showPicker .emojiOuter,
.emojiOuter:hover {
  color: white;
  background: #666666;
}

.showPicker .emojiOuter {
  margin-right: 5px;
}

.showPicker .emojiIcon {
  stroke-width: 2.5;
}

.emojiIcon {
  width: 18px;
  height: 18px;
}

.hiddenTooltip {
  visibility: hidden;
}

.footerRight {
  display: flex;
}

.showPicker .pickerContainer {
  margin-left: -5px;
}

.pickerContainer {
  position: relative;
}

.inner {
  position: absolute;
  bottom: 15px;
  left: 5px;
  z-index: 1070;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  height: 368px;
}

.adminInner {
  height: 353px;
}

@media (max-width: 600px) {
  .leftPanel {
    border-radius: 10px 10px 0px 0px;
    padding: 45px 30px 10px 30px;
    width: 100%;
  }
  .inner {
    top: 25px;
    left: -255px;
  }
}

@media (max-device-width: 770px) and (max-resolution: 95dpi),
  (max-device-width: 450px) and (max-resolution: 336dpi) {
  .emojiOuter {
    display: none;
  }
}
