.restoreTasks {
  padding-right: 2px;
}
.buttonWrapper {
  padding-left: 5px;
}

.viewDetails {
  padding: 0;
}

.headerOuter {
  display: flex;
  align-items: center;
}
