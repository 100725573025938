.alternativeBrowsers .logo {
  opacity: 0.4;
  filter: grayscale(1);
  width: 44px;
  height: 44px;
  transition: all 0.2s ease;
  cursor: pointer;
}

.alternativeBrowsers .logo:hover {
  filter: grayscale(0);
  opacity: 1;
}

.installCode {
  max-width: 360px;
  margin: 0 auto;
}

.text {
  margin: 15px 0 10px 0;
  font-size: 12px;
}
