.videoOffOuter {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #91d5ff;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  margin-bottom: 10px;
}

.videoOffOuter svg {
  width: 17px;
  height: 17px;
}

.messageOuter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 300;
  font-size: 14px;
}
.messageOuter p {
  margin: 0;
}

.messageOuter a {
  padding-left: 5px;
}

@media (max-width: 1359px) and (min-width: 601px) {
  .messageOuter p:not(.inSidebar) {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 350px) {
  .messageOuter p:not(.inSidebar) {
    display: flex;
    flex-direction: column;
  }
}
