.tagsContainer {
  display: flex;
  flex-direction: column;
  padding: 4px;
}

.tagsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  color: inherit;
  font-weight: 800;
  color: var(--navigationLink);
}

.emptyTagsMessage {
  padding: 0px 8px;
}

.tagsButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: inherit;
}

.tagContainer {
  border: none;
  padding: 4px 8px;
}

.tagContainer.active {
  border: 1px dashed #3f71e0;
  border-radius: 12px;
}

.tag {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.tagCount * {
  color: #19222a;
}

.tagName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: normal;
  color: var(--colorTextBase);
}

.navIcon {
  width: 14px;
  height: 14px;
  color: var(--blueColorIcons);
}

.collapseItem > :first-child:hover {
  background: rgba(0, 0, 0, 0.06);
}

.collapseItem > :first-child:hover * {
  color: var(--colorText) !important;
}

.collapseItem > :first-child {
  color: var(--navigationLink);
  padding-left: 8px !important;
  border-radius: 12px !important;
}
