
.upgradeContainer {
    display: flex;
    gap: 20px;
    background-color: #ececf2;
    border-radius: 5px;
    padding: 15px;
    max-width: 570px;
  }
  
  .upgradeContainer h3 {
    margin-top: 0;
    margin-bottom: 10px;
  }
  
  .upgradeImage {
    background-image: url('./images/upgrade.png');
    position: relative;
    width: 80px;
    height: 22px;
    background-size: 80px;
    display: inline-block;
    top: 5px;
  }
  
  .upgradeImageContainer {
    width: 50%;
  }
  
  .integrationsUpgradeImage {
    background-image: url('./images/integrations.png');
    position: relative;
    height: 160px;
    width: 160px;
    background-size: 160px;
  }
  
  .upgradeArrow {
    float: left;
    margin-right: 5px;
    width: 15px;
  }
  
  .upgradeButton {
    background-color: #af30d7;
  }
  
  .upgradeButton:focus {
    background-color: #8e22b0;
  }
  
  .upgradeButton:hover {
    background-color: #8e22b0;
  }

  .groupingHorizontal {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
 
  .removeAddonBtn {
    padding: 0px;
  }