.overlay :global(.ant-popover-inner-content) {
  border-radius: 5px;
  padding: 8px;
}

.overlay :global(.ant-popover-inner) {
  border-radius: 5px;
}

.overlay :global(.ant-popover-inner-content) {
  padding: 8px;
  width: 300px;
}

.overlay :global(.ant-picker-calendar) { 
  border-radius: 5px;
  padding: 4px;
  border: 1px solid #D9D9D9;
}

.overlay :global(.ant-picker-calendar-header) {
  padding: 6px 4px;
  justify-content: unset;
}

.overlay :global(.ant-select-selection-item) {
  padding-left: 5px !important;
}

.overlay :global(.ant-picker-calendar-mode-switch) {
  display: none;
}

.overlay :global(.ant-radio-button-wrapper) {
  padding: 0px 6px;
}

.selectYear {
  margin-right: 10px;
}

.overlay :global(.ant-select-selection-item) {
  margin-right: 5px;
}

.dateHeader {
  padding-bottom: 8px;
}
