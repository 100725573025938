.assigneesOuter {
  display: flex;
}

:global(.rightToLeft) .assigneesOuter {
  direction: ltr;
}

.avatarContainer {
  display: block;
  border: 3px solid white;
  border-radius: 50%;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.firstOverlap {
  margin-left: -10px;
  z-index: 2;
}

.secondOverlap {
  margin-left: -10px;
  z-index: 3;
}
