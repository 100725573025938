.card {
  display: block;
  position: relative;
  background-color: var(--cardColor);
  cursor: pointer;
  border-radius: 6px;
  font-weight: 400;
  box-shadow: 0px 2px 5px 0px rgba(0, 57, 128, 0.3);
}

.cardOuter {
  padding: 2px 0 6px 0;
  margin: 0 15px;
  outline: none;
}

.hasAction {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.metadataContainer {
  padding-bottom: 10px;
}

.priorityIndicator {
  border-radius: 6px 0px 0 6px;
  position: absolute;
  left: 0;
  top: 0;
  width: 6px;
  height: 100%;
  z-index: 1;
}

.notSet {
  background-color: #ccc;
}

.critical {
  background-color: #d46060;
}

.important {
  background-color: #e8b019;
}

.normal {
  background-color: #60c5d4;
}

.minor {
  background-color: #8bb863;
}

.cardInnerContent {
  padding: 8px 12px 4px 16px;
}

.idInfo {
  color: #aaaaaa;
  display: flex;
  align-items: center;
}

.description {
  white-space: pre-line;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
  overflow: hidden;
  max-height: 500px;
  margin-top: 0 !important;
  line-height: 22px;
  color: var(--colorTextBase);
}

.cardActionIcon,
.tagIcon,
.videoIcon,
.dueIcon {
  width: 15px;
  height: 15px;
  stroke: #848484;
  margin-right: 6px;
}

.cardActionIcon {
  margin-left: auto;
}

.taskInfoFooter {
  background: var(--colorBgCardTag);
  padding: 4px 12px 4px 16px;
  border-radius: 6px;
  position: relative;
}

.tagBox,
.dueBox {
  color: var(--colorGrey3);
  display: flex;
  align-items: center;
  font-size: 12px;
}

.tags {
  max-width: calc(100% - 61px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 18px;
}

.assigneesContainer {
  position: absolute;
  bottom: -6px;
  right: -6px;
  display: flex;
  flex-direction: row-reverse;
}

.avatarContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.avatarContainer :global(.ant-avatar-string) {
  font-size: 11px;
}

.assigneesContainer div:not(:first-child) {
  margin-right: -8px;
}

.assigneesContainer div:nth-child(2) {
  z-index: 1;
}

.assigneesContainer div:nth-child(3) {
  z-index: 2;
}

.activeCard {
  box-shadow: 0px 0px 0px 2px #3f71e0;
  overflow: visible;
}

.activeCardMultiSelectBG {
  background-color: rgba(0, 192, 233, 0.1);
}

.activeCardMultiSelect {
  border: 1px solid #3f71e0;
  overflow: visible;
}

.mentionText {
  font-weight: 400;
  white-space: pre-line;
  word-break: break-word;
  font-size: 14px;
}

.assetIcon {
  color: #919191;
  font-size: 9px;
  background: #ececf3;
  display: flex;
  align-items: center;
  border-radius: 7px;
  height: 16px;
  justify-content: space-around;
  padding: 5px;
  margin-left: 7px;
  font-weight: 600;
  margin-bottom: 3px;
}

.imageIcon,
.fileIcon {
  width: 10px;
  height: 10px;
  margin-right: 3px;
}

.checkboxMultiselect > :global(.ant-checkbox),
.cardActionIcon {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.25s;
}

.checkboxMultiselect > :global(.ant-checkbox-checked) {
  visibility: visible;
  opacity: 1;
}

.checkboxMultiselect {
  z-index: 999;
  position: relative;
  margin-left: 0;
}

.card:focus .checkboxMultiselect,
.activeCardMultiSelect .checkboxMultiselect {
  visibility: visible;
  opacity: 1;
}

.idCheckboxWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

@media (hover: hover) {
  /* when hover is supported by the device */
  .card:not(.activeCard):hover {
    box-shadow: 0px 10px 10px 0px rgba(0, 57, 128, 0.2);
  }

  .idInfo:hover {
    color: #3f71e0;
  }

  .cardActionIcon:hover {
    stroke: #20c0e7;
  }

  .card:hover .checkboxMultiselect > :global(.ant-checkbox),
  .activeCardMultiSelect .checkboxMultiselect {
    visibility: visible;
    opacity: 1;
  }

  .card:hover .cardActionIcon {
    visibility: visible;
    opacity: 1;
  }
}
