.submit {
  text-align: center;
  margin: 40px 0 0 0;
}

.accountLink {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.or {
  text-transform: uppercase;
  color: #848484;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  margin-block: 1rem;
  font-size: 12px;
}

.or::before,
.or::after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #848484;
  vertical-align: middle;
  margin: 0 10px;
}

.signUpButton {
  margin-inline: auto;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: #3f71e0;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 8px 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  line-height: 22px;
  border-radius: 9999px;
  border: 1px solid var(--borderColor);
}

.signUpButton:hover {
  color: #fff;
  background-color: #1a49b0;
  text-decoration: none;
}

.signUpButton:active,
.signUpButton:focus {
  background-color: #133683;
  color: #fff;
  text-decoration: none;
}

.backToWebsite {
  text-align: center;
  position: absolute;
  bottom: -3rem;
  left: 50%;
  transform: translateX(-50%);
}

.backToWebsite a {
  color: #ffffff;
  text-align: center;
}

.formWrapper {
  text-align: center;

  & :global(.ant-btn-default):first-child {
    background-color: var(--colorPrimary);
    color: #fff;

    & :hover {
      background-color: var(--colorPrimary);
      color: #fff;
    }
  }

  & a {
    font-weight: bold;
  }
}

.captureModal {
  max-width: 400px;

  & :global(.ant-modal .ant-modal-content) {
    background-color: transparent;
  }
}
.fullWidthIframe {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  z-index: 1;
}
