.detailsViewActions {
  display: flex;
  align-items: center;
  column-gap: 6px;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: #f6f9fc;
  transition: all 0.25s ease-in;
  border-radius: 50%;
  cursor: pointer;
}

.iconWrapper:hover {
  background-color: #0000000f;
}

.closeIcon {
  stroke: #d46060;
}

.minimize,
.maximize,
.closeIcon {
  height: 16px;
  width: 16px;
}

.expandedView {
  margin-bottom: 5px;
  justify-content: flex-end;
}

/* Don't show the maximize buttons when in sidebar */
:global(.detailbarWrapper) .maximize,
:global(.detailbarWrapper) .minimize {
  display: none;
}

/* Show the maximize/minimize buttons when in expanded */
:global(.pane-expanded) .maximize,
:global(.pane-expanded) .minimize {
  display: initial;
}

/* Don't show the maximize/minimize buttons on small screens */
@media (max-width: 951px) {
  .iconWrapper:first-child,
  .maximize,
  .minimize {
    display: none;
  }
}

.isExpanded {
  max-width: 340px;
  width: 100%;
  background-color: var(--colorBgAccent2);
  display: flex;
  justify-content: flex-end;
  padding: 8px 16px;
}
