.mobileNav {
  position: absolute;
  top: 0px;
  left: -20px;
  width: 100vw;
  z-index: 100;
  box-shadow: 0px 10px 10px 0px #00398020;
}

.mobileNavButton {
  color: #3f71e0;
  border: 2px solid #d9e7f4;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.headerItem {
  padding: 20px 20px 20px 18px !important;
  height: fit-content;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
}

.item {
  display: flex;
  align-items: center;
  color: #19222a;
  font-weight: 600;
}

.sitesSubMenu {
  color: #3f71e0;
  font-weight: 600;
}

.active,
.active svg,
.sitesSubMenu svg {
  color: #3f71e0;
}

.mobileNav .menu .closeMenu {
  background-color: #133683;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-left: -4px;
}

.divider {
  margin: 20px 0;
}

.headerDivider {
  margin-top: 0;
}

.websiteIcon {
  width: 18px;
  height: 18px;
}

.sitesSubMenu ul {
  max-height: 190px;
  overflow: scroll;
}

.mobileNavOuter {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}
