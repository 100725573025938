.pageHeader {
  position: sticky;
  z-index: 100;
  top: 0;
  left: 0;
  background: #ffffff;
  width: 100%;
  padding-right: 90px;
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: padding-right ease-in-out 300ms;
}

:global(.sidebarClosed) .pageHeader,
:global(.noSidebar) .pageHeader {
  padding-right: 20px;
}

.assetContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: margin-right ease-in-out 300ms;
  background: #f1f1f1;
  margin: 20px 90px 0px 20px;
  overflow: auto;
}

:global(.isPdf) .assetContainer canvas {
  width: auto !important;
}

.asset:not(.unmounted) {
  height: auto;
  max-width: unset;
  transition: height ease-in-out 400ms;
  transition: width ease-in-out 400ms;
}

:global(.sidebarClosed) .assetContainer,
:global(.noSidebar) .assetContainer {
  margin-right: 20px;
}

.overflowing {
  display: block;
}

@media (max-width: 470px) {
  .assetContainer {
    margin-right: 20px;
  }
  .pageHeader {
    padding-right: 20px;
  }
}

.loader {
  position: fixed !important;
  top: 20% !important;
  width: 100% !important;
  height: 100% !important;
}

.loaderWrapper :global(.ant-spin-container),
.loaderWrapper :global(.ant-spin-container::after) {
  opacity: 1;
  background: unset;
  position: fixed;
  top: 50% !important;
  left: 50%;
  transform: translate(-50%, -50%);
}
