.formItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 4px 0;
  border-bottom: 1px solid #dbe3e6;
  width: 100%;
  min-height: 24px;
  flex-wrap: column;
}

.formItem :global(.ant-legacy-form-item-label) {
  width: 80px;
  margin-right: 10px;
  flex-shrink: 0;
  flex-basis: auto;
}

.formItem :global(.ant-legacy-form-item-control-wrapper) {
  width: calc(100% - 90px);
  flex: 1 1 0;
}

.formItem label {
  font-size: 9px;
  font-weight: 600;
  color: #888;
  text-transform: uppercase;
  text-align: left;
}

.formItem :global(.ant-legacy-form-item-control) {
  line-height: 1em;
}

.assignToUser {
  min-width: 190px;
}

.creatorAnchor {
  font-weight: 400;
  text-decoration: none;
  color: #666;
}

.creatorAnchor:hover {
  text-decoration: underline;
}

.dateItem :global(.ant-picker) {
  width: 100%;
}

.userIcon {
  width: 12px;
  margin-right: 4px;
}

.dateItem :global(.ant-picker-input-wrap) {
  display: none;
}
