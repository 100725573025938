.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

:global(.details-hidden) .container {
  width: 100% !important;
}

.hasFilter:not(.isMobileView) {
  width: calc(100% - 550px);
}

.container:not(.isMobileView) {
  width: calc(100% - 300px);
}

@media (min-width: 1360px) {
  .container:not(.isMobileView) {
    width: calc(100% - 400px);
  }

  .hasFilter:not(.isMobileView) {
    width: calc(100% - 650px);
  }
}

@media (min-width: 1750px) {
  .container:not(.isMobileView) {
    width: calc(100% - 500px);
  }

  .hasFilter:not(.isMobileView) {
    width: calc(100% - 750px);
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: fit-content;
  margin-left: auto;
}

.hasFilter {
  justify-content: space-between;
}

.isMobileView {
  justify-content: flex-end;
}

.installExtensionContainer {
  display: block;
  @media (max-width: 1040px) {
    display: none;
  }
}
