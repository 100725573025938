.inputsContainer {
  display: flex;
  flex-direction: column;
}

.rightPanel {
  background: #f5eeed;
  width: 220px;
  border-radius: 0px 10px 10px 0px;
  padding: 48px 18px 18px 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.innerTop {
  width: 100%;
}

.tag {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  max-width: 174px;
}

.tagOption div:first-child {
  display: flex;
  align-items: center;
}

.assigneeOption div:first-child {
  display: flex;
  align-items: center;
}

.select {
  padding-bottom: 7px;
  font-size: 13px;
  /* Weird use but it is done to overwrite antd*/
  height: unset;
}

.rightPanel .innerTop .inputsContainer .select :global(.ant-select-selector) {
  border-radius: 4px;
}

.rightPanel .innerTop .inputsContainer .select :global(.ant-select-arrow) {
  top: 14px;
  right: 17px;
}

.rightPanel .innerTop .inputsContainer .select :global(.ant-select-arrow) svg {
  width: 16px;
  height: 16px;
}

.select:not(.selectSeverity) {
  color: #666;
}

.assigneeOption {
  display: flex;
}

.withUpgrade {
  min-width: 220px;
}

.selectStatus {
  text-transform: capitalize;
}

/*Override the text color for status from antdgi*/
.selectStatus :global(.ant-select-selection-item) {
  font-size: 13px;
  color: #666;
}

.priority-0,
.selectPriority-0 span {
  color: #cccccc !important;
}

.dropdownSeverity .priority-0:global(.ant-select-item-option-selected) {
  background-color: #cccccc;
  color: white !important;
}

.priority-1,
.selectPriority-1 span {
  color: #d46060 !important;
}

.dropdownSeverity .priority-1:global(.ant-select-item-option-selected) {
  background-color: #d46060;
  color: white !important;
}

.priority-2,
.selectPriority-2 span {
  color: #e8b019 !important;
}

.dropdownSeverity .priority-2:global(.ant-select-item-option-selected) {
  background-color: #e8b019;
  color: white !important;
}

.priority-3,
.selectPriority-3 span {
  color: #60c5d4 !important;
}

.dropdownSeverity .priority-3:global(.ant-select-item-option-selected) {
  background-color: #60c5d4;
  color: white !important;
}

.priority-4,
.selectPriority-4 span {
  color: #8bb863 !important;
}

.dropdownSeverity .priority-4:global(.ant-select-item-option-selected) {
  background-color: #8bb863;
  color: white !important;
}

.placeHolder {
  font-size: 13px;
  color: #666;
}

.optionText {
  margin-left: 5px;
}
.optionIcon,
.newTagIcon {
  width: 17px;
  height: 17px;
}

.newTagIcon {
  color: #8bb863;
}

.tagIcon {
  min-width: 13px;
  max-width: 13px;
  height: 13px;
  margin-right: 3px;
}

.createButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.createButton,
.createButton:visited,
.createButton:focus,
.createButton:active {
  background-color: #3f71e0;
  border-radius: 5px;
  height: unset;
  padding-top: 7px;
  width: 100%;
  padding-bottom: 7px;
  color: white;
  border: none;
}

.createButton:hover {
  background-color: #1a49b0;
  color: white;
}

.createButton:disabled,
.createButton:disabled:hover {
  background-color: #97b3ee;
  color: white;
}

.createButton:focus {
  background-color: #133683;
}

.submitted,
.submitted:hover,
.succes:visited,
.submitted:focus,
.submitted:active {
  background-color: #7aae37;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkCircleIcon,
.loaderIcon {
  margin: 0 7px 2px 0;
  width: 15px;
  height: 15px;
}

.newTagOuter {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.tagOption .optionText,
.assigneeOption .optionText,
.tag .optionText {
  max-width: 221px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

@media (min-width: 601px) {
  .selectAssignees :global(.ant-select-selection-search),
  .selectTags :global(.ant-select-selection-search) {
    max-width: 160px;
    overflow-x: hidden;
  }
}

@media (max-width: 600px) {
  .rightPanel {
    border-radius: 0px 0px 10px 10px;
    width: 100%;
    padding: 15px 30px 30px 30px;
  }

  .createButton {
    margin-top: 10px;
  }

  .rightPanel .innerTop .inputsContainer .select :global(.ant-select-arrow) {
    top: 17px;
    right: 17px;
  }

  .rightPanel
    .innerTop
    .inputsContainer
    .select
    :global(.ant-select-arrow)
    svg {
    width: 18px;
    height: 18px;
  }

  .tag .optionText {
    max-width: 90px;
  }
}

.selectAssignees :global(.ant-select-selector),
.selectTags :global(.ant-select-selector) {
  padding-top: 3px;
}

.checkboxLabel {
  font-size: 13px;
  margin-left: 7px;
}

.checked :global(.ant-checkbox-inner) {
  background-color: #3f71e0 !important;
  border-color: #3f71e0 !important;
}

.checkboxContainer {
  margin-bottom: 10px;
}

.loginlessInputContainer {
  width: 100%;
}

@media (max-width: 600px) {
  .loginlessInputInner {
    width: 100%;
  }
}

.upgradeOption {
  background-color: #edcbf5 !important;
  position: absolute !important;
  top: calc(100% - 32px);
  left: 0;
  z-index: 10;
}

.userContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 195px;
}

.upgradeLink {
  color: #9232bc;
  font-weight: 600;
}

.upgradeIcon {
  min-width: 24px;
  min-height: 23px;
}

.tag span:not(:global(.anticon)) {
  max-width: 126px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.popup {
  max-width: 230px;
}

.withUpgrade.lastOption {
  margin-bottom: 34px;
}
