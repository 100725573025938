.visibleToOuter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    padding: 4px 0;
    border-bottom: 1px solid #dbe3e6;
    width: 100%;
    min-height: 24px;
}

.withLink :global(.ant-select-item-option-content)  {
    display: flex;
    align-items: center;
}

.infoIcon {
    width: 20px;
    height: 20px;
    margin: 0 0 2px 8px;
    color: #3f71e0;
}