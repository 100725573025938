.skinToneButton {
  position: absolute;
  width: 36px;
  height: 36px;
  right: 16px;
  bottom: 16px;
  background: transparent;
  z-index: 2000;
  cursor: pointer;
}

.reactionPickerButton {
  position: absolute;
  right: -20px;
  top: 0;
}

.descriptionPickerButton {
  margin-top: -3px;
}

.skinToneIcon {
  width: 16px;
  height: 16px;
  position: relative;
  border-radius: 100%;
}

.skinTone-1 .skinToneIcon {
  background: #ffc93a;
}

.skinTone-2 .skinToneIcon {
  background: #ffdab7;
}

.skinTone-3 .skinToneIcon {
  background: #e7b98f;
}

.skinTone-4 .skinToneIcon {
  background: #c88c61;
}

.skinTone-5 .skinToneIcon {
  background: #a46134;
}

.skinTone-6 .skinToneIcon {
  background: #5d4437;
}

.active {
  border: 1px solid #3f71e0;
}

.skinToneIcon::after {
  content: '';
  mix-blend-mode: overlay;
  background: linear-gradient(rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0));
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-radius: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: inset 0 -2px 3px #000, inset 0 1px 2px #fff;
}

.reactionPopover :global(.ant-popover-inner) {
  padding: 0;
}

.reactionPopover :global(.ant-popover-title) {
  min-width: unset;
  width: fit-content;
}

.reactionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f2f2;
  border: 1px solid #f1f2f2;
  border-radius: 50% !important;
}

.reactionButton:hover {
  background-color: white;
  border-color: #666666;
}

.reactionIcon {
  width: 16px;
  height: 16px;
  stroke: rgba(25, 34, 42, 0.88);
}

.reactionButton:hover .reactionIcon {
  fill: #f2c744;
  stroke: #61501b;
}

:global(.privateComment) .reactionButton {
  background-color: white;
  border: 1px solid white;
}

:global(.privateComment) .reactionButton:hover {
  border-color: #666666;
}

.reactionTooltip {
  font-size: 12px;
}

.darkMode :global(.ant-popover-arrow):before,
.darkPicker .skinToneOverlay {
  background-color: #151618;
}
