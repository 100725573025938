.ignoreLabelOuter {
  cursor: default;
}

.ignoreLabelHeader {
  font-weight: 400;
}

.ignoreListMessage {
  font-weight: 300;
}

.ignoreListInput {
  font-weight: 400;
  border-radius: 4px;
  line-height: 23px !important;
  overflow-y: scroll;
}
