.pageHeader {
  position: sticky;
  z-index: 100;
  top: 0;
  left: 0;
  background: #ffffff;
  width: 100%;
  padding-right: 20px;
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: padding-right ease-in-out 300ms;
}

.assetsContainer {
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.shareButton {
  display: flex;
  align-items: center;
}
