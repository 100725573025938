.body {
  white-space: pre-line;
  font-size: 14px;
  word-break: break-word;
  font-weight: 400;
}

.container {
  margin: 0 0 1em 0;
  background: #ffffff;
  padding: 15px 15px 5px 15px;
  border-radius: 4px;
}

.commentContent {
  display: flex;
}

.avatar {
  margin-right: 1em;
  flex-shrink: 0;
}

.header {
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  margin: 0.1em 0 0.3em 0;
}

.bodyContainer {
  flex-grow: 1;
}

.editButton {
  color: #3f71e0;
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  transition: all 0.2s ease;
}

.container:hover .editButton {
  opacity: 1;
}

.editIcon {
  width: 1.1em;
  margin-right: 0.5em;
  margin-top: -0.4em;
}

.privateCommentText span {
  border-radius: 2px;
  background: white;
}

.privateCommentText :global(.linkified) .notMention {
  background: #fff6d9;
}

.commentText {
  font-weight: 300;
}

.date::before {
  height: 1em;
  width: 1px;
  margin: 0 0.5em -0.184em 0.5em;
  background-color: #959595;
  display: inline-block;
  content: ' ';
}

.externalCommitLink {
  text-decoration: none;
}

.externalCommitText {
  margin-left: 0.8em;
}

.actions {
  margin: 1em 0 0 0;
  padding: 1em 0 0 0;
  border-top: 1px solid #f0f0f0;
}

.privateComment {
  background: #fff6d9;
  border: 1px solid #efdba7;
  padding: 14px;
}

.deleteIcon {
  width: 16px;
  height: 16px;
}

.delete {
  line-height: 1em;
  padding-right: 0;
}

.privateCommentFooter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: -10px;
  padding-top: 5px;
}

.eyeIcon {
  width: 16px;
  height: 16px;
  stroke: #666;
  margin: 0px 3px 2px 0px;
}

.visibleTo {
  font-size: 11px;
  font-weight: 500;
  color: #666666;
}

.unreadComment {
  padding: 14px;
  border: 1px solid #3f71e0;
}

.adminContainer.unreadComment {
  border: 1px solid #c7c7c7;
}

.adminContainer {
  border: none;
}

.adminContainer .commentText {
  line-height: 1.25;
}

/* By default it uses `fit-content` which doesn't work for this*/
.antdBadgeOverride {
  width: 100%;
}
