.tools {
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

:global(.mobile) .tools {
  right: 13px;
}

.toolsInner {
  background: #2f353e;
  border-radius: 7px 0 0 7px;
  padding: 0 10px 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.icon {
  color: inherit;
}

.toolButton {
  background: transparent;
  border: 0;
  margin: 15px 0 0 0;
  color: #fff;
}

.toolButtonTrash {
  opacity: 0.3;
  cursor: not-allowed;
}

.toolButtonTrashEnabled {
  opacity: 1;
  cursor: pointer;
}

.toolButton.toolActive {
  color: #3f71e0;
}

.break {
  width: 100%;
  display: block;
  height: 0px;
  border: none;
  border-top: 1px solid #a0a0a0;
  margin: 10px 0 0 0;
}

.pickerContainer {
  position: relative;
}

.picker {
  position: absolute !important;
  right: -3px;
  top: 55px;
}

.save,
.cancel {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  border: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
  margin: 10px 0 0 0;
}

.save {
  background: #3f71e0;
  color: #fff;
}

.cancel {
  color: #3f71e0;
}

.save .icon,
.cancel .icon {
  width: 21px;
}

.disabled {
  cursor: not-allowed;
}

.draggerIcon {
  cursor: grab;
  color: white;
  margin-top: 10px;
}

.isDragging {
  cursor: grabbing;
}

.movedTools .toolsInner {
  border-radius: 7px;
}

.circleIcon {
  fill: currentColor;
}
