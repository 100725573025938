.previewOuter:not(.createTaskView) {
  box-shadow: 0 0 5px 3px rgba(0, 57, 128, 0.2);
  border-radius: 5px;
  margin-bottom: 5px;
}

.previewOuter {
  max-width: 100%;
  width: fit-content;
}

.overlayInner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.notCroppedView .overlayInner {
  flex-direction: column;
}

.notCroppedView {
  margin: 0 auto 5px auto;
}

.previewOuter:not(.createTaskView) ._croppedPinContainer,
.previewOuter:not(.createTaskView) ._screenshotContainer {
  border-radius: 5px;
}

._croppedPinContainer:hover .overlay,
._croppedPinContainer:hover .overlayTextWrapper {
  opacity: 1;
  transition: opacity 0.2s ease 0.3s;
}

._croppedPinContainer {
  position: relative;
  display: block;
  text-align: center;
  margin: 0 auto;
}

.screenshotBorder {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  margin-bottom: 5px;
  max-width: 100%;
  display: inline-block;
}

._screenshotWrapper {
  position: relative;
  margin: 0 auto;
  display: inline-block;
}

._screenshot {
  display: block;
  max-width: 100%;
  max-height: 300px;
  width: auto;
  height: auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}

._pin {
  width: 34px;
  height: 36px;
  margin-top: -32px;
  margin-left: -16px;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  z-index: 1;
  transition: opacity 0.2s ease 0s;
  background-size: 34px 36px;
}

.cropped ._screenshot {
  box-shadow: none;
}

._screenshotWrapper {
  position: relative;
  margin: 0 auto;
  display: inline-block;
}

._screenshotContainer {
  text-align: center;
  margin: 0 auto;
}

.createTaskView ._screenshotContainer {
  border-radius: 5px;
}

.overlay,
.overlayTextWrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 7;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease 0s;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

.maximize {
  stroke: white;
}

.overlayText {
  font-size: 16px;
  font-weight: 900;
  padding: 4px 0 0 10px;
  color: white;
}

.createTaskView {
  border-radius: 5px;
  box-shadow: 0 5px 5px 0 rgba(0, 57, 128, 0.2);
}

@media (max-width: 600px) {
  .createTaskView {
    display: none;
  }
}
