.toggleOuter {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.visibleTo {
  color: #afb3b5;
  font-weight: 500;
  padding: 0 5px;
}

.isPrivate .visibleTo {
  color: #3f71e0;
}

.isSmallView .visibleTo {
  font-size: 12px;
}

.iconOuter {
  display: flex;
  align-items: center;
}

.hiddenLabel {
  margin-right: 5px;
}

.eyeIcon {
  margin-bottom: 2px;
  width: 18px;
  height: 18px;
  stroke: #afb3b5;
  cursor: pointer;
}

.isPrivate .eyeIcon {
  stroke: #3f71e0;
}

.hiddenLabel .eyeIcon {
  margin-bottom: 1px;
  width: 22px;
  height: 20px;
}

.tooltip {
  width: 180px;
}

.popover {
  max-width: 200px;
}

.popoverIcon {
  padding-right: 8px;
  padding-top: 3px;
  color: rgb(250, 173, 20);
}

.okContainer {
  display: flex;
  justify-content: flex-end;
}

.isSmallView .visibleTo {
  font-size: 12px;
}

.popoverContent p {
  display: flex;
  justify-content: space-between;
}

.switchContainer {
  display: flex;
  align-items: center;
}

.upgradeArrow {
  width: 14px;
  height: 14px;
  margin-left: 5px;
  cursor: pointer;
}

.membersOnlyComments {
  color: #786ea7;
  font-weight: 500;
}

.toggleOuter :global(.ant-switch-checked) {
  background-color: #3f71e0;
}
