.help {
  margin: -1.25em 0 2em 0;
  font-size: 0.87rem;
}

.video {
  width: 100%;
}

.ssoDivider {
  width: 100%;
  height: 20px;
  border-bottom: 1px solid black;
  text-align: center;
  margin: 0 0 18px 0;
}

.ssoDividerText {
  background-color: #fff;
  padding: 0 10px;
  position: relative;
  top: 8px;
}

.subscribeCopy {
  color: #656667;
  font-size: 13px;
}
