.subFilterListBox {
  position: relative;
  display: none;
}

.active {
  display: block;
}

.subFilterList {
  background: #24282f;
  border-radius: 0 0 3px 3px;
}

.scrollbarContainer {
  max-height: calc(100vh - 400px);
  /* 350px here is an abritrary value that represents the space taken
   in the sidebar by elements that are not the subFilterList */
  min-height: 104px;
}

.subFilterList :global(.simplebar-track) {
  background: transparent;
  width: 3px;
}

.subFilterList :global(.simplebar-scrollbar)::before {
  background: white;
  width: 3px;
  opacity: 0.7;
  left: inherit;
}

.subFilterList :global(.simplebar-scrollbar) {
  width: 3px;
}
