.assigneesOuter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 4px 0;
  border-bottom: 1px solid #dbe3e6;
  width: 100%;
  min-height: 24px;
}

.assigneesInner {
  flex-grow: 1;
}

.assigneeItem {
  padding-bottom: 0;
  align-items: flex-start;
}

.assigneesBox {
  display: flex;
  flex-wrap: wrap;
  line-height: 1.5em;
}

.tag {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  background-color: rgb(250, 251, 251);
}

.tag:hover {
  opacity: unset;
  background-color: rgb(255, 255, 255);
}

.tag .tagContent {
  display: flex;
  align-items: center;
}

.addTag {
  border: 1px dashed rgba(1, 1, 1, 0.2);
  cursor: pointer;
  background-color: rgb(248, 248, 248);
  color: #848484;
}

.assigneesAutoComplete {
  width: 100%;
  margin-bottom: 4px;
}

.inputBox {
  display: flex;
}

.inputWithUpgrade {
  position: relative;
}

.userIcon,
.assignUserIcon {
  width: 12px;
  margin-right: 4px;
  flex-shrink: 0;
}

.userContainer {
  display: flex;
  align-items: center;
}

.upgradeLink {
  color: #9232bc;
  font-weight: 600;
}

.userName {
  margin-left: 10px;
}

.slashIcon {
  width: 14px;
  height: 14px;
}

.innerTag {
  white-space: normal;
  word-break: break-all;
}

.userContainer :global(.ant-select-item-option-content) {
  display: flex;
}

.upgradeOption {
  background-color: #edcbf5 !important;
  position: absolute;
  padding: 5px 9px;
  border: 4px solid white;
  border-radius: 0 0 5px 5px;
  z-index: 1100;
  width: 100%;
  left: 0;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1), 2px 0px 5px rgba(0, 0, 0, 0.1),
    -2px 0px 5px rgba(0, 0, 0, 0.1);
}

.activeTag {
  color: #3f71e0;
  background-color: white;
  font-weight: 600;
}

.sidebarDropdownWithUpgrade {
  box-shadow: none;
}

@media (min-width: 951px) and (max-width: 1330px) {
  .inputWithUpgrade {
    font-size: 12px;
  }
}

.assigneesDropdown {
  /* color: red; */
}

.upgradeOption.bottom {
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1), 2px 0px 5px rgba(0, 0, 0, 0.1),
    -2px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px 5px 0 0;
}
