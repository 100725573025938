.apiInput {
  cursor: default;
}

.apiBox {
  display: flex;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  justify-content: space-between;
}

.copySpan {
  display: block;
  margin: auto 0;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 0.2px;
  padding-right: 10px;
  color: #3F71E0;
  cursor: pointer;
}

.apiInput:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.apiInput {
  height: 40px;
  font-family: 'Courier New', monospace !important;
  font-weight: 100;
  letter-spacing: 0.7px;
  font-size: 15px;
  width: 80%;
  border: none;
}

.apiLabel {
  cursor: default;
  color: #666666;
  font-weight: 500;
  font-size: 14px;
}

.apiOuter {
  margin-bottom: 16px;
}
