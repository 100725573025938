.statusMenuItem {
  display: flex;
  align-items: center;
}

.statusButton {
  display: flex;
  align-items: center;
  border: 1px solid #d2d2d2;
  color: #787878;
  background: white;
  margin-left: 10px;
  text-transform: capitalize;
}

.statusButton:focus,
.statusButton:hover,
.active:hover,
.activeFocus {
  background: #3f71e0;
  color: white;
  border-color: #3f71e0;
}

.chevronIcon {
  margin-left: 5px;
}

.active {
  border-color: #3f71e0;
  color: #3f71e0;
  background-color: white;
}

.statusButton span {
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.statusMenu {
  border-radius: 5px;
  padding: 4px;
  max-height: 250px;
  overflow: scroll;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
  0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.statusMenu :global(.ant-radio-wrapper) {
  margin: 0;
  padding: 5px 12px;
  color: #666666;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
}

.statusMenuItem span:not(:global(.ant-wave-target)) {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
