html {
  background: #e3eff8;
}

body {
  background: #e3eff8;
}

.title {
  color: #19222a;
  font-size: 24px;
  font-weight: 900;
  text-align: center;
  margin: 0;
}

.form {
  margin: 20px 0 0 0;
}

.center {
  text-align: center;
}

.submit {
  margin: 0 0 15px 0;
}

.recaptcha > div > div {
  margin: 0 auto 30px auto;
}

.form :global(.ant-form-item-explain) {
  margin-top: 3px;
  margin-bottom: 5px;
  line-height: 1.3;
}

.form :global(.ant-form-item-extra) {
  margin-top: 3px;
  margin-bottom: 5px;
  line-height: 1.3;
}

.alert {
  margin-top: 25px;
  border-radius: 5px;
  border: 1px solid #20c0e7;;
}

.alertMessage {
  padding-top: 6px;
  padding-left: 6px;
}

.icon {
  color: #20c0e7;
  margin-left: 0.5em;
}
