.indicatorContainer {
  display: flex;
  gap: 4px;
  height: 18px;
}

.indicatorInfo {
  display: flex;
  align-items: center;
}

.indicatorLoader h3 {
  margin: 0;
}

.indicatorLoader ul {
  margin-block-start: 0 !important;
}
