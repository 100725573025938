.manageContainer {
  margin: 10px 4px;
}

.manageBox {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.logoBox {
  width: 125px;
  height: 125px;
  box-shadow: 0px 2px 8px rgb(199, 199, 199);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}
.logo {
  max-width: 95px;
  max-height: 45px;
  margin-top: -10px;
}

.moreInfo {
  width: 380px;
  margin-left: 20px;
}

.about {
  margin-top: 10px;
}

.check {
  max-width: 20px;
}

.connectedButton {
  color: #8bb863;
  display: flex;
  justify-content: space-between;
  width: 95px;
  align-items: center;
  margin: 5px 0;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.moreInfoHeader {
  margin: 0;
  line-height: 1.5rem;
}

.navigateBack,
.connectedButton,
.taskLog {
  padding: 0;
}

.taskLog {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 120px;
}

.taskLogIcon {
  max-width: 17px;
}

.taskLog,
.connectManage {
  margin: 5px;
}

.taskLogLink,
.taskLogLink:active,
.taskLogLink:visited,
.taskLogLink:hover {
  text-decoration: none;
}

.connectManageAnchor,
.connectManageAnchor:active,
.connectManageAnchor:visited,
.connectManageAnchor:hover {
  text-decoration: none;
  color: white;
}

.apiKey {
  font-weight: 600;
  letter-spacing: 0.5px;
  font-family: 'Courier New', Courier, monospace;
}

.zapierLink,
.zapierLink:active,
.zapierLink:visited,
.zapierLink:hover {
  text-decoration: none;
  color: #3f71e0 !important;
}

.need {
  font-weight: 500;
}
