.loaderIcon path,
.loaderIcon line {
  animation-timing-function: linear;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
}

.loaderIcon path:nth-child(1),
.loaderIcon line:nth-child(1) {
  stroke: #373737;
  animation-name: spin1;
}
.loaderIcon path:nth-child(3),
.loaderIcon line:nth-child(3) {
  stroke: #515151;
  animation-name: spin3;
}
.loaderIcon path:nth-child(5),
.loaderIcon line:nth-child(5) {
  stroke: #6a6a6a;
  animation-name: spin5;
}
.loaderIcon path:nth-child(7),
.loaderIcon line:nth-child(7) {
  stroke: #848484;
  animation-name: spin7;
}
.loaderIcon path:nth-child(2),
.loaderIcon line:nth-child(2) {
  stroke: #9d9d9d;
  animation-name: spin2;
}
.loaderIcon path:nth-child(4),
.loaderIcon line:nth-child(4) {
  stroke: #b6b6b6;
  animation-name: spin4;
}
.loaderIcon path:nth-child(6),
.loaderIcon line:nth-child(6) {
  stroke: #d0d0d0;
  animation-name: spin6;
}
.loaderIcon path:nth-child(8),
.loaderIcon line:nth-child(8) {
  stroke: #eaeaea;
  animation-name: spin8;
}

@keyframes spin1 {
  0% {
    stroke: #373737;
  }
  12.5% {
    stroke: #515151;
  }
  25% {
    stroke: #6a6a6a;
  }
  37.5% {
    stroke: #848484;
  }
  50% {
    stroke: #9d9d9d;
  }
  62.5% {
    stroke: #b6b6b6;
  }
  75% {
    stroke: #d0d0d0;
  }
  87.5% {
    stroke: #eaeaea;
  }
  100% {
    stroke: #373737;
  }
}
@keyframes spin3 {
  0% {
    stroke: #515151;
  }
  12.5% {
    stroke: #6a6a6a;
  }
  25% {
    stroke: #848484;
  }
  37.5% {
    stroke: #9d9d9d;
  }
  50% {
    stroke: #b6b6b6;
  }
  62.5% {
    stroke: #d0d0d0;
  }
  75% {
    stroke: #eaeaea;
  }
  87.5% {
    stroke: #373737;
  }
  100% {
    stroke: #515151;
  }
}
@keyframes spin5 {
  0% {
    stroke: #6a6a6a;
  }
  12.5% {
    stroke: #848484;
  }
  25% {
    stroke: #9d9d9d;
  }
  37.5% {
    stroke: #b6b6b6;
  }
  50% {
    stroke: #d0d0d0;
  }
  62.5% {
    stroke: #eaeaea;
  }
  75% {
    stroke: #373737;
  }
  87.5% {
    stroke: #515151;
  }
  100% {
    stroke: #6a6a6a;
  }
}
@keyframes spin7 {
  12.5% {
    stroke: #9d9d9d;
  }
  25% {
    stroke: #b6b6b6;
  }
  37.5% {
    stroke: #d0d0d0;
  }
  50% {
    stroke: #eaeaea;
  }
  62.5% {
    stroke: #373737;
  }
  75% {
    stroke: #515151;
  }
  87.5% {
    stroke: #6a6a6a;
  }
  100% {
    stroke: #848484;
  }
}
@keyframes spin2 {
  0% {
    stroke: #9d9d9d;
  }
  12.5% {
    stroke: #b6b6b6;
  }
  25% {
    stroke: #d0d0d0;
  }
  37.5% {
    stroke: #eaeaea;
  }
  50% {
    stroke: #373737;
  }
  62.5% {
    stroke: #515151;
  }
  75% {
    stroke: #6a6a6a;
  }
  87.5% {
    stroke: #848484;
  }
  100% {
    stroke: #9d9d9d;
  }
}
@keyframes spin4 {
  0% {
    stroke: #b6b6b6;
  }
  12.5% {
    stroke: #d0d0d0;
  }
  25% {
    stroke: #eaeaea;
  }
  37.5% {
    stroke: #373737;
  }
  50% {
    stroke: #515151;
  }
  62.5% {
    stroke: #6a6a6a;
  }
  75% {
    stroke: #848484;
  }
  87.5% {
    stroke: #9d9d9d;
  }
  100% {
    stroke: #b6b6b6;
  }
}
@keyframes spin6 {
  0% {
    stroke: #d0d0d0;
  }
  12.5% {
    stroke: #eaeaea;
  }
  25% {
    stroke: #373737;
  }
  37.5% {
    stroke: #515151;
  }
  50% {
    stroke: #6a6a6a;
  }
  62.5% {
    stroke: #848484;
  }
  75% {
    stroke: #9d9d9d;
  }
  87.5% {
    stroke: #b6b6b6;
  }
  100% {
    stroke: #d0d0d0;
  }
}
@keyframes spin8 {
  0% {
    stroke: #eaeaea;
  }
  12.5% {
    stroke: #373737;
  }
  25% {
    stroke: #515151;
  }
  37.5% {
    stroke: #6a6a6a;
  }
  50% {
    stroke: #848484;
  }
  62.5% {
    stroke: #9d9d9d;
  }
  75% {
    stroke: #b6b6b6;
  }
  87.5% {
    stroke: #d0d0d0;
  }
  100% {
    stroke: #eaeaea;
  }
}
