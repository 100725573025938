.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.attachFileButton,
.uploadFileButton {
  display: flex;
  align-items: center;
  padding: 2px;
}

.attachFileButton:hover span,
.attachFileButton:hover svg,
.attachFileButton:active svg,
.attachFileButton:active span {
  color: #1a49b0;
}

.attachFileButton:last-child {
  color: #3f71e0;
}

.attachIcon {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  margin-bottom: 2px;
}

.buttonContainer {
  display: inline-flex;
}

.outerProgress {
  position: relative;
  width: 100%;
  border-radius: 1.5px;
  height: 3px;
  background-color: #ccc;
  left: 0px;
}

.innerProgress {
  background-color: #20c0e7;
  border-radius: 1px;
  height: 3px;
}

.overlayContent {
  height: 128px;
  text-align: center;
  background: #ecebeb60;
  border: 2px dashed #ccc;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.iconBoxLarge {
  width: 70px;
  height: 70px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uploadIcon {
  width: 60%;
  height: 60%;
}
.instruction {
  color: #666666;
  margin-bottom: 5px;
  font-size: 14px;
}

.validFiles {
  color: #ccc;
  padding: 0 10px;
  font-size: 12px;
}

.completing {
  background-color: #5ec82e;
}

.completed {
  display: none;
}

.removeIcon {
  width: 14px;
}

.uploadList {
  margin-top: 10px;
}
.listItem {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  width: 100%;
}

.listInfoBox {
  position: relative;
  width: calc(100% - 17px);
  display: flex;
  justify-content: space-between;
}

.outerProgress {
  position: absolute;
  width: 100%;
  border-radius: 1.5px;
  height: 3px;
  background-color: #ccc;
  top: 22px;
  left: 0px;
}

.innerProgress {
  background-color: #20c0e7;
  border-radius: 1px;
  height: 3px;
}

.nameAndPreviewBox {
  display: flex;
  width: 90%;
}

.listRemoveIcon {
  width: 16px;
  height: 16px;
  stroke: #ccc;
}

.listAttachIcon {
  width: 12px;
  height: 12px;
  color: #666;
  margin-right: 5px;
}

.listRemoveIcon:hover {
  cursor: pointer;
  stroke: #666;
}

.linkContainer {
  margin-left: 5px;
}

.fileNameBox {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fileUpload {
  margin-bottom: 10px;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  line-height: 1.3;
}
