.header {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}

.section {
  margin-bottom: 2.5em;
}

.list {
  list-style: none;
  margin: 1em 0;
}

.list li {
  margin-bottom: 0.3em;
}

.scheduleContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
}

.scheduleLabel {
  font-weight: bold;
}

.container h3 {
  margin: 0 1em 0 0;
}

.greenTickIcon {
  margin-right: 5px;
  min-width: 19px;
  position: relative;
  top: 4px;
}

@media (max-width: 767px) {
  .scheduleContainer {
    gap: 8px;
  }
}
