.navIcon {
  width: 18px;
  height: 18px;
  color: var(--colorIcons);
}

.container {
  padding: 0px 20px;
}

.collapsed .hideIcon {
  transform: rotate(180deg);
}

.divider {
  margin: 14px 0;
  border-block-start-width: 2px;
  background-color: #d9e7f4;
}

.mainSection {
  padding-top: 20px;
}

.gettingStartedView {
  padding-top: 10px;
}

.mainSectionExpanded {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--colorBgBase);
}

.footerSection {
  padding-bottom: 20px;
  background-color: var(--colorBgBase);
}

.footerSectionExpanded {
  position: sticky;
  bottom: 0;
  z-index: 1;
}

.gettingStarted {
  background: var(--colorInfoBorder);
  color: var(--colorText) !important;
  width: auto;
}

.collapsed .divider {
  min-width: unset;
  width: 33px;
}

@media (max-width: 992px) {
  .container {
    height: calc(100vh - 64px);
  }
}

@media (max-width: 640px) {
  .divider {
    display: none;
  }

  .container {
    padding: 10px;
    display: none;
  }
}

.referralContainer {
  padding: 12px 20px;
}
