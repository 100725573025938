.attachFileContainer {
  margin-left: 10px;
}

.fullWidthVersion {
  max-width: 100%;
}

.attachFileButton {
  display: flex;
  align-items: center;
  padding-left: 0px;
}

.overlayBox {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(199, 199, 199, 0.7);
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.isDragging {
  display: flex;
}

.overlayContent {
  width: 80%;
  height: 70%;
  text-align: center;
  background: #f5f5f5;
  border: 1px dashed #ccc;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.iconBoxLarge {
  width: 70px;
  height: 70px;
  margin: 0 auto;
}

.uploadIcon {
  width: 54px;
  height: 54px;
}
.instruction {
  color: #666666;
  font-size: 14px;
  font-weight: 400;
}

.attachIcon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
