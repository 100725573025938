.teamsExplanation {
  margin-bottom: 20px;
}

.teamsExplanation ul {
  list-style-type: circle;
  padding-left: 20px;
  margin-bottom: 20px;
}

.teamsChannelHeader {
  padding: 0px 0px 2px 14px;
  font-weight: bold;
}

.infoContainer {
  padding-left: 10px;
}

.teamsEditRow :global(.ant-col) {
  padding-right: 6px;
  padding-left: 6px;
}

.save {
  margin-right: 2px;
}

.save,
.save:active,
.save:focus {
  background-color: #3f71e0;
  color: #fff;
  border-color: #d9d9d9;
}

.disconnectContainer {
  display: flex;
  border: 1px solid #d2d2d2;
  justify-content: space-between;
  align-items: center;
  padding: 20px 15px;
}

.disconnectButton {
  color: #d0021b;
  border: 1px solid #d9d9d9;
}

.disconnectButton:hover {
  background: #d0021b;
  color: #ffff;
}