.arrowIcon,
.mailIcon {
  width: 18px;
  height: 18px;
}

.arrowIcon circle {
  fill: white;
}

.arrowIcon path {
  fill: #af30d7;
}

.popover {
  border-radius: 10px;
  max-width: 340px;
}

.popover :global(.ant-popover-inner-content) {
  padding: 0;
  border-radius: 10px;
}

.popover :global(.ant-popover-inner) {
  border-radius: 10px;
}

.contentOuter {
  max-width: 320px;
  overflow: hidden;
}

.smallContentOuter {
  max-width: 260px;
}

.content {
  padding: 20px;
}

.discoverFeature {
  font-size: 16px;
  font-weight: 600;
  margin-top: 0;
}

.discovered {
  color: #786ea7;
}

.upgrade,
.contactOwner {
  width: 100%;
  background-color: #af30d7;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 6px 15px !important;
  height: unset;
}

.upgrade span, .contactOwner span {
  padding-top: 6px;
  display: flex;
  align-items: center;
}

.arrowIcon {
  width: 16px;
  height: 16px;
  margin-right: 7px;
  margin-bottom: 1px;
}

.mailIcon {
  margin-right: 7px;
  margin-bottom: 2px;
}

.upgrade:hover,
.contactOwner:hover {
  background-color: #7c00a4;
}

.upgrade:hover .arrowIcon path,
.contactOwner:hover .arrowIcon path {
  fill: #7c00a4;
}

.upgrade:focus .arrowIcon path,
.upgrade:active .arrowIcon path,
.contactOwner:focus .arrowIcon path,
.contactOwner:active .arrowIcon path {
  fill: #490071;
}

.upgrade:focus,
.upgrade:active,
.contactOwner:focus,
.contactOwner:active {
  background-color: #490071;
}

.image {
  max-width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
