.navBar {
  padding: 20px;
  display: flex;
  align-items: center;
}

.logoContainer {
  background: #19222a;
  width: 32px;
  display: flex;
  height: 32px;
  padding: 8px 5px 8px 7px;
  align-items: center;
  border-radius: 3px;
  justify-content: center;
}

.projectLink {
  color: #3f71e0;
  padding-right: 10px;
  font-weight: bold;
}

a.disabledLink {
  color: #333333 !important;
  text-decoration: none;
  pointer-events: none;
}

.projectLink span {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

a.projectLink {
  text-decoration: none !important;
}

a.projectLink:hover {
  color: #1a49b0;
  text-decoration: underline !important;
}

.assetName {
  padding: 0 10px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 970px) {
  :global(.isNotPdf.sidebarClosed) .projectLink span {
    max-width: 120px;
  }

  :global(.isNotPdf.sidebarClosed) .assetName {
    max-width: 150px;
  }
}

@media (max-width: 730px) {
  :global(.isNotPdf.sidebarClosed) .chevronIcon {
    display: none;
  }

  :global(.isNotPdf.sidebarClosed) .projectLink {
    display: none;
  }

  :global(.isNotPdf.sidebarClosed) .assetName {
    display: none;
  }
}

@media (max-width: 1035px) {
  :global(.isNotPdf.sidebarOpen) .projectLink span {
    max-width: 120px;
  }

  :global(.isNotPdf.sidebarOpen) .assetName {
    max-width: 150px;
  }
}

@media (max-width: 760px) {
  :global(.isNotPdf.sidebarOpen) .chevronIcon {
    display: none;
  }

  :global(.isNotPdf.sidebarOpen) .projectLink {
    display: none;
  }

  :global(.isNotPdf.sidebarOpen) .assetName {
    display: none;
  }
}

@media (max-width: 1190px) {
  :global(.isPdf.sidebarOpen) .projectLink span {
    max-width: 120px;
  }

  :global(.isPdf.sidebarOpen) .assetName {
    max-width: 150px;
  }
}

@media (max-width: 960px) {
  :global(.isPdf.sidebarOpen) .chevronIcon {
    display: none;
  }

  :global(.isPdf.sidebarOpen) .projectLink {
    display: none;
  }

  :global(.isPdf.sidebarOpen) .assetName {
    display: none;
  }
}

@media (max-width: 1120px) {
  :global(.isPdf.sidebarClosed) .projectLink span {
    max-width: 120px;
  }

  :global(.isPdf.sidebarClosed) .assetName {
    max-width: 150px;
  }
}

@media (max-width: 890px) {
  :global(.isPdf.sidebarClosed) .chevronIcon {
    display: none;
  }

  :global(.isPdf.sidebarClosed) .projectLink {
    display: none;
  }

  :global(.isPdf.sidebarClosed) .assetName {
    display: none;
  }
}

@media (max-width: 610px) {
  :global(.isPdf.sidebarOpen) .navBar {
    padding-right: 5px;
  }
}
