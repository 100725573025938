.container {
  position: fixed;
  bottom: 40px;
  min-width: fit-content;
  opacity: 1;
  box-shadow: 0 10px 10px 0 rgba(0, 57, 128, 0.2);
  border-radius: 12px;
  background: #ffffff;
  z-index: 100;
  display: flex;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid #d5d1d1;
  max-width: 95%;
}

.count {
  margin-left: 10px;
  margin-right: 10px;
  white-space: nowrap;
}

.closeContainer {
  min-width: 30px;
  min-height: 30px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f5eeed;
  color: #d46060;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
}

.closeIcon {
  width: 14px;
  height: 14px;
}

.updateButton {
  display: flex;
  align-items: center;
  width: 114px;
  transition: opacity 600ms ease-in-out 600ms;
}

.clearButton:hover,
.clearButton:focus {
  background: white;
}

.clearButton {
  opacity: 1;
  width: 66px;
  transition: opacity 600ms ease-in-out 600ms;
}

.hideClear,
.hideUpdate {
  opacity: 0;
  transition: opacity 600ms ease-in-out;
  pointer-events: none;
}

.updating .updateButton {
  width: 142px;
}

.clearAndUpdate {
  display: flex;
  align-items: center;
  margin-right: 5px;
  width: 230px;
  transition: width 400ms ease-in-out 300ms;
  justify-content: space-between;
}

.updating.clearAndUpdate {
  width: 258px;
}

.hideClearAndUpdate {
  width: 50px;
  transition: width 400ms ease-in-out 400ms;
  margin-right: 10px;
}

.bulkButtons {
  display: flex;
  width: 100%;
  gap: 8px;
}

.innerContainer {
  display: grid;
  grid-template-areas: 'tasksSelected bulkButtons clearAndUpdate closeContainer';
  align-items: center;
  padding: 10px 40px 10px 10px;
}

.tasksSelected {
  grid-area: tasksSelected;
  min-width: max-content;
}

.bulkButtons {
  grid-area: bulkButtons;
}

.clearAndUpdate {
  grid-area: clearAndUpdate;
}

.closeContainer {
  grid-area: closeContainer;
}

@media (max-width: 1080px) {
  .bulkButtons {
    flex-wrap: wrap;
  }

  .innerContainer {
    grid-template-areas:
      'tasksSelected closeContainer'
      'bulkButtons bulkButtons'
      'clearAndUpdate clearAndUpdate';
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
    gap: 10px;
  }

  .clearAndUpdate {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

.limitReached {
  color: #3f71e0;
  font-weight: 600;
  margin-right: 10px;
}
