.contentInner {
  text-align: center;
}

.modal {
  top: calc(100vh - 225px);
}

.contentInner p {
  font-weight: 300;
}
