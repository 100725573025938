.shareButton .shareIcon {
  width: 20px;
  height: 20px;
}

.iconOnly {
  border: none;
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.iconOnly .shareIcon {
  width: 24px;
  height: 24px;
}
