.requestOuter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.archivedAt,
.details {
  margin: 0;
}

.details {
  font-size: 12px;
  color: #ccc;
  padding-top: 3px;
}

.archivedList {
  overflow: auto;
  max-height: 330px;
}

.archivedList li:not(:first-child) {
  border-top: 1px solid #ccc;
  padding-top: 5px;
}

.about {
  margin-bottom: 2em;
}

.scrollable {
  border-radius: 3px;
  box-shadow: 0px 0px 3px rgba(204, 204, 204, 0.666);
  padding: 7px;
  max-height: 344px;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 15px;
}

.restoreTasks {
  padding-right: 2px;
}

.loaderWrapper {
  display: flex;
  justify-content: center;
}
