.harvestModalContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.disconnectContainer {
  display: flex;
  border: 1px solid #d2d2d2;
  justify-content: space-between;
  align-items: center;
  padding: 20px 15px;
}

.disconnectButton {
  color: #d0021b;
  border: 1px solid #d9d9d9;
}

.disconnectButton:hover {
  background: #d0021b;
  color: #ffff;
}
