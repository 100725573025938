.listItem {
  display: flex;
  padding: 10px 0 0 10px;
}

.adminListItem {
  padding: 15px;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.itemInner {
  margin-left: 10px;
}

.itemInfo {
  font-size: 10.5px;
  color: #333;
  margin: 4px 0;
}

.item {
  font-size: 12px;
  margin-bottom: 6px;
}
.userName {
  margin-right: 4px;
  font-weight: 400;
  color: #7e8fa2;
}

.list {
  padding-inline-start: 0;
}
