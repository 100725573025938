.label {
  text-transform: uppercase;
  margin: 0 0 25px 0;
}

.list {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}

.list :global(.ant-list-items) {
  width: 100%;
}

.list :global(.ant-list-pagination) {
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}

.listItem {
  padding: 0 !important;
}

.card {
  padding: 15px 12px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  margin: 8px 0;
  border-radius: 4px;
  background-color: #ffffff;
}

.card:hover {
  cursor: pointer;
}

.cardBackground {
  border: 1px solid #efdba7;
  background-color: #fef6d9;
}

.highlightMention {
  background-color: #e7f6fc;
}

.commenterInfo {
  color: #656667;
  font-size: 12px;
  font-weight: 900;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.text {
  border-left: 8px solid #ebebeb;
  padding-left: 10px;
}

.projectName {
  color: #ffffff;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 900;
  white-space: nowrap;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 3px;
  letter-spacing: 0.5px;
}

.backgroundText {
  background-color: #a5a5a5;
  padding: 0 3px;
  border-radius: 3px;
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.eyeIcon {
  width: 16px;
  height: 16px;
  stroke: #666;
  margin: 0px 3px 2px 0px;
}

.visibleTo {
  font-size: 11px;
  font-weight: 500;
  color: #666666;
}

.privateCommentFooter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
}

.search {
  opacity: 0.7;
  width: 14px;
  height: 14px;
}

.searchInput {
  width: 100%;
}

.searchText {
  background-color: #ffff00;
}

.search {
  opacity: 0.7;
  width: 14px;
  height: 14px;
}

.helpIconOuter {
  margin-left: 10px;
  color: #20c0e7;
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 1em 0 0 0;
}
