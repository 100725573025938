.notifications {
  & b {
    display: inline-block;
    margin-bottom: 1rem;
  }
}

.helpArticle {
  margin-left: 4px;
}

.switchWrapper {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 12px;
  cursor: pointer;

  & .switchLink {
    color: var(--colorGrey2);
    margin-block: 0.8rem;
    display: flex;
    gap: 0.5rem;
  }
}

.saveButton {
  margin-top: 1rem;
}

.upgradeAlert {
  background-color: #ebebf2;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.upgradeText {
  margin-left: 10px;
}

.upgradeText a {
  color: #9232bc;
  font-weight: 600;
  text-decoration: none;
  margin-left: 5px;
}

.assignedToTasks {
  margin-bottom: 25px;
}
