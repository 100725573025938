/*these are antd overrides, we should try and remove these*/
.priority0 {
  color: #ccc !important;
}

.priority1 {
  color: #d46060 !important;
}

.priority2 {
  color: #e8b019 !important;
}

.priority3 {
  color: #60c5d4 !important;
}

.priority4 {
  color: #8bb863 !important;
}

.active0 {
  background-color: #ccc !important;
  color: white !important;
}
.active1 {
  background-color: #d46060 !important;
  color: white !important;
}
.active2 {
  background-color: #e8b019 !important;
  color: white !important;
}
.active3 {
  background-color: #60c5d4 !important;
  color: white !important;
}
.active4 {
  background-color: #8bb863 !important;
  color: white !important;
}

.severityOuter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 4px 0;
  border-bottom: 1px solid #dbe3e6;
  width: 100%;
  min-height: 24px;
}
