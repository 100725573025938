.cardOuter {
  border-radius: 8px;
  border: 1.5px solid #b6b6cfb3;
  margin-bottom: 10px;
}

.cardTop {
  background-color: #ececf3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-radius: 8px 8px 0 0;
}

.cardBottom {
  padding: 8px;
  background-color: white;
  border-radius: 0 0 8px 8px;
}

.noPadding {
  padding: 0px;
}

.settingsIcon {
  width: 20px;
  height: 20px;
  background: none;
  border-radius: 50%;
  box-shadow: 0 0 0 rgba(63, 113, 224, 0.4);
}

.integrationLabel {
  padding-left: 10px;
  font-weight: 600;
  font-size: 16px;
}

.integration {
  display: flex;
  align-items: center;
}

.integrationLogo {
  display: block;
  width: 30px;
  height: 30px;
  background-size: 85%;
  background-color: white;
  background-position: center;
  background-repeat: no-repeat;
}

.baseCampLogo {
  background-image: url('../../../../../assets/images/icons/integrations/ic-integration-basecamp.png');
}

.githubLogo {
  background-image: url('../../../../../assets/images/icons/integrations/ic-integration-github.png');
}

.slackLogo {
  background-image: url('../../../../../assets/images/icons/integrations/ic-integration-slack.png');
}

.trelloLogo {
  background-image: url('../../../../../assets/images/icons/integrations/ic-integration-trello.png');
}

.linearLogo {
  background-image: url('../../../../../assets/images/icons/integrations/ic-integration-linear.png');
}

.zapierLogo {
  background-image: url('../../../../../assets/images/icons/integrations/ic-integration-zapier.png');
}

.asanaLogo {
  background-image: url('../../../../../assets/images/icons/integrations/ic-integration-asana.png');
}

.azureDevOpsLogo {
  background-image: url('../../../../../assets/images/icons/integrations/ic-integration-azuredevops.png');
}

.clickupLogo {
  background-image: url('../../../../../assets/images/icons/integrations/ic-integration-clickup.png');
}

.jiraLogo {
  background-image: url('../../../../../assets/images/icons/integrations/ic-integration-jira.png');
  background-size: 110%;
}

.mondaycomLogo {
  background-image: url('../../../../../assets/images/icons/integrations/ic-integration-monday.png');
}

.productiveLogo {
  background-image: url('../../../../../assets/images/icons/integrations/ic-integration-productiveio.png');
}

.fullStoryLogo {
  background-image: url('../../../../../assets/images/icons/integrations/ic-integration-fullstory.png');
}

.logRocketLogo {
  background-image: url('../../../../../assets/images/icons/integrations/ic-integration-logrocket.png');
}

.harvestLogo {
  background-image: url('../../../../../assets/images/icons/integrations/ic-integration-harvest.png');
}

.teamworkLogo {
  background-image: url('../../../../../assets/images/icons/integrations/ic-integration-teamwork.png');
}

.settingsButton {
  display: flex;
  align-items: center;
  padding: 0;
  width: fit-content;
}
