.optionContainer {
  display: flex;
  align-items: center;
}

.optionContainer .optionId {
  max-width: 25%;
  min-width: 25%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  padding-right: 5px;
  color: #aaaaaa;
}

.optionContainer .optionName {
  max-width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.smallButton {
  font-size: 12px;
}

.smallButton:hover {
  background-color: #1a49b0 !important;
}

.linkExisting {
  margin-left: 10px;
  border-radius: 20px;
}

.linkExisting:hover {
  background-color: #1a49b0 !important;
  border-color: #1a49b0;
  color: #fff;
}

.linkExisting:active,
.linkExisting:focus {
  background-color: #133683 !important;
  border-color: #133683;
  color: #fff;
}

.connectContainer {
  width: 100%;
}

.searchContainer {
  display: block;
  width: 100%;
}

.searchContainer .connectedText {
  margin-bottom: 8px;
}

.smallSearch .connectedText,
.smallSearch .searchInput {
  font-size: 12.5px;
}

.searchOuter {
  width: 100%;
  margin-top: 8px;
}

.linkTaskAutoComplete {
  width: 100%;
}

.searchInput {
  padding-left: 5px;
}

.searchIcon,
.loaderIcon {
  width: 15px;
  height: 15px;
}

.searchIcon {
  color: #c7c7c7;
}

.searchActions {
  margin-top: 8px;
}

.linkTaskButton {
  margin-right: 3px;
}

.alertIcon {
  width: 15px;
  height: 15px;
}

.alertIcon circle {
  color: #faad14;
  fill: #faad14;
}

.alertIcon line {
  color: white;
}

.connectingInner,
.configRequired {
  color: #848484;
  font-weight: 500;
}
