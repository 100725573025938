.inputFlex {
  display: flex !important;
  align-items: center;
}

.removeUrl {
  width: 16px;
  height: 16px;
}

.justifyIcon {
  padding: 4px;
}

.columnContainer {
  margin-bottom: 16px;
}

.columnInputGroup {
  width: 495px;
}

.columnInputWrapper {
  width: 100%;
  margin: 0;
}

.columnInput {
  border-radius: 0px 6px 6px 0px;
}

.columnInput::placeholder {
  font-weight: 500;
}

.inputFlex :global(input:focus) {
  border: 1px solid #3f71e0;
  box-shadow: none;
  color: #666666;
  caret-color: #666666;
}

.removeButton {
  justify-content: center;
  display: flex;
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  cursor: pointer;
  color: #848484;
  margin-left: 8px;
}

.removeButton:hover {
  background-color: #ff4d4f;
  border-color: #ff4d4f;
}

.removeButton:disabled,
.removeButton:disabled:hover {
  cursor: not-allowed;
  border: none;
  background: none;
  color: #848484;
}

.arrangeButton,
.arrangeButton:hover,
.arrangeButton:active {
  background-color: #eef1f3;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: 1px solid #d9d9d9;
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  border-radius: 6px 0px 0px 6px;
}

.heading {
  font-size: 1rem;
  color: #19222a;
  margin-bottom: 0;
  font-weight: bold;
}

.columns {
  display: flex;
  flex-direction: column;
}

.actionButton {
  color: #3f71e0;
  padding: 0;
  font-weight: 500;
}

.refreshButton {
  display: flex;
  align-items: center;
  gap: 4px;
}

.refreshIcon {
  font-size: 1rem;
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
  max-width: 495px;
  margin-bottom: 16px;
}

.saveButton {
  height: 40px;
  letter-spacing: 0.5px;
  font-weight: 100;
  margin: 10px 0 0 0;
  padding: 0 20px;
}
