.screenshotModal {
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
}

.screenshotModal :global(.ant-modal-close-x) {
  font-size: 30px;
  color: #fff;
  background: #000;
  border-radius: 50%;
  transform: scale(0.6);
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.screenshotModal :global(.ant-modal-body) {
  padding: 0;
}

.screenshotModal :global(.ant-modal-content) {
  background: transparent;
}

.screenshotModal :global(.ant-modal-content),
.screenshotModal :global(.ant-modal-body) {
  height: 100%;
}

.screenshotModal :global(.ant-radio-button-wrapper) {
  background: #fff;
}
