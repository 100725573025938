.popover {
  max-width: 200px;
}

.popoverContent p {
  display: flex;
  justify-content: space-between;
}

.popoverIcon {
  padding-right: 8px;
  padding-top: 3px;
  color: rgb(250, 173, 20);
}

.okContainer {
  display: flex;
  justify-content: flex-end;
}

.locationSave,
.popoverOk {
  background-color: #3f71e0;
}

.locationSave:hover,
.popoverOk:hover {
  background-color: #1a49b0;
}

.locationSave:focus,
.popoverOk:focus,
.locationSave:active,
.popoverOk:active .locationSave:visited,
.popoverOk:visited {
  background-color: #133683;
}
