.uploadList {
  margin-top: 10px;
  list-style: none;
  padding: 0;
}

.listItem {
  margin-bottom: 10px;
  align-items: center;
  width: 100%;
}
