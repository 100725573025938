.descriptionOuter {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.count {
  padding-top: 5px;
}

.countForMentions {
  margin-left: -3px;
}

.user,
.emoji {
  display: flex;
  max-height: 40px;
  padding-right: 5px;
}

.emoji {
  align-items: center;
}

.selected {
  background: rgba(32, 192, 231, 0.314);
  border-radius: 5px;
}

.avatarContainer,
.tagContainer,
.emojiContainer {
  max-width: 40px;
  max-height: 40px;
  padding: 4px;
  margin-right: 4px;
}

.tagContainer,
.emojiContainer {
  display: flex;
  align-items: center;
}

.tagIcon {
  width: 18px;
  height: 18px;
}

.adminDescriptionMentions__control,
.adminDescriptionMentions__input,
.descriptionMentions__control,
.descriptionMentions__input {
  transition: padding ease 0.2s;
}

.adminDescriptionMentions__input,
.descriptionMentions__input {
  font-weight: normal !important;
  padding: 5px 10px 5px 3px !important;
  border-color: #d9d9d9;
  border-radius: 5px;
}

.adminDescriptionMentions__input:focus,
.descriptionMentions__input:focus {
  padding-bottom: 15px !important;
  box-shadow: 0px 0px 0px 2px rgba(32, 192, 231, 0.2);
}
.adminDescriptionMentions__input,
.descriptionMentions__input {
  background-color: #fff !important;
  min-height: 120px;
  max-height: 400px;
  border: none;
  overflow-y: auto !important;
}

.adminDescriptionMentions__highlighter,
.descriptionMentions__highlighter {
  line-height: 1.5;
  padding: 6px 11px 20px 11px;
  word-break: break-all;
}

.adminDescriptionMentions__suggestions,
.descriptionMentions__suggestions {
  left: 0;
  margin: 18px auto 0 auto;
  border-radius: 5px;
  max-width: 95%;
  box-shadow: 0px 0px 25px 4px rgba(0, 0, 0, 0.25);
  right: auto;
  z-index: 11 !important;
}

.adminDescriptionMentions__suggestions__list,
.descriptionMentions__suggestions__list {
  max-height: 145px;
  overflow: auto;
  margin: 5px 5px 2px 5px !important;
}

.adminDescriptionMentions__suggestions__item__display,
.descriptionMentions__suggestions__item__display {
  color: #666666;
  margin: auto 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100% - 40px);
}

.mentions {
  background: linear-gradient(to bottom, rgb(255, 255, 255) 97%, #20c0e7 98%);
}

.commentText {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  white-space: pre-line;
  word-break: break-word;
}

.body {
  white-space: pre-line;
  font-size: 14px;
  word-break: break-word;
  font-weight: 400;
}

.description textarea {
  padding: 0 0 4px 4px;
}

.description :global(.ant-input-textarea-show-count)::after {
  text-align: left;
  color: #666666;
}

.linkifyOuter {
  cursor: pointer;
  font-weight: normal !important;
  padding: 7px 10px 7px 3px !important;
  border-color: #d9d9d9;
  border-radius: 5px;
  min-height: 190px;
  max-height: inherit;
  overflow-y: auto;
  flex-grow: 1;
}

.hideHighlights {
  display: none;
}

.hideMentionInput {
  display: none;
}

.descriptionInner {
  margin-bottom: 10px;
  flex-grow: 1;
  display: flex;
  aspect-ratio: 16/9;
  min-height: 120px;
  max-height: 400px;

  & textarea {
    height: unset !important;
    overflow-y: auto !important;
    max-height: unset !important;
    min-height: unset !important;
  }
}

.descriptionFooter {
  display: flex;
  align-items: center;
}

.submissionShortcut {
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-size: 11px;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.shortcutVisible {
  opacity: 1;
}

@media (max-width: 600px) {
  .linkifyOuter {
    min-height: 111px;
    max-height: 300px;
  }

  .adminDescriptionMentions__input,
  .descriptionMentions__input {
    min-height: 111px;
    max-height: 300px;
  }

  .descriptionInner {
    min-height: 111px;
    max-height: 300px;
  }
}

.description {
  border-radius: 7px;
}
