:global(.select-container.ant-form-item-control-wrapper) {
  width: 100%;
}

.webookListContainer {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  padding-bottom: 10px;
}

.webhookListHeader {
  padding-bottom: 10px;
}

.webhookListHeader :global(.ant-row) {
  background-color: #151e26;
  color: #ffffff;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.webhookListHeader :global(.ant-col) {
  margin-top: 16px;
  margin-bottom: 16px;
  white-space: nowrap;
  text-align: left;
  padding-left: 6px;
  padding-right: 6px;
}

.webhookListHeader :global(.ant-col:first-child) {
  padding-left: 16px;
}

.webhookListHeader :global(.ant-col:last-child) {
  padding-right: 16px;
}

.webhookListRow :global(.ant-row) {
  border-right: 1px solid #151e26;
  border-left: 1px solid #151e26;
}

.webhookListRow :global(.ant-col) {
  margin-top: 8px;
  margin-bottom: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  padding-left: 6px;
  padding-right: 6px;
}

.webhookListRow :global(.ant-col:first-child) {
  padding-left: 16px;
}

.webhookListRow .editControls {
  margin: 0;
  display: flex;
  align-items: center;
  column-gap: 6px;
}

.webhookEditRow :global(.ant-col) {
  padding-right: 6px;
  padding-left: 6px;
}

.webhookEditRow :global(.ant-col:first-child) {
  padding-left: 16px;
}

.webhookEditRow :global(.ant-col:first-child .ant-form-item-control) {
  padding-left: 0;
}

.webhookEditRow :global(.ant-col:last-child) {
  padding-right: 0;
}

.webhookEditRow .addControls:global(.ant-col:last-child) {
  padding: 0 10px 0 0;
}

.webhookEditForm {
  border-top: 1px solid #d9d9d9;
  padding-top: 10px;
}

.webhookEditForm.noUpperBorder {
  border-top: 0;
}

.webhookEditRow :global(.ant-col) {
  padding: 0;
}

.webhookEditRow .editControls {
  padding: 0 10px 0 0;
}

.webhookEditFormInline .webhookEditRow :global(.ant-form-item) {
  margin-bottom: 0;
}

.deletePopoverText {
  color: initial;
}

.deletePopover {
  height: 25px;
  color: #d0021c;
}

.deletePopoverText :global(.ant-popover-inner-content) {
  padding: 0 6px 6px;
}

.deletePopoverButton {
  color: #d0021c;
  padding: 0;
}

.deletePopoverButton svg {
  width: 20px;
}

.deletePopoverButton span {
  position: relative;
  bottom: 6px;
}

.deleteButton {
  height: 24px;
  width: 24px;
  border: none;
}

.deleteButton:active,
.deleteButton:focus {
  color: #000;
  border: none;
}

.deleteButton:hover {
  color: #fff !important;
  border: none;
}

.deleteButton:hover span {
  background-color: #1a49b0;
  border-radius: 50%;
}

.addNewWebhook {
  margin-top: 20px;
}

.editButton,
.editButton:active,
.editButton:focus {
  background-color: #ffffff;
  color: #3f71e0;
  border-color: #d9d9d9;
}

.editButton:hover {
  background-color: #3f71e0;
}

.addButton {
  margin-right: 2px;
}

.addButton,
.addButton:active,
.addButton:focus {
  background-color: #3f71e0;
  color: #fff;
  border-color: #d9d9d9;
}

.headerTooltip {
  color: #3f71e0;
  padding-left: 6px;
  padding-top: 10px;
}

.webhookEditForm :global(.ant-form-item-explain),
.webhookEditFormInline :global(.ant-form-item-explain) {
  margin: 8px 0;
}

.editControls .deleteButton {
  color: #000;
}

:global(.ant-select-selection-search-input) {
  cursor: default;
}
