.sharingContainer {
  width: 100vw;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sharingContent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sharingExtensionImage {
  border-radius: 20px;
  margin-bottom: 10px;
}

.installExtension {
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.messaging {
  text-align: center;
}

.installButton {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.browsers {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.extensionItem {
  opacity: 0.6;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 120px;
}

.active {
  opacity: 1;
}

.extensionItem:hover {
  opacity: 1;
}

.browserLink {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 120px;
}

.extensionItem:hover img {
  transform: scale(1.05);
}

.extensionItem img,
.extensionItem svg {
  width: 70px;
  height: 70px;
}

.logoWrapper {
  display: flex;
  align-items: center;
  background: white;
  border-radius: 50%;
  padding: 5px;
  position: absolute;
  left: 10px;
}

.logoWrapper img,
.logoWrapper svg {
  width: 25px;
  height: 25px;
}

.noJavascript {
  padding: 60px 30px 0 30px;
  text-align: center;
}

.alert {
  margin: 0 auto 20px auto;
  width: 250px;
  text-align: left;
}

.contactOwner {
  display: block;
  margin: 0 auto 20px auto;
  font-weight: 600;
  max-width: 180px;
}

.noJavascript p {
  margin: 0 auto 20px auto;
  max-width: 330px;
}

.noJavascript h2 {
  margin: 0 auto 20px auto;
  max-width: 310px;
}

.learnMore {
  height: 17px;
  padding-left: 3px;
  padding-right: 3px;
}

@media (max-width: 400px) {
  .installExtension {
    width: 370px;
  }
}
