.placeholderModal {
  top: 0px;
}

.placeholderModal h2,
.modal h2 {
  font-weight: 600;
  margin: 0 0 1.8rem 0;
  color: 19222a;
}

.placeholderModal :global(.ant-modal-content),
.modal :global(.ant-modal-content) {
  border-radius: 10px;
}

.figmaModal :global(.ant-modal-content) {
  max-width: 500px;
  margin: auto;
  min-height: 240px;
  position: relative;
  overflow: hidden;
}

.marketingImage {
  padding-bottom: 10px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  padding-top: 25px;
}

@media screen and (min-width: 750px) {
  .contentContainer {
    flex-direction: row;
    justify-content: space-between;
  }

  .placeholderModal {
    /* required to override antd styles */
    top: 100px;
  }
}

.shareModal :global(.ant-modal-close) {
  height: 30px;
  width: 30px;
}

.closeContainer {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 50%;
  background-color: #f1f1f1;
  color: #d46060;
  cursor: pointer;
  z-index: 1;
}

.closeIcon {
  width: 14px;
  height: 14px;
  display: block !important;
  /* Override the vanishing behaviour present in the task tab*/
}

.container {
  position: relative;
}

.container :hover.icon,
.container a:hover,
.container.showAssets,
.container.showAssets .icon {
  color: #3f71e0;
  stroke: #3f71e0;
  transition: none;
}

@media screen and (max-width: 851px) {
  .badge {
    display: none;
  }
}

.mainScreen {
  height: fit-content;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding-bottom: 2rem;
}

.header {
  padding: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.firstTimeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.firstTimeContent p {
  font-size: 16px;
  font-weight: 600;
  padding: 20px;
  margin: 0px;
}

.loaderIcon {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}

.uploadButton {
  display: flex;
  align-items: center;
}

.uploadModal :global(.ant-modal-content) {
  border-radius: 10px;
}

.uploadModal :global(.ant-modal-body) {
  height: 327px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 16px;
}

.uploadModal p {
  margin: 16px;
}

.modalContent a {
  color: #3f71e0;
  border: none;
  display: unset;
  padding: 0px;
}

.modalContent a:hover {
  color: #1a49b0;
}

.filterContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.archivedFilter {
  margin-right: 8px;
}

.cardsContainer {
  padding: 20px 20px 90px 20px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  border-radius: 0 0 15px 15px;
}

.card {
  min-width: 280px;
  max-width: 280px;
  border-radius: 8px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.13);
  background-color: #ffffff;
  margin: 0 20px 20px 0;
  text-decoration: none;
  position: relative;
  height: fit-content;
  transition: all 0.5s ease;
  overflow: hidden;
  position: relative;
}

.archived {
  color: #fff;
  border-radius: 11px;
  background: #a2adb0;
  font-size: 0.75rem;
  transition: all 0.2s ease;
  padding: 0.1em 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -300%);
}

.grayscale {
  filter: grayscale(100%);
}

.card:hover {
  box-shadow: 0 10px 10px 0 rgba(0, 57, 128, 0.2);
}

.card h3 {
  color: #19222a;
  border: 1px solid transparent;
  margin-top: 12.4px;
  margin-bottom: 6px;
  padding: 2px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card h3:hover {
  border: 1px solid #ccc;
  box-shadow: inset 0 0 2px #ccc;
}

.guestCard h3:hover {
  box-shadow: unset;
  border-color: transparent;
}

.guestCard {
  min-width: unset;
  position: relative;
  height: fit-content;
  width: 33.333%;
}

@media (max-width: 650px) and (min-width: 551px) {
  .guestCard {
    width: 50%;
  }
}

@media (max-width: 550px) {
  .guestCard {
    width: 100%;
  }
}

.card img,
.card .pdfDocument {
  border-radius: 8px 8px 0 0;
  width: 100%;
  height: 165px;
  max-height: 165px;
  padding: 10px;
  background: #000000;
}

.imgWrapper {
  height: 165px;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.pdfDocument {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.card img {
  object-fit: cover;
}

.card .pdfPage {
  width: 100%;
  max-height: 150px;
  overflow-y: hidden;
}

.pdfPage canvas {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  object-position: top;
}

.card .lastUpdated {
  padding-left: 2px;
}

.card p {
  color: #848484;
  font-size: 11px;
}

.cardContent {
  text-align: left;
  padding: 0 20px 18px 20px;
}

.cardFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2px;
  column-gap: 10px;

  @media (width < 515px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.typeInfo {
  color: #919191;
  background-color: #ececf3;
  padding: 2px 10px 2px 10px;
  margin: 0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  column-gap: 5px;
  font-weight: 600;
}

.labelWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.label {
  color: #848484;
  font-weight: 500;
}

.infoIcon {
  margin: 0 0px 0px 5px;
  width: 18px;
  height: 18px;
  display: inline-block !important;
  color: #3f71e0;
}

.countContainer {
  position: absolute;
  right: 5px;
  top: 5px;
  width: fit-content;
}

.openCount {
  position: relative;
  display: flex;
  font-size: 12px;
  align-items: center;
  border-radius: 7px;
  background: #3f71e0;
  color: #fff;
  max-width: 55px;
  padding: 3px 7px 3px 5px;
  white-space: nowrap;
  z-index: 1;
}

.pinIcon {
  width: 14px;
  height: 14px;
  fill: #fff;
  margin-right: 4px;
}

.pinIcon circle {
  color: #3f71e0;
  fill: #3f71e0;
  border-color: #3f71e0;
}

.groupHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cardsContainer :global(.ant-divider-horizontal) {
  margin: 0.5rem 0 1rem 0;
  border-width: 2px;
  background-color: var(--colorBgAccent3);
}

.groupHeaderTitle {
  margin: 1rem 0 0 0;
  color: var(--colorTextBase);
  font-size: 20px;
}

.menu {
  width: 190px;
}

.menuInner {
  display: flex;
  align-items: center;
}

.editIcon,
.shareIcon,
.trashIcon,
.archiveIcon,
.menuIcon,
.groupIcon {
  width: 18px;
  height: 18px;
  stroke: #848484;
  margin-right: 10px;
}

.groupIcon {
  transform: translate(0px, 5px);
}

.ungroupIcon {
  stroke: #848484;
  margin-right: 4px;
  margin-left: -2px;
}

.moreIcon {
  color: var(--colorPrimary);
}

.moreIcon:hover,
.active {
  color: #3f71e0;
}

.fileNameInput {
  margin-top: 11px;
  margin-bottom: 7px;
}

.card .cardContent .fileNameInput input {
  padding: 2px;
}

.confirm,
.trashIcon {
  color: #d0021b;
  stroke: #d0021b;
}

.overlay {
  width: 142px;
}

.assetCount {
  color: #19222a;
}

.filterContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.sortButton {
  display: flex;
  align-items: center;
  color: #3f71e0;
}

.sortButton:hover {
  color: #1a49b0 !important;
}

.chevronIcon {
  margin-left: 5px;
}

.searchIcon {
  width: 16px;
  height: 16px;
  stroke: #c7c7c7;
  margin-bottom: 2px;
  margin-right: 4px;
}

.assetsSearch,
.urlInput {
  border-radius: 6px;
  padding-left: 5px;
  height: 32px;
  max-width: 250px;
  display: flex;
  flex-direction: row-reverse;
  padding: 0 0 0 4px;
}

@media screen and (max-width: 640px) {
  .assetsSearch,
  .urlInput {
    max-width: 100%;
    min-width: 250px;
  }
}

.assetsSearch :global(.ant-input-affix-wrapper) {
  padding: 0;
}

.assetsSearch :global(.ant-input-prefix) {
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  padding-left: 4px;
}

.assetsSearch :global(.ant-input-prefix > svg) {
  stroke: #3f71e0;
}

.filterOverlay ul {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  width: 182px;
}

.filterOverlay li {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: #666666;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
}

.filterOverlay li:hover {
  background: #f5f5f5;
}

.sortIcon {
  color: #3f71e0;
  margin-left: 10px;
  width: 18px;
  height: 18px;
}

.activeMenuItem .menuInner {
  color: #3f71e0;
  justify-content: space-between;
}

.sortMenuItem .menuInner div {
  display: flex;
  align-items: center;
}

.activeMenuItem .menuInner .menuIcon {
  stroke: #3f71e0;
}

.newTaskBubble {
  width: 14px;
  height: 14px;
  position: absolute;
  top: -5px;
  left: -7px;
  background-color: #d46060;
  color: white;
  font-weight: 600;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: 50%;
  opacity: 0;
  transition: opacity ease-in-out 700ms;
  z-index: 100;
}

.showTaskAdded {
  opacity: 1;
}

.errorMessage {
  margin-top: 1rem;
}

.gifWrapper {
  margin-top: 32px;
  padding-top: 20px;
  position: relative;
}

.gifWrapper img {
  border-radius: 5px;
}

.gifText {
  text-align: center;
  padding-top: 10px;
}

.figmaIcon {
  height: 20px;
}

.figmaIcon svg {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  margin-left: -3px;
}

.figmaButtonWrapper {
  margin-top: 1.2rem;
}

.cancelButton {
  color: #3f71e0;
}

.introContainer {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-block: auto;
  height: calc(100% - 60px);
  column-gap: 2rem;
  margin-block: auto;
}

.dropdownWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  margin-right: auto;
}

.dropdownWrapper span[data-elevio-style='nothing'] svg,
.contentWrapper span[data-elevio-style='nothing'] svg {
  color: #3f71e0 !important;
  width: 16px;
  height: 16px;
}

.introContent p {
  font-size: 16px;
  font-weight: 600;
  padding-block: 20px;
  margin: 0px;
}

.figmaButton {
  color: #ffffff;
  background-color: #19222a;
  margin-right: 1rem;
  display: inline-flex;
  align-items: center;
  padding-left: 8px;
}

.figmaButton .figmaIcon {
  width: 28px;
  height: 28px;
}

.figmaButton:hover {
  background-color: #19222a;
  color: #ffffff;
}

.figmaButton:hover svg {
  fill: #2a3847;
}

.figmaButton:focus {
  background-color: #19222a;
  color: #ffffff;
}

.loadingWrapper {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 2rem;
  border-radius: 10px;
}

.modalVisible {
  visibility: visible;
  opacity: 1;
  background-color: white;
  z-index: 2;
}

.figmaUploadModal {
  color: #19222a;
}

@media screen and (min-width: 900px) {
  .figmaUploadModal :global(.ant-modal-content) {
    min-width: 595px;
  }
}

.uploadLinkWrapper {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.uploadLinkWrapper :global(.ant-btn) {
  padding: 0;
  font-weight: 500;
}

.imagesWrapper {
  max-height: 380px;
  overflow-x: hidden;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 12px;
  padding: 20px 18px 12px 12px;
  box-shadow: 0 0 6px -2px rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  border-radius: 8px;
  border: 1px solid #f5eeed;
}

.figmaImageWrapper {
  position: relative;
  width: 120px;
  height: 120px;
}

.figmaImage,
.figmaImageLoader {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 0 6px -2px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.figmaImageWrapper :global(.ant-image-mask) {
  border-radius: 8px !important;
}

.figmaImageLoader {
  background-color: #c7c7c7;
}

.imageLoaderIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.figmaImageIsLoaded {
  display: none;
}

.uploadCheckbox {
  position: absolute;
  top: 8px;
  right: 8px;
}

.bottomModalWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 12px;
  margin-top: 1.5rem;
}

.bottomModalWrapper p {
  margin: 0;
}

.syncButton {
  display: inline-flex;
  align-items: center;
  column-gap: 10px;
}

.loadingSpinnerSync {
  display: none;
}

.loadingSpinnerShow {
  display: inline-block;
}

.progress {
  max-width: 80%;
}

.previewMask.hidePreviewHint .previewHint,
.previewMask .preview {
  display: none;
}

.previewMask.hidePreviewHint .preview,
.previewMask .previewHint {
  display: flex;
}

.previewHint {
  flex-direction: column;
  align-items: center;
}

.previewHint code {
  font-size: 9px;
}

.preview {
  align-items: center;
}

.previewIcon {
  width: 14px;
  height: 14px;
}

.mask {
  background: rgba(0, 0, 0, 0.3);
}

.inputGroup {
  max-width: 95%;
  display: flex;
  margin-bottom: 25px;
}

.shareModalHeader {
  margin: 0 0 25px 0;
}

.shareModal p {
  font-weight: 300;
  max-width: 90%;
}

.shareModal :global(.ant-modal-close):hover {
  background: transparent;
}

.inviteLink {
  padding-left: 0;
}

.copyButton {
  border-radius: 0 6px 6px 0;
}

.groupMenu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 65px;
}

.assetContainer {
  position: absolute;
  width: 100%;
}

@media screen and (max-width: 640px) {
  .assetContainer {
    top: 5rem;
    left: 0;
  }
}

.assetsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 10px 10px 10px 0;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  gap: 12px;
}

.filterIcon {
  height: 20px;
  width: 20px;
  color: #3f71e0;
  cursor: pointer;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
  width: 100%;

  & .figmaButton:hover {
    background-color: #19222a;
  }

  & .figmaButton:hover > svg {
    fill: white;
  }
}

.deliverablesWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding: 10px 10px 10px 0;
  margin-top: 3rem;
  z-index: 3;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;

  @media (width < 990px) {
    margin-top: 0;
  }

  & h2 {
    margin-bottom: 6px;
    font-size: 30px;
    max-width: 100%;
    overflow: hidden;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media (width < 641px) {
      display: none;
    }

    @media (width < 990px) {
      margin-top: 28px;
    }
  }
}

.uploadDeliverable {
  padding: 20px 15px;
  border-radius: 12px;
  position: relative;

  &:hover .circlePlus {
    transform: scale(1.08);
  }

  :global(.ant-upload-wrapper) {
    color: #fff;
  }
}

.circlePlus {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #fff;
  color: var(--colorPrimary);
  transition: all 0.15s ease-in;
}

.figmaButton {
  background-color: var(--colorIcon);

  & .circlePlus {
    color: var(--colorIcon);
  }
}

.introContainerEmpty {
  background-color: var(--colorBgAccent);
  background-image: url('./assets/empty_deliverables_bg.png');
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  width: 100%;
  padding: 3rem;
  color: #2e5d8c;
  border-radius: 15px;
  min-height: calc(100vh - 250px);
  margin-bottom: 20px;

  & h2 {
    max-width: 320px;
    font-size: 30px;
    opacity: 0;
    animation: fadeIn 0.6s ease-in-out forwards;
    animation-delay: 0.2s;
  }
}

.introEmoji {
  font-size: 80px;
  margin-top: 1rem;
  display: inline-block;
  animation: fadeIn 0.35s ease-in-out forwards;
  opacity: 0;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.addwebsitemodal .addWebsiteForm {
  display: flex;
  flex-direction: column;
}

.addWebsiteForm :global(.ant-form-item-explain) {
  margin: 0px;
}

.addWebsiteForm :global(.ant-form-item-explain-error) {
  margin-top: 5px;
  color: var(--colorErrorActive);
}

.addWebsiteForm :global(.ant-form-item-explain-warning) {
  margin-top: 5px;
  color: var(--colorWarningText);
}

.addWebsiteModal .setupWrapper {
  border-radius: 10px;
  background-color: var(--colorBgAccent);
  padding: 1rem;
}

.addWebsiteModal .setupWrapper h4 {
  margin: 0;
}

.addWebsiteModal .saveButton {
  margin-top: 1.2rem;
  margin-left: auto;
}

.addWebsiteModal .linkWrapper {
  display: inline-flex;
  cursor: pointer;
}

.addWebsiteModal .linkWrapper svg {
  width: 18px;
  height: 18px;
  color: #3f71e0;
}

.addWebsiteModal .collapse {
  background-color: #fff;
  margin-block: 0.8rem;
}

.addWebsiteModal .collapse :global(.ant-collapse-item) {
  padding: 8px;
}

.addWebsiteModal .collapse :global(.ant-collapse-header) {
  font-weight: bold;
}

.addWebsiteModal .collapse .blueWrapper {
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  gap: 12px;
  background-color: #e6f4ff;
  border: 1px solid #91caff !important;
  border-radius: 8px;
}

.addWebsiteModal .collapseWrapper p {
  margin: 0;
  display: inline;
}

.addWebsiteModal .infoWrapper svg {
  width: 18px;
  height: 18px;
  color: var(--colorTextLightSolid);
  fill: var(--colorPrimary);
}

.addWebsiteModal .blueWrapper button {
  margin-right: auto;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-top: 2px;
  background-color: var(--colorPrimary);
  color: var(--colorTextLightSolid);
  max-width: fit-content;
  border: none;
}

.addWebsiteModal .verify {
  margin-right: auto;
}

.addWebsiteModal .collapseWrapper {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.addWebsiteModal :global(.ant-collapse-content-active) {
  border: none;
}

.addWebsiteModal a {
  font-weight: bold;
}

/* //web browser logo */
.addWebsiteModal .blueWrapper button::after {
  width: 30px;
  height: 30px;
  content: '';
  position: absolute;
  background-size: contain;
  left: 8px;
  top: calc(50% - 15px);
}

.addWebsiteModal .head {
  font-weight: bold;
  padding: 2px 4px;
  margin-inline: 4px;
  color: #d14;
  background-color: #f7f7f9;
  border: 1px solid #e1e1e8;
  white-space: nowrap;
}

.newOnboarding {
  border-radius: 15px;
  margin-bottom: 2rem;
  height: 100%;
  overflow: unset;

  & .cardsContainer {
    background-color: var(--colorBgAccent);
    min-height: 420px;
  }
}

.headerWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  background-color: var(--colorBgAccent);
  border-radius: 15px 15px 0 0;
  overflow: auto;

  & .archiveSearchWrapper {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  & .archivedFilter {
    display: flex;
    width: -webkit-fill-available;

    & label {
      font-weight: bold;
    }

    & :global(.ant-radio-button-wrapper) {
      background-color: transparent;
      color: var(--colorPrimary);
      border-color: var(--colorPrimary);
    }

    & :global(.ant-radio-button-wrapper-checked) {
      background-color: var(--colorPrimary);
      color: var(--colorTextLightSolid);
      min-width: 105px;
    }
  }

  & .assetsSearch {
    @media (width > 990px) {
      min-width: 270px;
    }
  }

  @media (width < 640px) {
    flex-direction: column;
    align-items: flex-start;

    & .archiveSearchWrapper {
      flex-wrap: wrap;
    }
  }
}

.websiteWrapper {
  border-radius: 15px;
  background-color: var(--colorBgAccent);
  margin-bottom: 2rem;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.addWebsiteWrapper {
  border-radius: 12px;
  background-color: var(--colorBgAccent);
  padding: 10px;
  height: auto;
  flex: 1;
  max-width: 550px;

  @media (max-width: 640px) {
    width: 100%;
  }

  @media (max-width: 990px) {
    flex: none;
  }

  :global(.ant-input-wrapper.ant-input-group) {
    border-width: 2px;
    border-radius: 10px;
    padding: 1px;
    transition: all 0.3s ease-in;
  }

  :global(.ant-input-wrapper.ant-input-group):hover,
  :global(.ant-input-wrapper.ant-input-group):focus,
  :global(.ant-input-wrapper.ant-input-group):active {
    border: 2px solid var(--colorPrimary);
  }

  & form {
    display: inline-flex;
    width: 100%;
    height: auto;
    flex-direction: row;
    column-gap: 10px;
    flex-wrap: wrap;
    row-gap: 12px;

    & :global(.ant-form-item) {
      margin-bottom: 0;
      flex: 1;

      @media (max-width: 640px) {
        width: 100%;
      }

      @media (max-width: 990px) {
        flex: none;
      }
    }

    &:global(.ant-input-group-addon) {
      background-color: var(--colorTextLightSolid);
    }
  }
}

.orDivider {
  color: var(--colorGrey3);
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  padding-inline: 4px;
  display: flex;
  align-items: center;
  text-align: center;

  @media (max-width: 640px) {
    width: 100%;
    &::before,
    &::after {
      content: '';
      flex: 1;
      border-bottom: 1px solid var(--colorText);
    }

    &::before {
      margin-right: 0.5em; /* Adjust spacing as needed */
    }

    &::after {
      margin-left: 0.5em; /* Adjust spacing as needed */
    }
  }
}

.titleWrapper {
  display: flex;
  align-items: center;
  gap: 10px;

  & h2 {
    margin: 0;
    color: var(--colorTextBase);
  }
}

.websiteCards {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  height: 100%;
  margin-top: 2rem;

  & .typeInfo {
    padding-block: 3px;
    text-transform: uppercase;
  }

  & .footerIcon {
    width: 20px;
    height: 20px;
  }
}

.websiteCard {
  max-width: unset;
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  row-gap: 2rem;
  padding: 15px;
  transition: all 0.3s ease-in;
  border-radius: 8px;
  background-color: var(--colorTextLightSolid);
  cursor: pointer;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.13);

  &:hover {
    box-shadow: 0 10px 10px 0 rgba(0, 57, 128, 0.2);
  }

  & h3 {
    color: var(--colorTextBase);
    margin: 0 0 6px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: fit-content;
  }

  & .cardContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 0 0 20px;
    flex-grow: 1;
    min-width: 0;
  }

  & .lastUpdated {
    font-weight: 700;
    margin: 0;
    font-size: 12px;
  }

  & img {
    width: 140px;
    height: 82px;
    border-radius: 6px;
    border-top: 6px solid var(--colorTextBase);
    border-left: 6px solid var(--colorTextBase);
    border-right: 6px solid var(--colorTextBase);
  }

  @media (width < 640px) {
    & .cardContent {
      flex-direction: column;
      row-gap: 1rem;
      align-items: flex-start;
      padding: 0;
    }

    & .loadingWebsiteImage {
      display: none;
    }
  }
}

.leftCardContent {
  min-width: 0;
  width: 100%;
}

.taskCountUpdated {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.loadingWebsiteImage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 82px;
  flex: 0 0 auto;
}

.imageWrapper {
  position: relative;
  overflow: hidden;
  height: 165px;
}

.imageWrapper:hover .slideInButtonWrapper {
  transform: translateY(0);
}

.imgWrapper:hover .slideInButtonWrapper {
  transform: translateY(0);
}

.pdfDocument:hover .slideInButtonWrapper {
  transform: translateY(0);
}

.installExtension {
  background-color: var(--colorInfoBg);
  border: 1px solid var(--colorInfoBorder);
  color: var(--colorText);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  flex-wrap: wrap;
  margin-inline: 1rem;
  margin-bottom: 2px;
  position: relative;

  @media (width < 990px) {
    flex-direction: column;
    padding: 12px;
    text-align: center;
    gap: 12px;
  }

  & .textWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    flex-wrap: wrap;
    padding-left: 6px;

    @media (width < 990px) {
      flex-direction: column;
      padding: 12px;
    }

    & p,
    & b {
      margin: 0;
    }

    & a {
      color: var(--colorPrimary);
      border: none;
    }
  }

  & b {
    cursor: pointer;

    & :hover {
      text-decoration: underline;
    }
  }

  & button {
    background-color: var(--colorPrimary);
    color: var(--colorTextLightSolid);
    font-weight: bold;
    margin: 2px 12px;
    padding: 8px 20px 8px 40px;
  }

  & button::after {
    width: 25px;
    height: 25px;
    content: '';
    position: absolute;
    background-size: contain;
    left: 10px;
    top: calc(50% - 13px);
  }
}

@media (max-width: 640px) {
  .installExtension {
    display: none;
  }
}

.renameWebsiteModal {
  & h2 {
    margin-bottom: 0;
  }

  & .changeButton {
    margin-top: 2rem;
  }

  & :global(.ant-modal-close) {
    background-color: var(--colorBgBase);
    padding: 4px;
    border-radius: 50%;
    height: 26px;
    width: 26px;
  }

  & :global(.ant-modal-close-icon) {
    color: var(--colorSeverityCritical);
  }
}

.fileNameInput {
  & :global(.ant-input-group) {
    border: 1.5px solid #3f71e0;
    border-radius: 5px;
    padding: 4px 0;
  }

  & :global(.ant-input) {
    font-weight: 400;
    border: none;
  }

  & :global(.ant-input:focus) {
    box-shadow: none;
  }

  & :global(.ant-select-selector) {
    background-color: #f1f1f1 !important;
  }
}

.coverImageButton {
  display: flex;
  align-items: center;
  color: var(--colorTextLightSolid);
  border: 0;
  border-radius: 5px;
  background: var(--colorGrey5);
  font-size: 0.75rem;
  transition: all 0.2s ease;
  padding: 4px 6px;
  column-gap: 6px;

  &:hover {
    background: var(--colorGrey4);
    transform: scaleX(1.03);
  }

  & .retryIcon {
    width: 14px;
    height: 14px;
  }
}

.slideInButtonWrapper,
.cardSlideInContainer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  width: 100%;
  top: 0;
  left: 0;
  transform: translateY(200px);
  transition: all 0.25s ease-in-out;
  z-index: 1;
}

.buttonWrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: fit-content;
  z-index: 2;
}

.pulseIndicator {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  animation: pulse 2s infinite;
  position: absolute;
  top: -4px;
  right: -4px;
  z-index: 1;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
    scale: 0.75;
  }
  70% {
    box-shadow: 0 0 0 5px rgba(255, 0, 0, 0);
    scale: 1;
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    scale: 0.75;
  }
}

.websiteMenu {
  cursor: pointer;

  & button:hover svg {
    color: white;
  }
}

.buttonIcon {
  width: 18px;
  height: 18px;
}
.extensionLogos {
  width: 36px;
  height: 36px;
}

.extensionPicker {
  display: flex;
  margin-top: 1.8rem;
  justify-content: space-around;
  gap: 6px;
  flex-direction: column;
  align-items: center;

  @media (width > 600px) {
    flex-direction: row;
  }

  & .browser-install {
    opacity: 0.5;
  }

  & li {
    list-style: none;
    order: 2;
    display: block;
    opacity: 0.5;
    transition: all 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);

    & a {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
    }

    &:hover {
      opacity: 1;
      transform: scale(1.03);
    }
  }

  & .browserInstall {
    opacity: 1;
    order: 1;
    transform: scale(1.03);
  }
}

.extensionWrapper {
  margin: 3rem 2rem 2rem 2rem;
  text-align: center;

  & h2,
  & p {
    margin-bottom: 0;
    line-height: 1.3;
  }
}

.installModal {
  width: 620px !important;
}

.installModalTabs {
  display: flex;
  max-height: 80vh;
  :global(.ant-tabs-content-holder) {
    display: flex;
    background: #c2ddec;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  :global(.ant-tabs-content) {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.extensionContentWrapper {
  display: flex;
  align-items: center;
}

.extensionImageWrapper {
  transform: translateX(10px);
  animation: fadeInLeft 0.6s forwards;
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);

  @media (max-width: 551px) {
    display: none;
  }
}

@keyframes fadeInLeft {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.assetsFilterLabel {
  white-space: nowrap;
}

.openButton {
  margin-bottom: 10px;
}

.cardShareButton,
.cardShareButton:focus {
  color: #ffffff;
}

/*Override as it exists on a darker background*/
.cardShareButton:hover {
  color: var(--colorPrimaryHover) !important;
  background-color: #ffffff !important;
}

/*Override as it exists on a darker background*/
.cardShareButton:active {
  color: var(--colorPrimaryHover) !important;
  background-color: #ffffff !important;
}

.guestExtensionContentWrapper {
  text-align: center;
  max-width: 400px;
  margin-inline: auto;
  margin-block: 3rem 1rem;

  & svg {
    border-radius: 35px;
  }

  & .guestInstallButton {
    margin-block: 1rem;
    border-radius: 8px;
    font-size: 1rem;
    padding: 22px 32px;

    @media (width < 992px) {
      width: 396px;
    }
  }
}

.guestProjectHomeWrapper {
  margin-top: 0px;
}

.guestIntroWrapper {
  display: flex;
  gap: 2rem;
  flex-direction: column;
  padding-bottom: 3rem;

  @media (width > 992px) {
    flex-direction: row;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.mainScreen {
  --base-delay: 0.1s;
  --delay-increment: 0.05s;
}

.staggerInAnimation {
  opacity: 0;
  animation: fadeInUp 0.5s ease forwards;
  animation-delay: calc(
    var(--base-delay) + var(--delay-increment) * var(--index, 0)
  );
}

.installRequiredButton:not(.guestButton),
.installRequiredButton:not(.loadingButton) {
  background-color: var(--colorWarning);
  color: var(--colorTextLightSolid);
  border: none;

  &:active,
  &:hover,
  &:focus {
    background-color: var(--colorWarning);
  }
}

.useBugherdModal {
  width: 570px !important;

  & h2 {
    margin: 0 0 1.5rem 0;
  }

  & .radioWrapper {
    margin: 1rem 0;
    display: flex;
    gap: 1rem;
    font-weight: 700;
    width: 100%;
    
    @media (max-width: 551px) {
      flex-direction: column;
    }
  }

  & .radioGroup {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 1rem;
  }

  & .radioValue {
    border-radius: 12px;
    border: 1px solid var(--colorText);
    padding: 1rem;
    display: inline-flex;
    flex-direction: row-reverse;
    width: 100%;

    & img {
      width: min-content;
    }
  }

  & :global(.ant-radio-wrapper-checked) {
    border: 3px solid var(--colorPrimary);
  }

  & :global(.ant-radio) {
    margin-bottom: auto;
  }

  & :global(.ant-radio-wrapper span.ant-radio + *) {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    row-gap: 6px;
  }
}

.blueWrapper {
  display: flex;
  background: #c2ddec;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 100%;

  & .embedCode {
    gap: 1rem;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    margin-left: auto;
    width: 100%;
  }
}

.logoSimple {
  width: 32px;
  height: 32px;
}

.logoSimpleWrapper {
  display: flex;
  gap: 8px;
}

.blueExtensionWrapper {
  border-radius: 12px;
  background-color: #c2ddec;
  display: flex;
  font-weight: 700;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}

.blueTextWrapper {
  border-radius: 50px;
  background: var(--colorInfoBg);
  padding: 4px 8px;
  color: var(--colorPrimary);
  font-size: 12px;
  font-weight: 700;
  width: min-content;
  display: flex;
  width: min-content;
  align-items: center;
  height: 22px;
  line-height: 1;
}

.supportWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  gap: 6px;

  & img {
    margin-right: 0.5rem;
  }
}

.contactLink {
  font-weight: bold;
  color: var(--colorPrimary);
  cursor: pointer;
}

.getTriggerElement {
  display: contents;
}

.cardHoverInfoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
