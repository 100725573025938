.upgradeOuter {
    display: flex;
    background: #ffffff;
    margin-bottom: 16px;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
}

.icon {
    min-width: 22px;
    margin: 5px 10px 10px 0;
}

.message {
    margin: 0;
}

.upgradeOuter .message .upgrade, .upgradeOuter .message .install, .popoverMessage .install {
    padding: 1px;
    height: 19px;
    color: #af30d7;
    line-height: 19px;
}

.noBillingMessage {
    font-size: 13px;
}

.noBillingMessage a {
    font-size: 13px;
}

.noBillingPopoverMessage {
    font-size: 13px;
}

.screenshotsUpgradePopover .noBillingPopoverMessage a {
    font-size: 12.5px;
    padding: 0px;
}

.isCreateTask {
    background-color: #f5eeed;
    margin: 10px 0 0 0;
}
.isCreateTask:hover {
    box-shadow: 0 0px 5px 3px rgb(0 57 128 / 8%);
}
