.security {
  width: auto;
}

.switchWrapper {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-bottom: 1.5rem;
}

.switchWrapper :global(.ant-form-item-row) {
  display: flex;
  flex-direction: row-reverse !important;
  flex-flow: nowrap;
  justify-content: flex-end;
  align-items: flex-end;
}

.switchWrapper :global(.ant-form-item-control) {
  width: 42%;
}

.switchWrapper :global(.ant-form-item-control-wrapper) {
  margin-right: 1em;
}

.switchWrapper :global(.ant-form-item-label label) {
  font-weight: inherit !important;
}

.ghost {
  border-color: transparent;
  background-color: transparent;
  margin-left: 1em;
  box-shadow: none;
}

.actions {
  display: flex;
  justify-content: left;
  margin-top: 1.5em;
}

.container :global(.ant-form-item-required::before) {
  display: none;
}

.switchDisabled,
.switchEnabled {
  transform: translate(1px, 4px);
  width: 100px;
}

.switchDisabled {
  color: #c6c6c6;
  cursor: default;
}

.switchEnabled {
  color: #20c0e7;
  font-weight: 500;
  cursor: default;
}

.button {
  padding: 0 30px;
}

.upgradeAnchor {
  color: #20c0e7;
  text-decoration: none;
}

.learnMoreLink > span {
  display: block;
  font-weight: 900;
}

.switchWrapperDisableEnable {
  display: flex;
  align-items: center;
  gap: 20px;
}

.learnMoreWrapper {
  display: block;
  font-weight: 900;
}

.saveCancel {
  margin-top: 2rem;
}

@media screen and (max-width: 992px) {
  .switchWrapper :global(.ant-form-item-row) {
    flex-flow: wrap-reverse;
  }
}

@media screen and (min-width: 992px) {
  .switch {
    width: 50%;
  }
}
