.progressBar {
  max-width: 58%;
}

.searchBar {
  max-width: 45%;
}

.searchBar,
.searchBar input {
  font-weight: 400;
}

.searchBar input:nth-child(2)::placeholder {
  font-weight: 300;
}

.memberCount {
  font-weight: 500;
}

.membersFeedback {
  margin: 0;
  padding-bottom: 6px;
}

.teamParent {
  max-width: 620px;
}

.filterBox {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.searchIcon,
.closeIcon {
  color: rgb(191, 191, 191);
  width: 14px;
}

.filterContainer {
  display: flex;
  align-items: center;
}

.filterLabel {
  color: rgb(55, 55, 55);
  padding-right: 10px;
  cursor: default;
}

.ssoContainer {
  display: flex;
  background-color: #ECECF2;
  border-radius: 10px;
  padding: 10px;
}

.ssoContainer p {
  margin-bottom: 0;
}

.upgradeArrow {
  margin: 0 5px;
  width: 20px;
  position: relative;
}

.ssoText {
  font-weight: bold;
  color: #9232BC;
}

.upgradePopoverContainer {
  float: right;
  top: 20px;
  position: relative;
}
