.container {
  padding: 40px 100px 60px;
  position: relative;
  background-color: var(--colorBgAccent);
  background-image: url('./assets/getting-started.png');
  background-position: calc(100% - 40px) 90px;
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: fixed;

  @media (max-width: 990px) {
    padding: 30px 50px;
  }
}

:global(#tasks:has(*[data-hideLegacyHeader='true'])) {
  top: 8px;
  @media (max-width: 640px) {
    top: 100px;
  }
}

.headerAndContentWrapper {
  position: relative;
  color: var(--colorText);
}

.contentWrapper {
  display: flex;
  gap: 56px;
  position: relative;
  align-items: flex-start;
  flex-wrap: wrap;

  @media (max-width: 990px) {
    flex-direction: column;
  }
}

.stepsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 660px;
}

.stepContainer {
  background-color: var(--cardColor);
  padding: 24px;
  border-radius: 12px;
  transition: all 0.5s ease;
}

.stepContainer:hover {
  box-shadow: 0 10px 10px 0 rgba(0, 57, 128, 0.2);
}

.header {
  margin-top: 0;
  color: var(--colorText);
}

.stepHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.checkedStep {
  outline: 2px solid var(--colorSuccessBorder) !important;
  cursor: pointer;
}

.checkedStep {
  & .descriptionWrapper,
  & .descriptionWrapper *,
  & .actionButtons *,
  & .actionButtons {
    opacity: 0;
    max-height: 0;
    transition: opacity 0.25s ease-in-out, max-height 0.25s ease-in-out,
      margin 0.25s ease-in-out;
  }
  & .descriptionWrapper {
    margin: 0px;
  }
}

.checkedStep.showCompletedstep {
  & .descriptionWrapper,
  & .descriptionWrapper *,
  & .actionButtons *,
  & .actionButtons {
    opacity: 1;
    max-height: 500px;
    transition: opacity 0.25s ease-in-out, max-height 0.25s ease-in-out,
      margin 0.25s ease-in-out;
  }
  & .descriptionWrapper {
    margin: revert;
  }
}

.checkedBadge {
  color: var(--colorSuccessText);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  background-color: var(--colorSuccessBorder);
  border: 1px solid var(--colorSuccessBorder);
  border-radius: 9999px;
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 5px 8px;
  gap: 8px;
}

.buttonWithIcon {
  display: flex;
  align-items: center;
  gap: 8px;
}

.actionButtons {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 8px;
  }
}

.supportContainer {
  background-color: var(--cardColor);
  padding: 24px;
  border-radius: 12px;
  max-width: 336px;
}

.supportButtons {
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 12px;
}

.subscriptionPlanContainer {
  background: #ececf3;
  padding: 20px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  cursor: pointer;
  transition: all 0.5s ease;
}

.subscriptionPlanContainer:hover {
  box-shadow: 0 10px 10px 0 rgba(0, 57, 128, 0.2);
}

.installButton {
  background-color: var(--colorPrimary);
  padding: 4px 15px;
  margin: 0px;
  font-size: 14px;
  height: 32px;
  border-radius: 20px;
  font-weight: 800;
  line-height: 22px;
}

.installButton:hover {
  color: var(--colorTextLightSolid);
  background-color: var(--colorPrimaryHover);
}

.installButton::after {
  background-image: none;
}

.button {
  text-decoration: none;
}

.upgradeBadge {
  background-color: rgba(255, 255, 255, 1);
  color: var(--colorGrey3);
  display: flex;
  gap: 6px;
  width: fit-content;
  padding: 2px 6px 2px 3px;
  border-radius: 12px;
  align-items: center;
  text-transform: uppercase;
  font-size: 11px;
  font-style: normal;
  font-weight: 800;
}

.title {
  margin-top: 0;
  margin-bottom: 0;
}

.modal {
  min-width: 80vw;
  & .buttonVideoWrapper {
    display: flex;
    justify-content: center;
    padding-top: 32px;
  }
  & .buttonVideoWrapper,
  & iframe {
    aspect-ratio: 16 / 9;
    min-width: 70vw;
    border-radius: 12px;
  }
}

.thumbnailContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 12px;
  cursor: pointer;
}

.thumbnailContainer:hover .playCircle {
  transform: scale(1.1);
}

.thumbnailContainer:hover .previewThumbnail {
  filter: brightness(0.8);
}

.quickIntroduction {
  @media (width > 992px) {
    display: flex;
  }

  & .thumbnailContainer {
    max-width: 150px;
    margin: 1rem 1rem 0 0;
  }
}

.previewThumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.playCircle {
  position: absolute;
  width: 55px;
  height: 55px !important;
  border-radius: 50%;
  padding: 4px;
  background-color: #ffffff5e;
  transition: transform 0.25s ease-in-out !important;
}

.innerCircle {
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100% !important;
}

.playIcon {
  width: 40%;
  height: 40%;
  fill: black;
}
