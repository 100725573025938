.integrationsOuter {
  padding: 10px 16px;
}

.emptyStateImage {
  background-image: url('./integrations-empty-state.png');
  background-size: cover;
  background-repeat: no-repeat;
  width: 220px;
  height: 180px;
  margin: 0 auto 10px auto;
}

.emptyState {
  margin: 80px auto 0 auto;
  text-align: center;
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.firstIntegration {
  font-weight: 600;
  color: #19222a;
  margin-bottom: 15px;
}

.addIntegration {
  max-width: fit-content;
  margin: 0 auto;
}
