.onbModal {
  max-width: 940px;
  width: 100% !important;
}

.onbModal :global(.ant-modal-body) {
  padding: 0;
}

.onbModal :global(.ant-modal-content) {
  padding: 0;
}

.container {
  display: flex;
  flex-direction: row;
}

.leftPanel {
  flex-grow: 1;
  width: 70%;
  height: 100%;
}

.rightPanel {
  width: 30%;
  min-width: 380px;
  background-color: #e0f0f9;
  display: flex;
  flex-grow: 1;
  position: relative;
  border-radius: 4px;
}

.step {
  height: 100%;
  padding: 24px;
}

.stepVisibleStepTitle {
  color: #373737;
  font-size: 18px;
  font-weight: 900;
  padding-top: 7px;
}

.stepTitle {
  color: #373737;
  font-size: 13px;
  font-weight: 900;
  padding-top: 5px;
}

.stepTitleDone {
  color: #b4b4b4;
  font-weight: 900;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: -3px;
  margin-left: -16px;
  margin-top: 2px;
}

.activeTitle {
  max-width: 400px;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.stepTitleDone:hover {
  color: #b4b4b4;
  background-color: rgba(0, 0, 0, 0.05);
}

.stepTitleDone span {
  margin-right: 3px;
}

.setupProjectFormContainer {
  margin-top: 24px;
}

.projectName {
  border: 1px solid #20c0e7;
  border-radius: 5px;
}

.createProjMessage {
  font-style: italic;
}

.installBhContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  justify-content: space-between;
}

.installJsLabel {
  color: #373737;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
}

.stepContainer {
  height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.installBhBtn {
  margin-top: 50px;
}

.installJsText a {
  color: #3f71e0;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0;
}

.installJsText {
  margin-top: 30px;
  color: #373737;
  font-size: 16px;
}

.marginTop {
  margin-top: 16px;
}

.jsSuccess {
  color: #8bb863;
  line-height: 24px;
  font-weight: 900;
  margin-bottom: 0;
}

.jsSuccessMessage {
  color: #373737;
  font-size: 16px;
}

.jsError p {
  color: #f5222d;
}

.jsError {
  color: #f5222d;
}

.titleStep {
  color: '#373737';
  font-size: 18;
  font-weight: 900;
  line-height: 24;
}

.buttonWrapperStyle {
  display: 'flex';
  justify-items: 'center';
  line-height: '24px';
}

.setupProjectFormContainer {
  width: 80%;
  max-width: 940px;
}

.projectName {
  border: 1px solid #20c0e7;
}

.rightPanelInner {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat, no-repeat, repeat-x;
  background-size: contain;
  background-position: center;
  background-image: url('../../../assets/images/onboarding_v3_setup_project.png');
}

.setupProjectFormContainer :global(.ant-legacy-form-item-label) {
  text-align: left;
}

.setupProjectRightPanel .guideOuter {
  padding-bottom: 95px;
}

.installExtensionRightPanel .guideOuter,
.installJavascriptRightPanel .guideOuter {
  padding-bottom: 120px;
}

.inviteRightPanel {
  background-image: url('../../../assets/images/onboarding_v3_invite.png');
}

.websiteRightPanel .guideOuter {
  padding-bottom: 120px;
}

.tryOuter {
  display: flex;
  flex-direction: column;
  padding: 15px 0 20px 0;
  justify-content: flex-start;
}

.tryInner {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.tryInner div {
  padding-bottom: 20px;
}

.tryInner a {
  color: #3f71e0;
  letter-spacing: 0;
  padding-top: 20px;
}

.visitWebsiteButton {
  width: fit-content;
}

.showExtensionOuter {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px 0 0 0;
  font-weight: 500;
  font-size: 16px;
  color: #373737;
}

.showExtensionOuter a {
  padding: 20px 0 0 0;
  color: #3f71e0;
}

.showExtensionOuter a:hover {
  color: #3f71e0;
}

.inviteMembersOrText {
  margin-left: 17px;
  font-size: 16px;
}

.addMembersButton {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 19px;
  padding-right: 19px;
  height: 40px;
  font-weight: 900;
}

.addMembersButton:disabled {
  background-color: #97b3ee;
}

.doLaterButton,
.doLaterButton:hover,
.dolaterButton:active {
  font-size: 16px;
  font-weight: 900;
  color: #3f71e0 !important;
}

.inviteMembersForm {
  margin-top: 26px;
}

@media (max-width: 950px) {
  .onbModal {
    max-width: 560px;
  }
  .leftPanel {
    width: 100%;
  }
  .rightPanel {
    width: 0;
    min-width: unset;
  }
  .rightPanelInner {
    display: none;
  }
}

.inviteButtonContainer {
  display: flex;
  align-items: center;
}

.guideHeader {
  text-transform: uppercase;
  color: #848484;
  font-weight: 600;
  margin: 0;
}

.guideOuter {
  max-width: 89%;
  margin: 0 auto;
}

.guideInfo {
  margin-bottom: 10px;
}

.guideMessage {
  color: #373737;
  font-size: 20px;
  line-height: 26px;
}

.videoLength {
  color: #848484;
}

.talkToUsOuter {
  margin-top: 50px;
  font-size: 16px;
  display: block;
  text-align: center;
}

.inviteRightPanel .talkToUsOuter {
  width: 100%;
  margin-top: 340px;
}

.surveyHeaders {
  color: rgba(25, 34, 42, 0.8);
  margin-bottom: 4px;
}

.submitButton {
  margin-top: 25px;
}

.submitButton > span {
  padding: 0px 14px;
}

.surveyButton {
  margin-top: 25px;
  color: #3f71e0;
}

.surveyButton > span {
  text-decoration: underline;
}

.closeButton {
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  position: absolute;
  top: -40px;
  right: 0;
}

.closeButton:hover {
  background-color: #f1f1f1;
}

.closeIcon {
  color: #d46060;
  width: 14px;
  height: 14px;
}

.widgetWrapper {
  margin: 0 20px;
  position: relative;
  width: 100%;
}

.typeformWidget div:first-child {
  width: 100%;
}

:global(.ant-steps .ant-steps-item-finish .ant-steps-item-icon) {
  background-color: #3f71e0;
}
:global(.ant-steps
    .ant-steps-item-finish
    .ant-steps-item-icon
    > .ant-steps-icon) {
  color: white;
}

.userForm :global(.ant-form-item-control-input) {
  max-width: 330px;
}

:global(.ant-steps .ant-steps-item-finish .ant-steps-item-icon) {
  z-index: 2;
  position: relative;
}

:global(.ant-steps.ant-steps-vertical
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-tail::after) {
  transform: translateX(50%);
}
