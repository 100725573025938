.nameWrapper {
  display: flex;
  justify-content: space-between;
}

.nameWrapper > div {
  width: 48%;
}

.form {
  width: 100%;
  max-width: 300px;
}

.actions {
  text-align: center;
  margin: 2.4em 0 0.5em 0;
}

.textCenter {
  text-align: center;
}

.spanLabel {
  font-size: 14px;
}

.ssoDivider {
  width: 100%;
  height: 20px;
  border-bottom: 1px solid black;
  text-align: center;
  margin: 20px 0 38px 0;
}

.ssoDividerText {
  background-color: #FFF;
  padding: 0 10px;
  position: relative;
  top: 8px
}

.submit {
  margin: 0 auto;
}
