.buttonAnchor {
  text-decoration: none;
}

.whyMessage {
  font-weight: 500;
}

.whyMessage * {
  text-decoration: none;
}

.whyMoreInfo * {
  text-decoration: none;
}

.whyMessageLink,
.whyMoreInfoLink {
  padding: 0 3px;
  color: #3f71e0;
}

.whyMessageLink:active,
.whyMoreInfoLink:active {
  color: #3f71e0;
  text-decoration: none !important;
}

.whyMessageLink:hover,
.whyMoreInfoLink:hover,
.whyMoreInfoLink:visited,
.whyMessageLink:visited {
  color: #3f71e0;
  text-decoration: none !important;
}

.modalContent {
  min-width: 350px;
  max-width: 370px;
}
