.unknownBrowserList {
  display: flex;
  justify-content: center;
  margin-bottom: 3.5em;
}

.unknownBrowser {
  margin: 0 2.55em;
}

.unknownBrowserLogo {
  display: block;
  width: 100px;
  height: 100px;
  margin: 0 auto 0.5em auto;
}

.emptyunknownBrowserLogo {
  display: block;
  width: 60px;
  height: 60px;
  margin: 0 auto 0.5em auto;
}

.installButtonChrome,
.installButtonEdge,
.installButtonSafari,
.installButtonFirefox {
  background: #3f71e0;
  font-size: 1rem;
  color: #fff;
  border-radius: 100px;
  padding: 0.85em 1em 0.85em 3.2em;
  position: relative;
  transition: all 0.2s ease;
  display: inline-block;
  margin: -0.7em 0 0 0;
  text-decoration: none;
  border: none;
}

.emptyStateAnimation {
  box-shadow: 0 0 0 0 rgba(32, 192, 231, 1);
  animation: pulse 3s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(32, 192, 231, 0.7);
  }

  50% {
    box-shadow: 0 0 0 20px rgba(32, 192, 231, 0);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(32, 192, 231, 0);
  }
}

.installButtonChrome:hover,
.installButtonEdge:hover,
.installButtonSafari:hover .installButtonFirefox:hover {
  color: #fff;
}

.installButtonChrome::after,
.installButtonEdge::after,
.installButtonSafari::after,
.installButtonFirefox::after {
  width: 40px;
  height: 40px;
  content: '';
  position: absolute;
  background-size: contain;
  left: 3px;
  top: calc(50% - 20px);
}

.installButtonChrome::after {
  background-image: url('../install_extension/browser_icons/chrome.png');
}

.installButtonEdge::after {
  background-image: url('../install_extension/browser_icons/edge-chromium.png');
}

.installButtonSafari::after {
  background-image: url('../install_extension/browser_icons/safari.png');
}

.installButtonFirefox::after {
  background-image: url('../install_extension/browser_icons/firefox.png');
}
