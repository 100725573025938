.inputBorder :global(.ant-input-group){
  border: 1.5px solid #20c0e7;
  border-radius: 5px;
  padding: 1px;
}

.inputBorder :global(.ant-input-group-addon){
  border: 0
}

.inputBorder :global(.ant-input-group .ant-input){
  border: 0
}