.container {
  background-color: #f6f9fc;
  display: flex;
  justify-content: center;
  padding: 1rem;
  min-height: 100vh;
  min-width: 100vw;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 6rem;

  & .blueWrapper {
    padding-left: 6px;
  }

  & img {
    margin-bottom: 0.8em;
  }

  & a {
    text-decoration: none;
    font-weight: bold;
  }
}
