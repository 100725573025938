.icon {
  color: #28a736;
  width: 58px;
  height: 58px;
}

.video {
  width: 100%;
}

.videoContainer {
  position: relative;
  max-width: 620px;
  margin: 0 auto 2.9em auto;
}
