.smallConnected {
  font-size: 12.5px;
}

.smallConnected .connectedButton span {
  max-width: 110px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12.5px;
}

.smallConnected .connectedText {
  width: max-content;
}

.connectedContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.connectedText {
  margin-right: 5px;
  color: #848484;
  font-weight: 500;
}

.greenTickIcon {
  margin-right: 5px;
  min-width: 19px;
}

.linkIcon {
  width: 18px;
  height: 18px;
  margin: 0 0 3px 5px;
}

.connectedContainer .connectedButton {
  display: flex;
  align-items: center;
  padding-left: 0;
  text-decoration: none;
  padding-bottom: 0;
}

.cardContent {
  display: flex;
  justify-content: space-between;
}

.close {
  width: 15px;
  height: 14px;
  stroke: #848484;
  margin-right: 15px;
  transform: translate(6px, 2px);
}

.trashIcon {
  stroke: #d46060;
  width: 14px;
  height: 14px;
  margin-right: 0.5em;
}
