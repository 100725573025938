.hintContainer {
  margin-top: 32px;
  margin-bottom: 32px;
}

.hintMessage {
  color: #373737;
  font-size: 14px;
  font-weight: 900;
  line-height: 20px;
}

.hintIcon {
  top: 16px !important;
  left: 16px !important;
  fill: transparent;
  width: 22px;
  height: 22px;
}

.hintIcon line {
  stroke: white;
}

.cantPlaySafari {
  margin-top: 0;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .hintContainer {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}