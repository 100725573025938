.overlayOuter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  border-radius: 10px;
  background-color: #ffffffe8;
}

.overlayOuter .moreInfoHeader {
  font-family: 'Inter', sans-serif;
}

.moreInfoHeader {
  font-size: 24px;
  font-weight: 600;
  margin: 10px 0 15px 0;
  color: #373737;
}

.loaderIcon {
  width: 30px;
  height: 30px;
}

.infoIcon {
  width: 30px;
  height: 30px;
  color: #1A49B0;
  margin-bottom: 20px;
}

.errorIcon {
  color: #d46060;
  width: 25px;
  height: 25px;
}

.loaderIcon,
.errorIcon {
  margin-bottom: 25px;
}

.messageOuter {
  max-width: 60%;
}

.moreInfoMessage {
  width: 100%;
  font-size: 16px;
  margin-bottom: 20px;
  text-align: center;
}

.progressBar {
  height: 10px;
  border-radius: 5px;
  width: 100%;
  background-color: #cccccc;
}

.progressInner {
  height: 10px;
  border-radius: 5px;
  background-color: #3f71e0;
}

.almostComplete {
  background-color: #8bb863;
}

.wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  width: 311px;
}

.macWrapper {
  width: 400px;
}

.wrapper code {
  background: #e8e8e8;
  border: 1px solid #cecece;
  border-radius: 4px;
  padding: 1px 4px;
  font-size: 13px;
}

.shortcuts {
  align-items: unset;
  justify-content: flex-start;
  padding: 15px 30px 30px 30px;
}

.closeContainer {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 50%;
  background-color: #f5eeed;
  color: #d46060;
  cursor: pointer;
}

.closeIcon {
  width: 14px;
  height: 14px;
}

.codeContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 160px;
}

.macWrapper .codeContainer {
  min-width: 230px;
}
