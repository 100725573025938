.container {
  width: 100%;
  height: 100%;
  background-color: var(--colorBgAccent2);
}

.toggleCommentsAndEventLog {
  font-size: 12px;
  padding-right: 0;
}

.label {
  font-size: 9px;
  font-weight: 600;
  color: #888;
  text-transform: uppercase;
}

.labelAndToggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.detailsMiddle {
  padding: 10px 16px 0 16px;
  display: flex;
  flex-direction: column;
}

.detailMiddle :global(.ant-select-selector),
.detailsMiddle :global(.ant-select-selection-item) {
  border-color: inherit;
  color: inherit;
}

.detailMiddle :global(.ant-tag) {
  color: #848484;
}

.eventLogAndComments {
  padding-left: 16px;
  padding-bottom: 60px;
  padding-right: 16px;
}

.fullStoryLogo {
  background-image: url('../../../../assets/images/icons/integrations/ic-integration-fullstory.png');
}

.logRocketLogo {
  background-image: url('../../../../assets/images/icons/integrations/ic-integration-logrocket.png');
}

.integrationLogo {
  display: block;
  width: 15px;
  height: 15px;
  background-size: 85%;
  background-color: var(--darkWhite);
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  margin-right: 5px;
}

.modal {
  max-width: 980px;
  width: 100% !important;
}

@media screen and (min-width: 980px) {
  .modal :global(.ant-modal-content) {
    padding: 0;
    border-radius: 15px;
    overflow: hidden;
    height: 100%;
  }

  .modal .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: var(--colorBgAccent2);
  }

  .modal .titleHeader {
    position: absolute;
    top: 0;
  }

  .modal .detailsHead {
    max-width: 640px;
    width: 100%;
    height: 100%;
    max-height: 70%;
  }

  .modal .taskDetailsWrapper {
    height: calc(100vh - 80px);
  }

  .modalContent {
    margin-top: 46px;
    display: flex;
    height: 100%;
  }

  .modal .detailsMiddle {
    max-width: 340px;
    overflow-x: auto;
    background-color: var(--colorBgAccent2);
    height: calc(100vh - 160px);
  }

  .rightPane {
    flex-grow: 1;
    background-color: var(--colorBgAccent2);
  }

  .leftPane {
    max-width: 640px;
    width: 100%;
    padding-bottom: 12px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background-color: var(--darkWhite);
  }

  .modal .eventLogAndComments {
    background-color: var(--darkWhite);
    max-width: 640px;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 0;
    max-height: 30%;
  }
}

.modal .detailsMiddle::-webkit-scrollbar {
  display: none;
}

.paneRightExpanded {
  width: 0 !important;
  overflow: visible !important;
}

.paneCenterExpanded {
  right: 0 !important;
}

.integrationsWrapper {
  max-width: 640px;
  width: 100%;
  padding-bottom: 34px;
}
