.createTaskOuter {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background-color: rgba(110, 110, 110, 0.45);
}

.createTaskInner {
  width: 580px;
  display: flex;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 10px 10px 0 rgba(0, 57, 128, 0.2);
}

.closeContainer {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 50%;
  background-color: white;
  color: #d46060;
  cursor: pointer;
}

.closeIcon {
  width: 14px;
  height: 14px;
}

@media (max-width: 600px) {
  .createTaskOuter {
    align-items: flex-start;
  }

  .createTaskInner:not(.guestView) {
    width: 100%;
    flex-direction: column;
    min-height: 482px;
    overflow: visible;
    max-height: 100%;
  }

  .guestView {
    min-height: 292px;
    width: 100%;
    flex-direction: column;
    overflow: visible;
  }

  .closeContainer {
    background-color: #f5eeed;
    width: 25px;
    height: 25px;
  }
}

.shortcutsContainer {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: -35px;
  color: white;
  cursor: pointer;
}

.infoIcon {
  margin: 0 5px 2px 0;
  width: 20px;
  height: 20px;
}
