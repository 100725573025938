.heading {
  color: #d0021b;
}

.container {
  max-width: 460px;
  margin: 0 auto;
}

.container h2 {
  line-height: 1.5em;
}

.container h1 {
  margin-bottom: 1.7em;
}

.hr {
  max-width: 240px;
  margin: 2.9em auto;
  background-color: #b8b8b8;
  border: 0;
  height: 1px;
}

.link {
  font-size: 1rem;
}

.linkWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.linkIcon {
  display: block;
  margin: 0 1em 0 0;
  color: #406cff;
}

.message {
  width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.bgImage {
  width: 900px;
  min-height: 460px;
  background-image: url('../../../assets/bg-diamonds.png'),
    url('../../../assets/max-computer.png');
  background-repeat: no-repeat, no-repeat;
  background-position: center top, right bottom;
  background-size: auto, 360px;
}

@media (max-width: 830px) {
  .bgImage {
    background-image: url('../../../assets/bg-diamonds.png');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: auto;
  }
}

@media (max-width: 980px) {
  .bgImage {
    background-size: auto, 300px;
  }
}
.message {
  margin: 0 auto 2.5em auto;
}

.firstMessage {
  margin: 2em auto 0 auto;
}
