.severityMenuItem {
  display: flex;
  align-items: center;
}

.bulkSeverityOverlay .severityMenu .severityMenuItem:not(.checked):hover .label,
.severityButton-0:hover,
.severityButton-0:focus,
.severityButton-1:hover,
.severityButton-1:focus,
.severityButton-2:hover,
.severityButton-2:focus,
.severityButton-3:hover,
.severityButton-3:focus,
.severityButton-4:hover,
.severityButton-4:focus {
  color: white !important;
}

.bulkSeverityOverlay .severityMenu .severity-0,
.severity-0 .label,
.severityButton-0 {
  color: #cccccc !important;
}

.bulkSeverityOverlay .severityMenu .severity-0:not(.checked):hover,
.bulkSeverityOverlay
  .severityMenu
  .severity-0
  :global(.ant-radio-checked .ant-radio-inner:after),
.severityButton-0:hover,
.severityButton-0:focus {
  background-color: #cccccc !important;
}

.bulkSeverityOverlay
  .severityMenu
  .severity-0
  :global(.ant-radio-checked .ant-radio-inner),
.bulkSeverityOverlay
  .severityMenu
  .severity-0
  :global(.ant-radio:hover .ant-radio-inner),
.severityButton-0,
.severityButton-0:hover,
.severityButton-0:focus {
  border-color: #cccccc !important;
}

.bulkSeverityOverlay .severityMenu .severity-1,
.severity-1 .label,
.severityButton-1 {
  color: #d46060 !important;
}

.bulkSeverityOverlay .severityMenu .severity-1:not(.checked):hover,
.bulkSeverityOverlay
  .severityMenu
  .severity-1
  :global(.ant-radio-checked .ant-radio-inner:after),
.severityButton-1:hover,
.severityButton-1:focus {
  background-color: #d46060 !important;
}

.bulkSeverityOverlay
  .severityMenu
  .severity-1
  :global(.ant-radio-checked .ant-radio-inner),
.bulkSeverityOverlay
  .severityMenu
  .severity-1
  :global(.ant-radio:hover .ant-radio-inner),
.severityButton-1,
.severityButton-1:hover,
.severityButton-1:focus {
  border-color: #d46060 !important;
}

.bulkSeverityOverlay .severityMenu .severity-2,
.severity-2 .label,
.severityButton-2 {
  color: #e8b019 !important;
}

.bulkSeverityOverlay .severityMenu .severity-2:not(.checked):hover,
.bulkSeverityOverlay
  .severityMenu
  .severity-2
  :global(.ant-radio-checked .ant-radio-inner:after),
.severityButton-2:hover,
.severityButton-2:focus {
  background-color: #e8b019 !important;
}

.bulkSeverityOverlay
  .severityMenu
  .severity-2
  :global(.ant-radio-checked .ant-radio-inner),
.bulkSeverityOverlay
  .severityMenu
  .severity-2
  :global(.ant-radio:hover .ant-radio-inner),
.severityButton-2,
.severityButton-2:hover,
.severityButton-2:focus {
  border-color: #e8b019 !important;
}

.bulkSeverityOverlay .severityMenu .severity-3,
.severity-3 .label,
.severityButton-3 {
  color: #60c5d4 !important;
}

.bulkSeverityOverlay .severityMenu .severity-3:not(.checked):hover,
.bulkSeverityOverlay
  .severityMenu
  .severity-3
  :global(.ant-radio-checked .ant-radio-inner:after),
.severityButton-3:hover,
.severityButton-3:focus {
  background-color: #60c5d4 !important;
}

.bulkSeverityOverlay
  .severityMenu
  .severity-3
  :global(.ant-radio-checked .ant-radio-inner),
.bulkSeverityOverlay
  .severityMenu
  .severity-3
  :global(.ant-radio:hover .ant-radio-inner),
.severityButton-3,
.severityButton-3:hover,
.severityButton-3:focus {
  border-color: #60c5d4 !important;
}

.bulkSeverityOverlay .severityMenu .severity-4,
.severity-4 .label,
.severityButton-4 {
  color: #8bb863 !important;
}

.bulkSeverityOverlay .severityMenu .severity-4:not(.checked):hover,
.bulkSeverityOverlay
  .severityMenu
  .severity-4
  :global(.ant-radio-checked .ant-radio-inner:after),
.severityButton-4:hover,
.severityButton-4:focus {
  background-color: #8bb863 !important;
}

.bulkSeverityOverlay
  .severityMenu
  .severity-4
  :global(.ant-radio-checked .ant-radio-inner),
.bulkSeverityOverlay
  .severityMenu
  .severity-4
  :global(.ant-radio:hover .ant-radio-inner),
.severityButton-4,
.severityButton-4:hover,
.severityButton-4:focus {
  border-color: #8bb863 !important;
}

.severityMenu {
  border-radius: 5px;
}