.detailsLabel {
  display: flex;
  align-items: center;
  width: 86px;
  margin-right: 10px;
  flex-shrink: 0;
  font-size: 9px;
  font-weight: 600;
  color: var(--labelColor);
  text-align: left;
}
