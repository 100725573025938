.settingsWrapper {
  padding-block: 20px;
}

.settingsTitle {
  margin-top: 0;
}

.settingsFormWrapper {
  max-width: 30vw;
  min-width: 300px;
  padding-right: 2rem;
}

.settingsWrapper :global(.ant-tabs-ink-bar) {
  display: none;
}

.settingsWrapper :global(.ant-tabs-tab) {
  margin-top: 0 !important;
  font-weight: bold;
}

.settingsWrapper :global(.ant-tabs-content-holder) {
  border-left: none;
  padding-left: 6px;
}

.settingsWrapper :global(.ant-tabs) {
  padding-top: 10px;
  max-width: 800px;
}

.settingsWrapper :global(h2) {
  margin-top: 0;
}

.settingsFormWrapper {
  max-width: 500px;
}

.integrationsWrapper :global(.ant-tabs) {
  max-width: 100%;
}

.integrationsWrapper .settingsFormWrapper {
  max-width: 100%;
}

.guestPermissionsView {
  max-width: 700px;
}

:global(.ant-tabs-nav::before) {
  right: unset !important;
}

.urlInputBox {
  display: flex;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  justify-content: space-between;
}

.urlInputBox input {
  height: 40px;
  font-weight: noral;
  letter-spacing: 0.7px;
  font-size: 15px;
  border: none;
  width: 100%;
  padding: 4px 0 4px 11px;
  cursor: default;
  max-width: 85%;
}

.commitWrapper .textBlock {
  max-width: 460px;
}

.copy {
  display: block;
  margin: auto 0;
  font-weight: normal;
  letter-spacing: 0.6px;
  padding-right: 20px;
  color: #3f71e0;
  cursor: pointer;
}

.commitWrapper h3 {
  padding-top: 1rem;
}

.buttonWrapper {
  display: inline-flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 2rem;
}

.buttonWrapper :global(.ant-btn-default) {
  transition: all 0.3s ease-in-out;
  display: inline-flex;
}

.buttonWrapper :global(.ant-switch) {
  height: 32px;
}

.buttonWrapper :global(.ant-btn-default:hover) {
  background-color: #1a49b0;
  color: #fff;
}

.buttonWrapper :global(.ant-btn-default:active) {
  background-color: #133683;
  color: #fff;
}

.buttonWrapper :global(.ant-switch-handle) {
  top: 20%;
}

.buttonWrapper :global(.ant-switch-inner) {
  display: grid;
  align-content: center;
}

.switchWrapper {
  margin-block: 2rem;
}

.switchText {
  padding-left: 1rem;
  color: inherit;
  color: #19222a;
}

.switchLink:hover {
  text-decoration: none;
}

.disableButton {
  display: inline-flex;
  color: #ff4d4f;
  padding: 0px 16px;
  height: 40px;
  align-items: center;
  transition: all 0.3s ease-in-out;
  border-color: inherit;
}

.disableButton:hover {
  color: #fff !important;
  border-color: #ff4d4f !important;
  background-color: #ff4d4f;
  text-decoration: none;
}

@media only screen and (max-width: 992px) {
  .settingsWrapper {
    max-width: 100%;
    margin: 0 1rem 2rem 0;
    height: unset;
    padding: 20px 0px 20px 4px;
    margin: 0px 18px 18px 18px;
  }

  .settingsWrapper :global(.ant-tabs) {
    display: flex;
    flex-direction: column;
  }

  .settingsWrapper
    :global(.ant-tabs-left
      > .ant-tabs-nav
      > .ant-tabs-nav-wrap
      > .ant-tabs-nav-list) {
    flex-direction: column;
    flex-wrap: nowrap;
    padding-bottom: 2rem;
  }

  .settingsWrapper :global(.ant-tabs-content-holder) {
    padding-left: 0;
  }

  .settingsTitle {
    margin-top: 2rem;
  }

  .settingsWrapper :global(.ant-tabs-nav-wrap) {
    display: none;
  }
}

.mobileDropdown {
  margin-left: 20px;
  margin-bottom: 1.5rem;
  display: flex;
}

.loader {
  margin-left: 15px;
}

.bugsHeader,
.formatLabel {
  font-weight: 400;
  font-size: 14px;
  margin: 0;
}

.radioButton {
  flex-grow: 1;
  text-align: center;
  font-weight: 100;
}

.label,
.formatLabel {
  cursor: default;
}

.label {
  margin: auto 0;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.2px;
  padding: 0 0 0 16px;
}

.switchBox {
  display: flex;
  padding: 0;
}

.formItem {
  margin: 0;
  padding: 0;
}

.paddedHeader {
  padding: 0 0 10px 0;
}

.alert {
  margin-top: 10px;
  font-size: 12px;
}

.export {
  margin-top: 25px;
}

.integrationsBanner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  color: white;
  border-radius: 15px;
  background-color: #3f71e0;
  margin-bottom: 15px;
}

.integrationsBanner h1 {
  color: white;
  margin-bottom: 0;
}

.integrationsBanner p {
  margin-top: 10px;
}

@media (max-width: 700px) {
  .integrationsBanner h1 {
    font-size: 22px;
  }
  .integrationsBanner p {
    font-size: 12px;
  }
  .integrationsBanner {
    padding: 0 15px;
  }
}
