.container {
  position: relative;
}

.toolbarButton {
  display: flex;
  align-items: center;
  border: 1px solid #d2d2d2;
  color: #787878;
  background: white;
  text-transform: capitalize;
}

.toolbarButton:hover,
.active:hover,
.active:focus,
.open,
.open:hover,
.open:focus {
  background: #3f71e0;
  color: white;
  border-color: #3f71e0;
}

.chevronIcon {
  margin-left: 5px;
}

.active {
  border-color: #3f71e0;
  color: #3f71e0;
  background-color: white;
}

.toolbarButton span {
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 900;
  font-style: normal;
}

.disabled,
.disabled:hover,
.disabled:focus {
  cursor: not-allowed;
  border-color: #d2d2d2;
  color: #b7b9bb;
  background-color: rgb(239, 241, 243, 0.8);
}
