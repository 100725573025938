.unpaid {
  color: #d46060;
  font-weight: 700;
}
span.paid {
  color: #8bb863;
  font-weight: 700;
}

.contentContainer {
  padding: 0px 5px;
}

.table :global(.ant-table-container) {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.table :global(.ant-table-container table > thead > tr:first-child th:first-child) {
  border-top-left-radius: 5px;
}
.table :global(.ant-table-container table > thead > tr:first-child th:last-child) {
  border-top-right-radius: 5px;
}