.whyModal {
  max-width: 430px;
}
.buttonAnchor {
  text-decoration: none;
}

.whyMessage {
  font-weight: 500;
}

.whyMessageLink,
.whyMoreInfoLink,
.whyMessageLink:hover,
.whyMoreInfoLink:hover,
.whyMoreInfoLink:visited,
.whyMessageLink:visited {
  color: #3f71e0;
  text-decoration: none;
}

.modalContent {
  min-width: 350px;
  max-width: 370px;
}

:global(.ant-modal-title) {
  font-size: 24px;
  line-height: 33px;
}

.whyModal :global(.ant-modal-close-x) {
  display: flex;
  align-items: center;
  justify-content: center;
}
