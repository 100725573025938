.addButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 22px;
  border-radius: 10px;
}

.mobileProjectHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 20px;
  margin-top: 4px;
}

.mobileProjectHeader h2 {
  margin-right: auto;
  padding-left: 1rem;
  margin-block: auto;
}

.feedbackBanner {
  position: fixed;
  z-index: 9;
  bottom: 2rem;
  width: 500px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  max-width: 500px;
  margin-inline: 1rem;
  padding: 20px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  color: var(--colorTextLightSolid);
  border-radius: 12px;
  border: 2px solid var(--cardColor);
  background: var(--colorPrimary);
  box-shadow: 0px 10px 10px 0px rgba(0, 57, 128, 0.2);
  animation: slideUpFadeIn 0.25ss ease-out;

  @media (width < 990px) {
    width: 300px;
  }

  & .closeButton {
    position: absolute;
    right: 1.5rem;
    cursor: pointer;
  }

  & span[role='img'] {
    margin-left: 0.5rem;
  }

  & button {
    margin-left: auto;
    margin-top: 1rem;
  }

  & h2,
  & p {
    margin: 0;
  }
}

.feedbackBanner.hidden {
  display: none;
}

@keyframes slideUpFadeIn {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

:global(#project_board_container > div:first-child) {
  height: 100%;
}

.projectNavWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
