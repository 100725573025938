.title {
  color: #19222a;
  font-size: 24px;
  font-weight: 900;
  text-align: center;
  margin: 0;
  margin-bottom: 1rem;
}

.subscribeCopy {
  color: #656667;
  font-size: 13px;
}

.disclaimer {
  color: #848484;
  font-size: 13px;
  line-height: 20px;
  margin: 10px 0 0 0;
}

.submit {
  text-align: center;
  margin: 40px 0 0 0;
}

.recaptcha > div > div {
  margin: 0 auto;
}

.accountLink {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.or {
  text-transform: uppercase;
  color: #848484;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  margin-block: 1rem;
  font-size: 12px;
}

.or::before,
.or::after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #848484;
  vertical-align: middle;
  margin: 0 10px;
}

.signUpButton {
  margin-inline: auto;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: #ffffff;
  background-color: #3f71e0;
  border-radius: 20px;
  padding: 8px 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  line-height: 22px;
}

.signUpButton:hover {
  color: #ffffff;
  background-color: #1a49b0;
  text-decoration: none;
}

.signUpButton:active,
.signUpButton:focus {
  background-color: #133683;
  color: #fff;
  text-decoration: none;
}

.backToWebsite {
  text-align: center;
  margin-bottom: 2rem;
}

.backToWebsite a {
  color: #ffffff;
  text-align: center;
}

.formWebsiteWrapper {
  display: flex;
  flex-direction: column;
  margin: auto;
}

@media (min-width: 990px) {
  .formWebsiteWrapper {
    padding-left: 40px;
    padding-right: 20px;
  }
}

@media (max-width: 990px) {
  .registerWrapper {
    flex-direction: column;
  }
}

:global(.logoOnlyHeader) {
  display: none;
}
