.sidebarBugPin {
  position: absolute;
  width: 30px;
  height: 26px;
  margin-top: -24px;
  margin-left: -10px;
}

.sidebarScreenshotContainer {
  margin: 6px auto;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  transition: height 0.3s ease;
  display: block;
}

.sidebarScreenshotContainer:hover {
  cursor: pointer;
}

.overlay,
.overlayTextWrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease 0s;
}

.overlay {
  background: rgba(0, 0, 0, 0.5);
}

._croppedPinContainer:hover .overlay,
._croppedPinContainer:hover .overlayTextWrapper {
  opacity: 1;
  transition: opacity 0.2s ease 0.3s;
}

._croppedPinContainer:hover ._pin {
  opacity: 0.3;
  transition: opacity 0.2s ease 0.3s;
}

.maximize {
  stroke: white;
}

.overlayText {
  font-size: 16px;
  font-weight: 900;
  padding: 4px 0 0 10px;
  color: white;
}

.minimizedLabel {
  font-size: 9px;
  font-weight: 600;
  color: #888;
  display: flex;
  align-items: center;
}

.minimizedContainer {
  display: flex;
  align-items: center;
}

.alertIcon {
  color: #faad14;
  padding-left: 10px;
  cursor: pointer;
}

.alertIcon svg {
  width: 16px;
  height: 16px;
}

.screenshotAlert {
  margin: 2px 0 7px 0;
}

.processingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.processingContainer :global(.ant-spin) {
  margin: 2px 20px 0 0;
}

.viewSwitch {
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.viewSwitch :global(.ant-switch-inner) {
  font-size: 9px;
}

.labelAndSwitchContainer {
  display: flex;
  justify-content: space-between;
  margin: 12px 0;
}

._screenshot {
  display: block;
  max-width: 100%;
  max-height: 300px;
  width: auto;
  height: auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}

.screenshotBorder {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  margin-bottom: 5px;
  max-width: 100%;
  display: inline-block;
}

.cropped ._screenshot {
  box-shadow: none;
}

.screenshotSectionContainer {
  text-align: center;
}

._screenshotWrapper {
  position: relative;
  margin: 0 auto;
  display: inline-block;
}

._pin {
  width: 30px;
  height: 26px;
  margin-top: -24px;
  margin-left: -10px;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  z-index: 1;
  transition: opacity 0.2s ease 0s;
}

._screenshotContainer {
  text-align: center;
  margin: 0 auto;
}

._croppedPinContainer {
  position: relative;
  display: block;
  text-align: center;
  margin: 0 auto;
}

.editIcon {
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin: 0 0 2px 1em;
  color: #3f71e0;
}

.progressContainer {
  margin: 0 auto;
  padding: 2em 1em;
  font-size: 12px;
  text-align: center;
  max-width: 300px;
}

.forIos :global(.ant-switch-inner) {
  margin-top: 2px !important;
}

.alertMessage {
  display: flex;
}

.retry {
  margin-left: auto;
}

.croppedViewWrapper {
  display: flex;
  flex-direction: row;
  column-gap: 6px;
}

.viewFocused,
.viewFull {
  border: 3px solid #848484;
  width: 25px;
  padding: 0;
  background-color: transparent !important;
  opacity: 1 !important;
}

.viewFocused {
  height: 18px;
  margin-top: auto;
}

.viewFull {
  height: 25px;
}

.viewChecked {
  background-color: #848484 !important;
}
