.layoutContent {
  padding: 0 20px 30px 20px;
  margin: 64px 0 6.25rem 0 !important;
}

.layoutContent :global(.ant-col) {
  margin: 0 !important;
}

.loader {
  height: 100vh;
  width: 100vw;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  top: 0;
  left: 0;
}

.myDashboard {
  margin-top: -2rem;
}

.myTasksHeader {
  display: flex;
  align-items: center;
  margin: 0 0 25px 0;
  text-transform: uppercase;
}

.myTasksHeader .helpIconOuter {
  margin-left: 10px;
}

.titleOuter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.statusNameColumn {
  text-transform: capitalize;
}

tbody tr .projectNameColumn {
  padding: 0 16px 0 0;
}

tbody tr .projectNameColumn span {
  padding: 17px 0 17px 10px;
}
.priority-0 {
  border-left: 6px solid #ccc;
}
.priority-1 {
  border-left: 6px solid #d46060;
}
.priority-2 {
  border-left: 6px solid #e8b019;
}
.priority-3 {
  border-left: 6px solid #60c5d4;
}
.priority-4 {
  border-left: 6px solid #8bb863;
}

.dueAtCell {
  text-transform: capitalize;
}

.yesterday {
  color: #d46060;
}
.today {
  color: #8bb863;
}
.tomorrow {
  color: #e8b019;
}

.yesterday,
.today,
.tomorrow {
  font-weight: 500;
}

tbody tr .assignedUsersColumn {
  padding: 7px;
}

.layout tbody tr {
  background: white;
}

.table :global(.ant-table-container) {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.table
  :global(.ant-table-container table > thead > tr:first-child th:first-child) {
  border-top-left-radius: 5px;
}
.table
  :global(.ant-table-container table > thead > tr:first-child th:last-child) {
  border-top-right-radius: 5px;
}

.localTaskId {
  color: #aaa;
}

@media (max-width: 980px) {
  .table :global(.ant-table) {
    width: calc(100vw - 10px);
    overflow-x: scroll;
    border-radius: 5px;
  }

  .layoutContent {
    padding: 0 0 0 20px;
  }

  .table :global(.ant-table-content) {
    min-width: 1100px;
  }
  .comments {
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
    padding-left: 0;
  }
}

.filterMargin {
  margin-bottom: 20px;
}

@media (max-width: 400px) {
  .table :global(.ant-table) {
    width: calc(100vw - 20px);
  }
}

@media (min-width: 1201px) and (max-width: 1450px) {
  .table :global(.ant-table) {
    width: calc(0.75 * (100vw - 50px));
    overflow-x: scroll;
  }

  .table :global(.ant-table-content) {
    min-width: 1100px;
  }
}

.pagination {
  margin: 10px 0 0 0;
  text-align: center;
}

/* Overrides the default sorter color*/
.table :global(.ant-table-column-sorter) {
  color: white;
}

.table
  :global(.ant-table-column-sorters):hover
  :global(.ant-table-column-sorter) {
  color: white;
}
