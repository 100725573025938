.integrationLinkOuter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 4px 0;
  border-bottom: 1px solid #dbe3e6;
  width: 100%;
  min-height: 24px;
}

.link {
  text-decoration: none;
  display: flex;
  align-items: center;
}

.link:focus {
  text-decoration: underline;
}

.linkIcon {
  margin-left: 5px;
  display: flex;
}
