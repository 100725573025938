.board {
  overflow-x: auto;
  display: flex;
  width: 100%;
  overflow-y: hidden;
  position: relative;
  height: 100%;
}

.cardContainer {
  max-width: 300px;
}

.cardContainer:active {
  cursor: grabbing;
}

.dropPlaceholder {
  border-radius: 4px;
  background: #848484;
  margin: 4px 0;
}

.isDragging {
  cursor: grabbing;
}

.scrollbarContainer {
  padding: 4px 0;
  display: flex;
}

.addColumn {
  color: #3f71e0;
  border: 2px solid #d9e7f4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  height: 40px;
  width: 40px;
}

.addColumn:hover {
  color: #1a49b0;
}

.addColumn svg {
  width: 18px;
  height: 18px;
}

.kanbanHeaderBar {
  height: 46px;
  border-top: 1px solid #f1f1f1;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 0;
  background: white;
}

.columnOuter {
  margin-top: 1px;
}

.headerMask {
  height: 45px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 0;
}

.headerMask::after {
  background: transparent;
  height: 4px;
  width: 100%;
  content: '';
  position: absolute;
  bottom: -4px;
  pointer-events: none;
  z-index: 6;
}

.draggableColumnContainer {
  display: flex;
  gap: 6px;
  padding: 0px 12px 12px 0px;
}

.column {
  width: 300px;
}

.headerColumns {
  display: flex;
}

.boardContent {
  display: flex;
}

.moveMultiple {
  position: fixed;
  z-index: 100;
  bottom: 32px;
  left: 50%;
}

@media (max-width: 580px) {
  .draggableColumnContainer {
    padding-left: 18px;
  }
}
