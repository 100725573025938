h4 {
  font-size: 14px;
}

.overlay {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fafafa;
  justify-content: center;
  align-items: center;
  z-index: 1;
  border-radius: 2px;
  border: 2px dashed #ccc;
  flex-direction: column;
  text-align: center;
}

.uploadIcon {
  width: 40px;
  height: 40px;
}

.instruction {
  color: #666666;
  margin-bottom: 5px;
}

.validFiles {
  color: #ccc;
  margin-bottom: 0;
}

.actionsBox {
  display: flex;
  justify-content: space-between;
}

.noLogoToRemove {
  justify-content: center;
}

.uploadButton {
  display: flex;
  align-items: center;
}

.demoBox {
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
}

.lightDemoOuter,
.darkDemoOuter {
  position: relative;
  border-radius: 2px;
}

.lightLogoContainer,
.darkLogoContainer {
  max-width: 48%;
}

.lightLogoContainer .demoBox {
  border: 2px solid #ccc;
}

.darkLogoContainer .demoBox {
  background-color: black;
  border: 2px solid black;
}

.darkLogoContainer .demoBox .uploadButton {
  color: white;
}

.icon {
  width: 14px;
  height: 14px;
  margin: -2px 5px 0 0;
}

.logoBox {
  max-width: 100px;
  max-height: 25px;
  width: 100%;
  height: 100%;
}

.removeLogo,
.cancel {
  display: flex;
  align-items: center;
}

.customLogo {
  max-width: 100px;
  max-height: 25px;
}

@media (max-width: 767px) {
  .lightLogoContainer,
  .darkLogoContainer {
    max-width: 100%;
  }
}