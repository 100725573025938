.markerContainer {
  position: relative;
}

.markerContainer > div {
  left: 0 !important;
  top: 0 !important;
}

.image {
  width: auto;
  height: auto;
  max-height: 100vh;
  max-width: 100vw;
}

.container {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
