.guestsMessage {
  text-align: left;
}
.switch {
  margin-right: 10px;
}

.urlLabel {
  margin-right: 10px;
}

.label {
  display: inline-block;
  color: #666666;
  margin-bottom: 8px;
}

.projectUrl {
  text-align: left;
}

.formContainer :global(.ant-form-item-explain) {
  margin-top: 3px;
  margin-bottom: 5px;
  text-align: start;
}

.switchItem :global(.ant-form-item-control-input-content) {
  display: flex;
  align-items: center;
}
