.pane {
  margin: 0;
  color: #4c5664;
  padding: 0;
}

.pane h3 {
  font-size: 42px;
  font-weight: 700;
}

.contentBlock {
  color: inherit;
  background-color: white;
  margin-top: 30px;
}

.activeCoupon {
  margin-bottom: 10px;
}

.planCallout {
  text-align: center;
  font-size: 23px;
  margin: 30px 0;
  font-weight: 600;
  padding-block: 1.5rem;
}

.hero {
  text-align: left;
}

.pausedActions {
  margin-top: -4px;
}

.pausedActions span:not(.iconBox) {
  display: flex;
  align-items: center;
}

.iconBox svg {
  stroke: #20c0e7;
  width: 20px;
  height: 20px;
  margin: 2px -5px 0 0;
}

.pausedActions:first-child {
  margin-top: 0;
}

.planWarning {
  color: #c09853;
  display: block;
  margin-bottom: 15px;
}

.switch .button {
  font-size: 12px;
}

.switch p {
  color: #98a2b1;
  margin-bottom: 0;
}

.radioContainer {
  display: flex;
  justify-content: center;
}

.cancel svg {
  margin-top: -2px;
}

.plans {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.havingTrouble {
  text-align: center;
  padding-bottom: 1.5rem;
}

.moreUsers {
  background-color: #e0f0f9;
  display: flex;
  border-radius: 4px;
  width: 100%;
  max-width: 570px;
  margin-block: 20px;
  margin-inline: auto;
}

.moreUsers h3 {
  margin: 0 0 10px 0;
}

.moreUsers .innerLeft {
  padding: 20px 0px 5px 20px;
}

.moreUsers .innerRight {
  width: 50%;
  height: inherit;
  background-image: url('./additional-users-banner.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.addMembersButton {
  padding: 0;
}

.addMembers {
  text-decoration: underline;
  padding: 0;
  color: #406cff;
  line-height: 16px;
  font-weight: 600;
}
