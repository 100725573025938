.userHeader {
  display: flex;
  margin-top: -10px;
}

.membersName {
  margin: auto 0;
  padding-left: 20px;
}

.select {
  color: #20c0e7;
  font-size: 12px;
  cursor: pointer;
}

.selectButtonsBox {
  padding-bottom: 5px;
}

.selectAll {
  padding-right: 5px;
}

.selectNone {
  padding-left: 5px;
}

.projectBox {
  padding: 5px 0;
}

.accessHeader {
  font-weight: 500;
}

.checkboxLabel {
  font-weight: 300;
}

.buttonsBox {
  padding-top: 22px;
}

.searchIcon,
.closeIcon {
  color: rgb(191, 191, 191);
  width: 14px;
}

.searchBar,
.searchBar input {
  font-weight: 400;
}

.searchBar input:nth-child(2)::placeholder {
  font-weight: 300;
}

.searchBar {
  max-width: 45%;
}
