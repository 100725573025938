.hostPopoverContent {
  max-width: 200px;
}

.labelPopoverActionsOuter {
  display: flex;
  justify-content: flex-end;
}

.alertPopoverIcon {
  position: absolute;
  left: 70px;
  top: 6px;
}

.popoverOk {
  background-color: #3f71e0;
}

.popoverOk:hover {
  background-color: #1a49b0;
}

.popoverOk:focus,
.popoverOk:active,
.popoverOk:visited {
  background-color: #133683;
}

.popoverCancel {
  color: #3f71e0;
}

.popoverCancel:hover {
  color: #1a49b0;
}

.popoverCancel:focus,
.popoverCancel:active,
.popoverCancel:visited {
  color: #133683;
}
