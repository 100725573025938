.additionalInfoInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 4px 0;
  border-bottom: 1px solid #dbe3e6;
  width: 100%;
  min-height: 24px;
}

.additionalInfoOuter {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media screen and (min-width: 980px) {
  .additionalInfoOuter {
    padding-bottom: 2rem;
  }
}

.isExpanded {
  padding-top: 5px;
}

.tableRow {
  border-bottom: 1px solid #dbe3e6;
  color: #666666;
}

.tableHeader {
  font-size: 12px;
  width: 120px;
  line-height: 20px;
  padding: 0;
  text-transform: capitalize;
  font-weight: 500;
}

.tableData {
  line-height: 20px;
  font-size: 12px;
  padding-left: 5px;
  overflow: auto;
}

.expanded {
  margin-bottom: 0px;
  border: unset;
  padding-bottom: 0px;
}

.toggleButton {
  font-size: 12px;
  color: #3f71e0;
}

.toggleButton:hover {
  color: #1a49b0 !important;
  font-size: 12px;
  background-color: unset !important;
}

.toggleButton:focus,
.toggleButton:visited,
.toggleButton:active {
  color: #133683;
  font-size: 12px;
}

.tableData a {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.tableHeader {
  vertical-align: middle;
}

.selectorHeader {
  vertical-align: top;
}

.infoTable {
  width: 100%;
  table-layout: fixed;
}

.container .infoTable .tableData .absoluteUrl {
  max-width: 193px;
}

.tableHeader {
  text-align: left;
}
