.inputAndControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.saveButton {
  margin-left: 10px;
}

.form {
  width: 100%;
}

.columnNameInput:focus,
.columnNameInput:focus:invalid:focus {
  border: none;
  box-shadow: 0 0 0 2px rgba(32, 192, 231, 0.2);
}

.columnNameInput {
  border: none;
  height: 24px;
  padding: 0 0 0 5px;
  max-width: calc(100% - 40px);
}

.colorBackground {
  stroke: white;
}

.cancelIcon {
  cursor: pointer;
}
