.favourites .menuIcon {
  stroke: #e8b019;
  fill: #e8b019;
}

.menuItem {
  background-color: #f6f9fc;
  font-weight: 600;
  font-size: 13px;
}

.menuItem svg {
  width: 18px;
  height: 18px;
}

.menuItem:hover {
  background-color: #f6f9fc;
}

.menu {
  background-color: #f6f9fc;
  border-right: none !important;
  padding-left: 25px;
}

.newProjectButton {
  margin: 0 20px 20px 20px;
  width: 260px;
  border-radius: 12px;
  padding-top: 25px;
  padding-bottom: 25px;
}

.menuItem .subMenuItem {
  color: #19222ae0;
}

.subMenuItem.active,
.subMenuItem.active:hover,
.menuItem.active,
.menuItem.active:hover,
.newFolder,
.newFolder:hover {
  color: #3f71e0 !important;
}

.menu :global(.ant-menu-item-divider) {
  border-top-width: 3px !important;
  border-color: var(--colorBgAccent) !important;
}

.watchButton {
  padding: 0;
}

.videoPreview {
  max-width: 100%;
  max-height: 120px;
  margin-top: 32px;
  border: none;
}

.infoMessage {
  padding: 0 8px;
}

.infoMessage strong {
  padding: 2px 4px;
  background-color: var(--colorBgAccent);
  border-radius: 4px;
}

.alert {
  margin-left: 25px;
}

.alertContent {
  position: relative;
  padding: 4px 0px;
}

.alertCloseIcon {
  position: absolute;
  right: -7px;
  top: -3px;
}

.pulseIndicator {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  animation: pulse 2s infinite;
  position: absolute;
  top: 6px;
  left: 12px;
  z-index: 1;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
    scale: 0.75;
  }
  70% {
    box-shadow: 0 0 0 5px rgba(255, 0, 0, 0);
    scale: 1;
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    scale: 0.75;
  }
}

.newFolderTour {
  position: relative;
}
