.cardOuter {
  position: relative;
  height: fit-content;
  width: 33.333%;
}

@media (max-width: 650px) and (min-width: 551px) {
  .cardOuter {
    width: 50%;
  }
}

@media (max-width: 550px) {
  .cardOuter {
    width: 100%;
  }
}

.errorAlert {
  font-size: 0.75rem;
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  z-index: 1;
  text-align: center;
  font-weight: 500;
}

.cardLink {
  display: flex;
  flex-direction: column;
  margin: 10px;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  height: fit-content;
}

@media (max-width: 550px) {
  .cardLink {
    width: calc(100% - 20px);
  }
}

.card,
.inactiveCard {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.13);
  transition: all 0.5s ease;
}

.card :global(.ant-card-cover),
.inactiveCard :global(.ant-card-cover) {
  max-height: 180px;
  overflow: hidden;
}

.inactiveCard .img {
  filter: grayscale(1) opacity(0.2);
}

.cardLink :global(.ant-card-body) {
  padding: 15px 10px;
}

.guestView .cardLink :global(.ant-card-body) {
  padding: 15px 10px 30px 10px;
}

.guestView .viewLink {
  bottom: 20px;
}

.img {
  background: url(../images/project.png) 50% no-repeat;
}

@media (min-width: 700px) {
  .img {
    min-height: 178px;
  }
}

.retry,
.disabled {
  display: flex;
  align-items: center;
  color: #fff;
  border: 0;
  border-radius: 5px;
  background: #a2adb0;
  font-size: 0.75rem;
  transition: all 0.2s ease;
  padding: 0.1em 0.5em;
}

.retry,
.retry:hover,
.retry:active {
  outline: 0;
}

.retry:hover {
  transform: scale(1.1);
}

.retryIcon {
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
}

.updateButtonContainer {
  font-weight: 100;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.updateButtonContainer :global(.ant-btn) {
  background-color: gray;
  color: white;
}

.viewLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding-left: 3px;
  position: absolute;
  bottom: 25px;
  left: 20px;
}

.viewIcon {
  width: 12px;
  height: 12px;
  margin: 0 0.3em 2px 0;
}

.alertIcon {
  width: 14px;
  height: 14px;
}

.cardBody {
  font-size: 0.75rem;
}

.project {
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.project h3 {
  margin: 0 0 0 0;
  padding-bottom: 10px;
  word-break: break-all;
}

.refreshButton {
  padding-right: 0;
  display: flex;
  align-items: center;
  line-height: 15px;
  font-weight: 300;
  font-size: 12px;
  padding: 2px 2px 0 2px;
}

.cardActions {
  padding-top: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 29px;
}

.buttonInner {
  display: flex;
  align-items: center;
}

.openCount {
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  font-size: 12px;
  align-items: center;
  border-radius: 7px;
  background: #3f71e0;
  color: #fff;
  max-width: 55px;
  padding: 3px 7px 3px 5px;
  white-space: nowrap;
  z-index: 1;
}

.withFavourites {
  right: 40px;
}

.checkbox {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 1;
}

.favouriteBox {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4.6px;
  border-radius: 6px;
  z-index: 1;
  color: var(--colorGrey3);
  background: white;
  cursor: pointer;
}

.favouriteBox:hover {
  color: var(--colorSeverityImportant);
}

.favouriteBox.active .starIcon {
  stroke: var(--colorSeverityImportant);
  fill: var(--colorSeverityImportant);
}

.starIcon {
  width: 16px;
  height: 16px;
}

.openFeedback {
  background-color: #d0021b;
}

.mapPinIcon {
  min-width: 14px;
  min-height: 14px;
  width: 14px;
  height: 14px;
  fill: #fff;
  margin-right: 4px;
}

.mapPinIcon circle {
  color: #3f71e0;
  fill: #3f71e0;
  border-color: #3f71e0;
}

.openFeedback .mapPinIcon circle {
  color: #d0021b;
  fill: #d0021b;
  border-color: #d0021b;
}

.card:hover {
  box-shadow: 0 10px 10px 0 rgba(0, 57, 128, 0.2);
}

.updatedAgo {
  padding-left: 3px;
}

.project h3 {
  padding-left: 3px;
}

.updatedAndRefresh {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.cardOuter {
  --base-delay: 0.1s;
  --delay-increment: 0.05s;
}

.staggerInAnimation {
  opacity: 0;
  animation: fadeInUp 0.5s ease forwards;
  animation-delay: calc(
    var(--base-delay) + var(--delay-increment) * var(--index, 0)
  );
}

.folderLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  color: var(--colorGrey3);
  background-color: #ececf3;
  margin-bottom: 5px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 1px 5px;
  width: fit-content;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  border-radius: 12px;
}

.foldersIcon {
  width: 11px;
  height: 11px;
  margin-bottom: 1px;
}

.folderNameLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 14px;
  line-height: 14px;
  margin-bottom: 1px;
}
