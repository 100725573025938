.browserName {
  text-transform: capitalize;
}

.installButtonChrome,
.installButtonEdge,
.installButtonFirefox {
  background: #3f71e0;
  font-size: 1rem;
  color: #fff;
  border-radius: 100px;
  padding: 0.85em 1em 0.85em 3.2em;
  position: relative;
  transition: all 0.2s ease;
  display: inline-block;
  margin: -0.7em 0 0 0;
  text-decoration: none;
  border: none;
}

.installButtonChrome:hover,
.installButtonEdge:hover,
.installButtonFirefox:hover {
  color: #fff;
}

.installButtonChrome::after,
.installButtonEdge::after,
.installButtonFirefox::after {
  width: 40px;
  height: 40px;
  content: '';
  position: absolute;
  background-size: contain;
  left: 3px;
  top: 5px;
}

.installButtonChrome::after {
  background-image: url('./browser_icons/chrome.png');
}

.installButtonEdge::after {
  background-image: url('./browser_icons/edge-chromium.png');
}

.installButtonFirefox::after {
  background-image: url('./browser_icons/firefox.png');
}

.browser {
  max-width: 324px;
  display: block;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.32);
  border-radius: 3px 3px 0 0;
  margin: 0 auto;
}

.extensionDesc {
  display: inline-block;
  position: relative;
  color: #848484;
  margin-bottom: 1.3em;
}

.extensionDesc::after {
  content: '';
  background: url('./images/arrow.png');
  width: 24px;
  height: 26px;
  background-size: cover;
  display: block;
  position: absolute;
  right: -0.3em;
  bottom: -2em;
}

.extensionContainer {
  margin: 0 auto 2.2em auto;
  max-width: 820px;
}

.hr {
  max-width: 240px;
  margin: 2.9em auto;
  background-color: #b8b8b8;
  border: 0;
  height: 1px;
}

.alternativeBrowsers .logo {
  opacity: 0.4;
  filter: grayscale(1);
  width: 44px;
  height: 44px;
  transition: all 0.2s ease;
  cursor: pointer;
}

.alternativeBrowsers .logo:hover {
  filter: grayscale(0);
  opacity: 1;
}

.cantInstall {
  display: block;
  margin-top: 1.7em;
  font-size: 1rem;
  font-weight: 300;
}

.question {
  display: block;
  margin: 1.7em;
  font-size: 1rem;
}

.unknownBrowserLogo {
  display: block;
  width: 100px;
  height: 100px;
  margin: 0 auto 0.5em auto;
}

.unknownBrowserList {
  display: flex;
  justify-content: center;
  margin-bottom: 3.5em;
}

.unknownBrowser {
  margin: 0 2.55em;
}

.installCode {
  max-width: 360px;
  margin: 0 auto;
}

.infoText {
  margin: 0 20%;
  text-align: left;
}

.container {
  background-image: url('../../../assets/bg-diamonds.png');
  background-repeat: no-repeat;
  background-position: center top;
  padding-bottom: 30px;
}

.linksClosed {
  height: 790px;
}

.unsupportedMessage {
  margin: 50px auto;
  max-width: 600px;
  line-height: 1.4;
  color: #7d7d7d;
}
