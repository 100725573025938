.teamworkExplanation {
  margin-bottom: 20px;
}

.teamworkExplanation ul {
  list-style-type: circle;
  padding-left: 20px;
  margin-bottom: 20px;
}

.teamworkChannelHeader {
  padding: 0px 0px 2px 14px;
  font-weight: bold;
}

.infoContainer {
  padding-left: 10px;
}

.teamworkWorkflows {
  margin-left: 7px;
}

.teamworkEditRow :global(.ant-col) {
  padding-right: 6px;
  padding-left: 6px;
}

.teamworkDomain {
  position: relative;
  top: 36px;
  left: -8px;
  font-weight: bold;
}

.completedColumn {
  margin: 10px 0px;
}

.save {
  margin-right: 2px;
}

.save,
.save:active,
.save:focus {
  background-color: #3f71e0;
  color: #fff;
  border-color: #d9d9d9;
}

.disconnectContainer {
  display: flex;
  border: 1px solid #d2d2d2;
  border-radius: 3px;
  justify-content: space-between;
  align-items: center;
  padding: 20px 15px;
}

.disconnectButton {
  color: #d0021b;
  border: 1px solid #d9d9d9;
}

.disconnectButton:hover {
  background: #d0021b;
  color: #ffff;
}
