.listItemOuter {
  background: #e6f7ff;
  display: flex;
  padding: 10px 0px 10px 14px;
  align-items: center;
}

.listItemOuter svg {
  width: 18px;
  height: 18px;
  stroke: #20c0e7;
  margin-right: 10px;
}

.listItemOuter p {
  margin: 0;
}
