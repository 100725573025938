.preview {
  width: 100%;
  height: 90px;
  background: #f5eeed;
  border-radius: 5px;
  box-shadow: 0 5px 5px 0 rgba(0, 57, 128, 0.2);
  margin-top: 15px;
}

.previewFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 10px 10px;
}

.captured {
  color: #fff;
  font-weight: 500;
  font-size: 13px;
  display: flex;
  align-items: center;
  padding-left: 4px;
}

.checkCircle,
.editIcon,
.playIcon,
.maximizeIcon {
  margin: 0 5px 2px 0;
  width: 17px;
}

.previewButton,
.previewButton:active,
.previewButton:visited,
.previewButton:focus {
  color: #3f71e0;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  background: #fff;
  padding: 4px 9px;
}

.previewButton:hover {
  color: #3f71e0;
  background-color: #ebebeb;
}

.loadingOuter {
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  background-color: #f5eeed;
  border-radius: 5px;
  box-shadow: 0 5px 5px 0 rgba(0, 57, 128, 0.2);
  margin-top: 15px;
  justify-content: center;
}

.loaderIcon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-bottom: 2px;
}

.loadingInfo {
  margin-right: 5px;
}

.overlay {
  width: 100%;
  height: 100%;
  background-color: #6666669b;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
}

.bugPinContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bugPinContainer img {
  width: 20px;
  height: 24px;
  margin-bottom: 28px;
  object-fit: cover;
  object-position: left;
}

.recordingPreview {
  height: 90px;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  box-shadow: 0 5px 5px 0 rgba(0, 57, 128, 0.2);
}

.previewOuter {
  position: relative;
}

@media (max-width: 600px) {
  .previewOuter {
    display: none;
  }
}
