.aboutOuter {
  display: flex;
  justify-content: center;
}

.emptyJavascript
  :global(.ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title) {
  color: #8bb863;
}

.emptyJavascript
  :global(.ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description) {
  color: rgba(0, 0, 0, 1);
}

.emptyJavascript :global(.ant-steps-item-title) {
  font-weight: 500;
}
.verify {
  margin-left: 45px;
}

.emptyJavascript
  :global(.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon),
.emptyJavascript
  :global(.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon),
.emptyJavascript
  :global(.ant-steps-item-custom
    > .ant-steps-item-container
    > .ant-steps-item-icon
    > .ant-steps-icon) {
  color: #fff;
  font-size: 16px;
}

.emptyJavascript
  :global(.ant-steps-item-error
    .ant-steps-item-container
    > .ant-steps-item-icon) {
  background: #f5222d !important;
}

.emptyJavascript
  :global(.ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-icon) {
  background-color: #8bb863 !important;
}
.emptyJavascript
  :global(.ant-steps-item-custom
    > .ant-steps-item-container
    > .ant-steps-item-icon),
.emptyJavascript
  :global(.ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-icon) {
  background: rgb(24, 144, 255);
  border-color: rgb(24, 144, 255);
  color: #fff;
  font-size: 16px;
}
