.removeUrl {
  width: 18px;
  height: 18px;
}

.removeButton {
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 4px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  cursor: pointer;
  color: #848484;
}

.removeButton:hover {
  background-color: #f1f1f1;
  color: #848484;
}

.removeButton:focus,
.removeButton:focus:hover {
  background-color: #848484;
  color: white;
}

.popoverContent {
  padding: 5px 10px;
}

.popover :global(.ant-popover-inner-content) {
  padding: 0;
  border-radius: 4px;
}

.trashIcon {
  margin: 0px 5px 2px 0px;
}

.confirmButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  color: #d0021c;
  font-size: 12px;
}

.confirmButton:hover,
.confirmButton:focus {
  color: #d0021c;
}
