.moreMenuItem {
  padding-left: 24px;
}

.moreMenuItem :global(.ant-dropdown-menu-title-content) {
  max-width: 260px;
}

.projectName {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.moreMenu {
  min-width: 125px;
  max-height: 500px;
  overflow-y: scroll;
}

.moreMenuSidebar {
  max-width: 318px;
}

.delete,
.shareUrl,
.showAllOtherProjects {
  display: flex;
  align-items: center;
}

.moreMenuItemIcon {
  width: 14px;
  height: 14px;
  stroke: #666666;
  margin-right: 0.5em;
}

.deleteLoader {
  width: 14px;
  height: 14px;
  margin-right: 0.5em;
}

.moreActionsButton {
  display: flex;
  align-items: center;
  color: #3f71e0;
  max-width: 150px;
}

.moreActionsButton:hover {
  color: #1a59b0;
}

.moreActionsButton:focus {
  color: #133683;
}

.moreActionsButton .menuToggleIcon {
  margin-left: 5px;
}

.markAsInner {
  display: flex !important;
}

.deleteTask {
  padding-bottom: 5px;
}

@media (min-width: 951px) and (max-width: 1280px) {
  .moreMenuAdmin {
    max-width: 268px;
  }
}

@media (min-width: 1281px) and (max-width: 1440px) {
  .moreMenuAdmin {
    max-width: 368px;
  }
}

@media (min-width: 1441px) {
  .moreMenuAdmin {
    max-width: 468px;
  }
}

.confirmDelete {
  color: #d46060;
}

.confirmDelete .moreMenuItemIcon {
  stroke: #d46060;
}

.antdDropdownOverride {
  border-radius: 20px;
}

@media (min-width: 951px) and (max-width: 1350px) {
  .moreActionsButton {
    font-size: 11px;
  }
}

.confirmDelete:hover {
  background-color: #ff4d4f !important;
  color: white !important;
}

.projectListItem {
  background-color: white !important;
}

.projectListItem:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.projectListItem span,
.recentProjectItem span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.projectListItem span {
  max-width: 142px;
}

.recentProjectItem span {
  max-width: 150px;
}
