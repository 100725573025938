.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 60%;
  min-height: 60%;
}

.container * {
  font-size: 1rem;
}

.header {
  text-align: center;
}

.header h1 {
  color: #19222a;
  font-family: inherit;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 33px;
  text-align: center;
}

.info {
  color: #19222a;
  font-family: inherit;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  width: 100%;
  margin: 20px auto;
}

.info b {
  font-weight: 500;
}

.footer {
  text-align: center;
  margin-top: 0.75em;
}

.terms {
  font-size: 0.8rem;
  text-align: center;
}

.terms a {
  font-size: 0.8rem;
}

@media (max-width: 450px) {
  .container {
    margin-top: 100px;
    margin-bottom: 170px;
  }
}
