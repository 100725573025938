.errorIcon {
  width: 18px;
  height: 18px;
}

.errorIcon circle {
  color: #ff4d4f;
  fill: #ff4d4f;
}

.errorIcon line {
  color: white;
}

.errorText {
  color: #848484;
  font-weight: 500;
  font-size: 12.5px;
}

.whyButton {
  padding: 0;
  font-size: 12.5px;
  font-weight: 600;
  cursor: pointer;
}

.closeContainer {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 50%;
  background-color: #f1f1f1;
  color: #d46060;
  cursor: pointer;
}

.closeIcon {
  width: 14px;
  height: 14px;
}

.whyModal {
  top: 0px
}

.whyModal h2 {
  font-weight: 600;
  margin: 0;
}

.whyModal :global(.ant-modal-content) {
  border-radius: 10px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  padding-top: 25px;
  font-size: 15px;
}

@media screen and (min-width: 750px) {
  .whyModal {
    width: 700px !important;
    /* required to override antd styles */
    top: 100px;
  }
}

.whyModalDetails {
  background-color: #151e26;
  color: #ffffff;
  padding: 1rem; 
  border-radius: 10px;
  font-size: 13px;
}

.errorContainer {
  display: flex;
  align-items: center;
}

.smallButton {
  font-size: 12.5px;
}

.space :global(.ant-space-item):first-child {
  align-items: center;
  display: flex;
}

.space div:first-child {
  margin-right: 5px !important;
}
