.integrationBox {
  margin: 40px 4px 0px 4px;
  display: flex;
  align-items: flex-start;
}

.firstBox {
  margin-top: 20px;
}

.logoBox {
  width: 125px;
  height: 125px;
  box-shadow: 0px 2px 8px rgb(199, 199, 199);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  padding: 8px;
}

.logo {
  max-width: 95px;
  max-height: 45px;
}

.zapier {
  margin-top: -10px;
}

.basecamp {
  max-height: 80px;
}

.moreInfo {
  max-width: 400px;
  margin-left: 20px;
}

.title {
  font-weight: 500;
  text-transform: uppercase;
}

.about {
  margin-top: 10px;
}

.controlsBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.connected {
  color: #8bb863;
  display: flex;
  justify-content: space-between;
  width: 95px;
  align-items: center;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.check {
  max-width: 17px;
}

.connectManageAnchor a,
.connectManageAnchor a:active,
.connectManageAnchor a:visited,
.connectManageAnchor a:hover,
.connectManage a,
.connectManage a:active,
.connectManage a:visited,
.connectManage a:hover,
.projects,
.projects:active,
.projects:visited,
.projects:hover {
  text-decoration: none;
  color: white;
}

.twoButtons {
  max-width: 180px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 850px) {
  .logoBox {
    width: 100px;
    height: 100px;
  }
  .logo {
    max-width: 75px;
  }
}

@media (max-width: 725px) {
  .integrationBox {
    margin: 20px 2px 0px 2px;
  }
  .logoBox {
    width: 75px;
    height: 75px;
  }
  .logo {
    max-width: 56px;
  }
  .controlsBox {
    flex-direction: column;
    align-items: start;
  }
}

.projects {
  margin-left: 10px;
}

.connectManage {
  margin-left: 0px;
}

.findOutMoreButton {
  padding: 0px;
}