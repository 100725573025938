.filterNoPanelShow,
.filterNoPanelShow:active,
.filterNoPanelShow:focus {
  border: 1px solid #d2d2d2;
  color: #787878;
  background-color: transparent;
}

.filterNoPanelShow :global(.ant-badge-count) {
  color: #787878;
  background-color: #3f71e0;
  border: 1px solid #787878;
  box-shadow: none;
}

.filterPressedButton :global(.ant-badge-count),
.filterNoPanelShow:focus:hover :global(.ant-badge-count) {
  color: #ffffff;
  background-color: #3f71e0;
  border: 1px solid #ffffff;
  box-shadow: none;
}

.filterButtonContainer :global(.ant-badge-count-sm) {
  font-size: 10px;
}

.filterIcon {
  margin-left: 6px;
  height: 16px;
  display: block;
}

.filterPanel {
  margin-top: 10px;
}

.filterPopover {
  border: 1px solid #d2d2d2;
  text-shadow: none;
}

.popoverFooterContainer {
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
}

.filterPanel :global(.ant-popover-inner) {
  border-radius: 10px !important;
}

.panelButton {
  border: 1px solid #d2d2d2;
  color: #787878;
  background: none;
  text-shadow: none;
}

.popoverContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.popoverContentVertical {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.popoverDivider {
  margin-top: 4px;
  margin-bottom: 12px;
}

.filterAppliedButton {
  border: 1px solid #3f71e0;
  color: #3f71e0;
  background: none;
  text-shadow: none;
}

.filterPanelContainer {
  align-self: center;
}

.badgeCount {
  margin-left: 5px;
}

.filterButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.clearButton,
.clearButton:active,
.clearButton:focus {
  color: #787878;
  background-color: transparent;
  font-weight: 900;
}

.boldFont {
  font-weight: 900;
  min-width: 65px;
  margin-top: 6px;
}

.filterPanel :global(.ant-popover-arrow) {
  display: none;
}

.panelWrap:not(:last-child) {
  margin-right: 5px;
}

.panelWrap {
  margin-bottom: 10px;
}

.footerContainerWithFilter {
  margin-bottom: 5px;
}

.filtersContainer {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
}

.filtersContainer :global(.ant-popover) {
  max-height: 100% !important;
}

.scrollableContent {
  overflow-y: auto;
  max-height: 200px;
  width: 100%;
}
