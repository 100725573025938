.tagListScroll {
  overflow: hidden auto;
  height: 100%;
}

.archivedTagsDivider {
  margin-top: 2em;
  font-size: 22px;
}

.archivedTagsDivider p {
  font-size: 14px;
  margin-top: 1em;
}

.tagEmptyContainer {
  width: 100%;
  height: 160px;
  background-color: #f1f1f1;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.tagEmptyContent {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}

.tagEmptyHeader {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
}

.tagItemContainer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
  list-style-type: none;
  border-bottom: 1px solid #e1e4e8;
  padding: 16px 10px 16px 0;
}

.tagEditForm {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.tagEditInput {
  border: 1px solid #20c0e7;
  border-radius: 5px;
  width: 50%;
}

.tagEditBtns {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.tagDisplayOuter {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.tagNameDisplay {
  width: 50%;
  color: #373737;
  margin: auto 0;
}

.tagCount {
  color: #919191;
}

.actionsOuter {
  display: flex;
}

.actionsOuter a {
  text-decoration: none;
  text-align: center;
}

.archive,
.archive:hover,
.archive:visited {
  color: #8c8c8c;
  width: 80px;
}

.edit,
.edit:hover,
.edit:visited {
  color: #3f71e0;
  width: 75px;
}

.switchContainer {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.switchLabel {
  margin-left: 10px;
}
