.container {
  width: 25vw;
  margin-top: 30px;
}

.buttons {
  margin: 25px 0;
}

.horizontal {
  display: flex;
}
