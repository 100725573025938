.commentsAndLogOuter {
  margin: 0;
  padding: 4px 0;
  width: 100%;
  min-height: 24px;
}

.labelContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 4px;
}

.toggleButton {
  color: #3f71e0;
  font-size: 12px;
}

.toggleButton:hover {
  color: #1a49b0;
}

.toggleButton:active,
.toggleButton:visited,
.toggleButton:focus {
  color: #133683;
}

.commentsExpanded {
  border-top: 1px solid #c7c7c7;
}

.adminToggleButton,
.adminToggleButton:hover,
.adminToggleButton:active,
.adminToggleButton:visited,
.adminToggleButton:focus {
  font-size: 12px;
}
