.ellipsisButtonEnabled {
  background-color: #3f71e0;
}

.ellipsisButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ellipsisButton svg {
  width: 24px;
  height: 24px;
}

.ellipsisButton:hover {
  background-color: #3f71e0;
}

.ellipsisButton:hover svg {
  color: white;
}

.ellipsisButton span {
  margin-top: 3px;
}

.ellipsisButtonEnabled:hover,
.ellipsisButtonEnabled:focus {
  background-color: #3f71e0;
}
.ellipsisButtonEnabled svg {
  color: white;
}

.siteDetails {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
  width: 100%;
  margin-bottom: 15px;
  border-radius: 4px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 5px 5px 5px 10px;
}

.siteDetailsDeleteButton {
  float: right;
  background: transparent;
}

.siteDetailsDeleteButton:hover,
.siteDetailsDeleteButton:focus,
.siteDetailsDeleteButton:active {
  background: transparent;
}

.urlInput {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.urlFormItem :global(.ant-form-item-explain) {
  margin-top: 4px !important;
}

.addAnotherUrlButton {
  padding-left: 0;
  color: #3f71e0;
  margin-bottom: 16px;
}

.addMargin {
  margin-top: 4px;
}

.inputsOuter {
  position: relative;
}

.inputsContainer {
  overflow-y: auto;
  max-height: 210px;
  padding-top: 2px;
}

.overlay {
  pointer-events: none;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 20%;
  width: 100%;
  background: linear-gradient(to bottom, #0000, #fff);
  z-index: 1;
}

.urlInput :global(.ant-form-item-extra) {
  color: #faad14;
  margin-top: 5px;
}

.urlInput
  :global(.ant-form-item-has-warning
    .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
    .ant-select-selector) {
  border: 1px solid transparent !important;
  box-shadow: none !important;
}

.urlInput
  :global(.ant-form-item-has-warning
    .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open
    .ant-select-selector) {
  border: 1px solid transparent !important;
  box-shadow: none !important;
}

.urlInput :global(.ant-form-item) {
  min-width: 92%;
}
