.tabHeader {
  font-weight: 400;
  color: #616161;
  font-size: 16.5px;
  letter-spacing: 0.7px;
  padding: 17px 0 12px 0;
}

.accountName {
  padding: 5px 0 5px 10px;
  width: 75%;
  height: 40px;
}

.saveButton {
  height: 40px;
  letter-spacing: 0.5px;
  font-weight: 100;
  margin: 10px 0 0 0;
  padding: 0 25px;
}

label {
  margin: 0;
}

.apiBox {
  display: flex;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  justify-content: space-between;
  width: 55%;
}

.copy {
  display: block;
  margin: auto 0;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 0.6px;
  padding-right: 20px;
  color: #3f71e0;
  cursor: pointer;
}

.apiInput {
  height: 40px;
  font-family: 'Courier New', monospace !important;
  font-weight: 100;
  letter-spacing: 0.7px;
  font-size: 15px;
  border: none;
  width: 100%;
  padding: 4px 0 4px 11px;
  cursor: default;
  max-width: 225px;
}

.apiInput:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.authToken {
  font-weight: 400;
  letter-spacing: 0.7px;
}

.apiNote {
  padding: 20px 0 0 0;
  display: flex;
}

.apiDocs {
  color: #3f71e0;
}

.iconBox {
  max-width: 25px;
  display: flex;
  width: 100%;
  justify-content: center;
}

.abnVat {
  width: 75%;
  height: 40px;
  padding: 5px 0 5px 10px;
}

.apiBox {
  min-width: 300px;
}

.formButton {
  margin: 30px 0 0 0;
}

.ssoContainer {
  display: flex;
  background-color: #ececf2;
  border-radius: 5px;
  padding: 15px;
}

.ssoUpgradeImage {
  background-image: url('./images/upgrade.png');
  position: relative;
  width: 80px;
  height: 22px;
  background-size: 80px;
  display: inline-block;
  top: 5px;
}

.ssoText1 {
  font-weight: bold;
}

.ssoImageContainer {
  width: 50%;
}

.ssoImage {
  background-image: url('./images/org-url.png');
  position: relative;
  height: 170px;
  width: 250px;
  background-size: 250px;
}

.exportContainer {
  display: flex;
  background-color: #ececf2;
  border-radius: 5px;
  padding: 15px;
  margin: 15px 0 15px 0;
}

.checkCircle {
  color: #00c87b;
  position: relative;
  top: 3px;
}

.upgradeArrow {
  margin: 5px 5px 5px 0;
  width: 15px;
  top: 10px;
  position: relative;
}

.orgContainer {
  line-height: 30px;
}

.orgInput {
  max-width: 320px;
  margin-left: 15px;
}

.orgInput > * > *:last-child {
  padding: 0;
}

.copyButton {
  background-color: #fff;
  height: 30px;
  display: block;
  margin: auto 0;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 0.6px;
  color: #3f71e0;
  cursor: pointer;
  border-radius: 0px 6px 6px 0px;
}

:global(.ant-input-group-addon) {
  background-color: #fff;
}

@media (max-width: 991px) {
  .ssoContainer {
    flex-direction: column;
  }

  .apiBox {
    min-width: 100%;
  }

  .ssoImageContainer {
    width: 100%;
    overflow: scroll;
  }
}

.exportOptions {
  margin-top: 10px;
  margin-bottom: 5px;
}
