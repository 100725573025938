.columnHeader {
  color: #848484;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 900;
  vertical-align: middle;
  background: #d9e7f4;
  position: relative;
  z-index: 3;
  height: 45px;
  flex-shrink: 0;
  border-radius: 15px 15px 0 0;
}

.blueHeader {
  background-color: #6aa0c3;
  color: white;
  border-right: 1px solid #6aa0c3;
}

.redHeader {
  background-color: #d96148;
  color: white;
  border-right: 1px solid #d96148;
}

.greenHeader {
  background-color: #56b185;
  color: white;
  border-right: 1px solid #56b185;
}

.orangeHeader {
  background-color: #f59064;
  color: white;
  border-right: 1px solid #f59064;
}

.whiteHeader {
  background-color: var(--colorBgAccent);
  color: var(--colorTextColumn);
}

.columnCount {
  margin-left: 10px;
  color: #000000a6;
  text-align: center;
  background-color: #f5f5f5;
  border: solid 1px #d9d9d9;
  height: 22px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-weight: 300;
  line-height: 20px;
  padding: 0 2px;
}

.countInner {
  min-width: 18px;
}

.actionText {
  margin-top: 2px;
}

.columnHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.draggableHeader {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
}

.draggableHeader:active {
  cursor: grabbing;
}

.columnHeaderInfo {
  display: flex;
  max-width: 85%;
}

.columnNameDisplay {
  text-transform: uppercase;
  border-radius: 3px;
  padding: 2px 5px 0 5px;
  cursor: text;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.draggableHeader .columnNameDisplay:hover {
  box-shadow: 0px 0px 3px #d9d9d9;
}

.columnNameInput {
  border: none;
  height: 24px;
  padding: 0 0 0 5px;
  max-width: calc(100% - 40px);
}

.columnNameInput:focus {
  border: none;
}

.columnIcon {
  min-width: 20px;
  min-height: 20px;
  stroke: #495866;
  margin-left: 10px;
}

.columnIcon:hover {
  stroke: #3f71e0;
  cursor: pointer;
}

.actionBox {
  display: flex;
  align-items: center;
  width: 146px;
}

.actionIcon {
  width: 18px;
  height: 18px;
  stroke: #848484;
  margin-right: 10px;
}

.unreadIcon {
  margin-right: 12px;
}

.confirmArchive {
  color: #d0021b;
  stroke: #d0021b;
}

.columnActionsColor {
  position: relative;
  width: 16px;
  height: 16px;
  padding: 0;
  display: inline-block;
  border-radius: 100%;
  opacity: 0.7;
  text-indent: -99999px;
  margin: 0 2px;
  cursor: pointer;
}

.blue {
  background-color: rgba(106, 160, 195, 1);
}

.red {
  background-color: rgba(217, 97, 72, 1);
}

.green {
  background-color: rgba(86, 177, 133, 1);
}

.orange {
  background-color: rgba(245, 144, 100, 1);
}

.white {
  background-color: #ffffff;
  border: 1px solid #848484;
}

.columnActionsColor:hover {
  opacity: 1;
}

.whiteStrokeColor {
  stroke: white;
}

.inputAndControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.saveButton {
  margin-left: 10px;
}

.dummyHeader {
  width: 250px;
}

.multiSelectMenuItem {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px;
  color: #3f71e0;
}

.multiSelectMenuContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.multiSelectMenuItem:hover {
  cursor: pointer;
}

.multiSelectMenuItemIcon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  stroke: #3f71e0;
}
