.formItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 4px 0;
  border-bottom: 1px solid #dbe3e6;
  width: 100%;
  min-height: 24px;
  position: relative;
}

.formItem :global(.ant-legacy-form-item-control-wrapper) {
  width: calc(100% - 90px);
  flex-basis: auto;
}

.formItem :global(.ant-legacy-form-item-control) {
  line-height: 1em;
}

@media (max-width: 575px) {
  .formItem :global(.ant-legacy-form-item-label) {
    width: 80px;
    margin-right: 10px;
    flex-shrink: 0;
    flex-basis: auto;
  }
}

.flexedContainer :global(.ant-legacy-form-item-children) {
  position: relative;
}

.locationEditIcon {
  cursor: pointer;
  min-width: 14px;
  min-height: 14px;
  width: 14px;
  height: 14px;
  margin-left: 6px;
  stroke: #3f71e0;
}

.locationEditIcon:hover {
  color: #1a49b0;
}

.locationInputOuter :global(.ant-input-affix-wrapper-focused),
.locationInputOuter :global(.ant-input-affix-wrapper):hover,
.locationInputOuter :global(.ant-input-affix-wrapper-focused):hover {
  border-color: #3f71e0;
  box-shadow: 0 0 0 2px rgba(63, 113, 224, 0.2);
}

.locationInputOuter {
  align-items: center;
  width: 100%;
}

.locationInput input {
  font-size: 13px;
  padding: 4px 30px 4px 0px !important;
}

.locationInput:focus {
  border-color: #3f71e0;
  box-shadow: 0 0 0 2px rgba(63, 113, 224, 0.2);
}

.locationSuffix {
  width: 13px;
  height: 13px;
  stroke: #aaaaaa;
  cursor: pointer;
}

.locationSuffix:hover {
  stroke: #1a49b0;
}

.pinIcon {
  color: #3f71e0;
  min-width: 14px;
  min-height: 14px;
  width: 14px;
  height: 14px;
  margin: 0 3px 2px 0;
}

.locationLinkOuter {
  max-width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;
  width: 100%;
}

.locationLink {
  color: #3f71e0;
  text-decoration: none;
  font-weight: 400;
  overflow: hidden;
}

.locationLink:hover {
  color: #1a49b0;
  text-decoration: underline;
}

.locationInner {
  display: flex;
  align-items: center;
}

.taskPath {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.cancelEditLocation {
  color: #3f71e0;
}

.cancelEditLocation:hover {
  color: #1a49b0;
}

.cancelEditLocation:focus,
.cancelEditLocation:visited,
.cancelEditLocation:active {
  color: #133683;
}

.inputActions {
  padding: 5px 0 0 0;
  display: flex;
  justify-content: flex-end;
}
