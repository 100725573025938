.iframeWrapper {
  height: calc(100vh - 73px);
  width: 100%;
  background-color: var(--colorGrey4);
}

.iframeContainer {
  display: flex;
  height: 100%;
  width: 100%;
  border: none;
}

.pageHeader {
  position: sticky;
  z-index: 100;
  top: 0;
  left: 0;
  background: #ffffff;
  width: 100%;
  padding-right: 20px;
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: padding-right ease-in-out 300ms;
}

.disableInteraction {
  pointer-events: none;
}

.onboardingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.controlsContainer {
  display: none;

  @media (width > 992px) {
    display: block;
  }
}

.siteFeedbackWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--colorGrey4);
}

.buttonGroup {
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    color: var(--colorGrey3);
    cursor: pointer;
    transition: all 300ms ease-in;
    border: 1px solid var(--colorGrey5);
    padding: 3px;

    &:hover {
      background-color: var(--blueColorIcons);
      color: var(--colorTextLightSolid);
      border-color: var(--blueColorIcons);
    }
  }

  & .activeIcon {
    background-color: var(--blueColorIcons);
    color: var(--colorTextLightSolid);
    border-color: var(--blueColorIcons);
  }
}

.buttonGroup > svg:first-child {
  border-radius: 7px 0 0 7px !important;
}

.buttonGroup > svg:last-child {
  border-radius: 0 7px 7px 0 !important;
}

.buttonGroup > svg:not(:first-child):not(:last-child) {
  border-radius: 0;
  border-left: none;
  border-right: none;
}

.iframeWrapper > iframe {
  margin-inline: auto;
  background-color: var(--colorTextLightSolid);
}

.iframeWrapper:global(.smartphone) {
  & iframe {
    width: 468px;
  }
}

.iframeWrapper:global(.tablet) {
  & iframe {
    width: 768px;
  }
}

.iframeWrapper:global(.desktop) {
  & iframe {
    width: 100%;
  }
}
