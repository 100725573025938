.paginationContainer {
  min-width: 150px;
  margin-right: 8px;
  display: flex;
  align-items: center;
}

.paginationInput {
  width: 35px;
  margin: 0 10px;
}

.paginationInput input {
  text-align: center;
}

.paginationIcon {
  stroke-width: 1px;
  cursor: pointer;
  color: rgb(0, 0, 0, 0.88);
  border-radius: 4px;
}

.paginationIcon:hover {
  background-color: rgb(240, 240, 240);
}

.disabledIcon {
  stroke: rgb(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.widerInput {
  width: 45px;
}

.total {
  margin: 0 10px;
}

.loaderIcon {
  width: 20px;
  height: 20px;
}

.overlay {
  max-height: 200px;
  overflow: auto;
}

@media (max-width: 600px) {
  :global(.isPdf.sidebarOpen) .paginationContainer {
    min-width: unset;
    font-size: 12px;
  }

  :global(.isPdf.sidebarOpen) .paginationIcon {
    width: 20px;
    height: 20px;
  }

  :global(.isPdf.sidebarOpen) .paginationInput {
    font-size: 12px;
    width: 30px;
    margin: 0 7px 0 2px;
    padding-left: 7px;
  }

  :global(.isPdf.sidebarOpen) .total {
    margin: 0 4px 0 6px;
  }
}

@media (max-width: 600px) {
  :global(.isPdf.sidebarOpen) .paginationContainer {
    min-width: unset;
    font-size: 12px;
  }

  :global(.isPdf.sidebarOpen) .paginationIcon {
    width: 20px;
    height: 20px;
  }

  :global(.isPdf.sidebarOpen) .paginationInput {
    font-size: 12px;
    width: 30px;
    margin: 0 7px 0 2px;
    padding-left: 7px;
  }

  :global(.isPdf.sidebarOpen) .total {
    margin: 0 4px 0 6px;
  }
}
