
.container {
  height: 475px;
}

.title {
  color: #19222a;
  font-size: 24px;
  font-weight: 900;
  text-align: center;
  margin: 0;
}

.form {
  margin: 20px 0;
}

.center {
  text-align: center;
}

.orgUrl {
  border: #AAAAAA;
  color: #AAAAAA;
  margin-bottom: 0;
}

.orgUrl :global(.ant-input-group-addon) {
  color: #AAAAAA;
}

.submit {
  margin: 15px 0;
  background-color: #3f71e0;
}

.submit:hover {
  background-color: #1A49B0;
}

.submit:visited {
  background-color: #1A49B0;
}

.submit:focus {
  background-color: #1A49B0;
}

.submit:disabled {
  background-color: #3f71e0;
}

.submit:disabled:hover {
  background-color: #1A49B0;
}

.intro {
  text-align: center;
  color: #19222a;
  margin: 15px 0 0 0;
}

.intro strong {
  font-weight: 600;
}

.successIcon {
  width: 50px;
  height: 50px;
}

.success {
  text-align: center;
}

.successIcon {
  margin: 0 0 15px 0;
}

.closeCopy {
  margin: 15px 0 0 0;
}

.continueButton {
  margin: 20px 0;
}

.footerImage {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: 0 0 15px 15px;
  bottom: 0;
}

.form :global(.ant-form-item-explain) {
  margin-top: 3px;
  margin-bottom: 5px;
  line-height: 1.3;
}

.form :global(.ant-form-item-extra) {
  margin-top: 3px;
  margin-bottom: 5px;
  line-height: 1.3;
}

.form :global(.ant-form-item-explain-error) {
  float: right;
}

.form :global(.ant-form-item-has-error .ant-input-group-addon) {
  border-color: #CACDCE;
}

.upgradeMessaging {
  display: inline-flex;
  background-color: var(--colorBgBase) !important;
  top: 120px;
  left: -30px;
  padding: 11px;
  background-color: #FFF;
  border-radius: 15px;
}

.findOutMoreContainer {
  padding-left: 15px;
  margin-bottom: 0;
}

.findOutMore {
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
  color: #9E29C2 !important;
}

.errorMessage {
  margin-top: 20px;
}
