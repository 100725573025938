.verticalField {
  max-width: 120px;
}

.form :global(.ant-radio-group) {
  width: 100%;
}

.form :global(.ant-radio) {
  margin: 0 20px 0 12px;
}

.form :global(.ant-radio-wrapper) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-radius: 5px;
  border: 1.5px solid rgba(0, 0, 0, 0.15);
  margin: 0 0 8px 0;
}

.form label:last-child {
  margin-bottom: 15px;
}

.form :global(.ant-radio-wrapper-disabled) {
  background-color: #eef1f3;
}

.form :global(.ant-radio-wrapper-checked) {
  border-color: #3f71e0;
}

.radioInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.radioInner p {
  max-width: 220px;
}

.radioInner h3 {
  font-size: 14px;
  font-weight: 600;
}

.radioImage {
  width: 120px;
  height: 110px;
  margin-left: 26px;
}

.form :global(.ant-radio-wrapper) span:not(:global(.ant-radio)) {
  padding-left: 0;
  padding-right: 0;
}

.newAlert :global(.ant-alert-icon) {
  font-size: 18px;
  color: #3f71e0;
}

.newAlert :global(.ant-alert-message) {
  font-size: 14px;
  font-weight: 600;
}

.form :global(.ant-radio-wrapper-checked) :global(.ant-radio-inner) {
  border-color: #3f71e0;
}

.form :global(.ant-radio-wrapper-checked) :global(.ant-radio-inner)::after {
  background-color: #3f71e0;
}

.form :global(.ant-radio-input):focus + :global(.ant-radio-inner) {
  box-shadow: 0 0 0 3px rgba(63, 113, 224, 0.08);
}

.isPublicAlert {
  margin-top: 10px;
}

.emoji {
  font-family: inherit;
  margin-right: 5px;
}

.readHere {
  color: #3f71e0;
}
