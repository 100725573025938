.container :global(.ant-mention-wrapper) {
  transition: ease 0.3s all;
}

.container {
  margin-bottom: 1em;
}

.actions {
  margin-top: 20px;
  transform: translateY(0px);
  transition: all 0.1s ease;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: all;
}

.mentionWrapper {
  position: relative;
}

:global(.rightToLeft) .mentionWrapper textarea {
  direction: rtl;
}

.count,
.submissionShortcut {
  display: none;
  font-size: 11px;
  z-index: 10;
  line-height: 1;
  background: transparent;
  text-align: right;
  padding: 0.4em;
  border-radius: 0 0 5px 5px;
}

.footer {
  display: flex;
  position: absolute;
  top: -20px;
  right: 0px;
  width: 100%;
}

.submissionShortcut {
  text-align: left;
  align-items: center;
}

.active .count {
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
}

.active .count,
.active .submissionShortcut {
  display: flex;
}

.active :global(.ant-mention-editor-wrapper) {
  border-bottom: 20px solid transparent;
}

.deleteIcon {
  width: 16px;
  height: 16px;
}

.delete span {
  margin: auto;
}

.delete {
  line-height: 1em;
  padding-right: 0;
}
.delete,
.cancel {
  color: #3f71e0;
}

.delete:hover,
.cancel:hover {
  color: #1449b0;
}

.delete:focus,
.delete:active,
.delete:visited,
.cancel:focus,
.cancel:active,
.cancel:visited {
  color: #133683;
}

.delete::before {
  display: none !important;
}

.active :global(.react-mentions__highlighter),
.active :global(.react-mentions__input) {
  padding-bottom: 2em;
}

.shortcutShown :global(.react-mentions__highlighter),
.shortcutShown :global(.react-mentions__input) {
  padding-bottom: 4em;
}

.privateToggle {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.visibleTo {
  color: #ccc;
  font-weight: 500;
  padding: 0 5px;
}

.isPrivate {
  color: #3f71e0;
}

.switchIcon {
  width: 14px;
  height: 20px;
}

.eyeIcon {
  margin-bottom: 2px;
  width: 18px;
  height: 18px;
  stroke: #ccc;
}

.activeEyeIcon {
  stroke: #3f71e0;
}

.count:hover {
  cursor: text;
}

.roundedButtonOverride {
  border-radius: 20px;
}
