.overlay {
  width: 400px;
  min-height: 245px;
}

@media (max-width: 900px) {
  .overlay {
    max-width: 90vw;
  }
}

.overlayWrapper {
  box-shadow: 0 10px 10px 0 rgba(0,57,128,0.2);
  background-color: white;
  border-radius: 5px;
  overflow: auto;
  padding: 5px;
  border: 1px solid #D9D9D9;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
}

.optionsContainer {
  height: 180px;
  max-height: 180px;
  overflow-y: scroll;
}

.option,
.noResults {
  display: flex;
  align-items: center;
  padding: 7px 10px;
  background-color: #f1f1f1;
  color: #787878;
  border-top: 1px solid #d9d9d9;
}

.option {
  position: relative;
  cursor: pointer;
}

.option:last-child {
  border-bottom: 1px solid #d9d9d9;
}

.secondOption {
  background-color: white;
}

.withUpgrade.firstOption {
  margin-top: -46px;
}

.withUpgrade.lastOption {
  margin-bottom: 46px;
}

.avatarContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 0 1px, rgba(0, 0, 0, 0.2) 0 1px 3px;
  margin-right: 10px;
}

.checkIcon {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 9px;
  right: 10px;
  color: #161f27;
}

.tag :global(.ant-menu-title-content) {
  display: flex;
  margin-bottom: 4px;
  border: 1px solid #D9D9D9;
  border-radius: 2px;
  background-color: #F5F5F5;
  padding: 4px;
  font-size: 14px;
  line-height: 16px;
}

.assigneeTag {
  max-width: 175px;
}

.assigneeTag span {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.tag .userIcon,
.tag .tagIcon {
  min-width: 14px;
  width: 14px;
  min-height: 14px;
  height: 14px;
  margin-right: 4px;
}

.tag .tagIcon {
  margin-bottom: -4px;
}

.tag .userIcon {
  margin-bottom: -2px;
}

.tag .closeIcon {
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.tag .tagContainer {
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
}

.tag .tagLabel {
  max-width: 145px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.tagsAndSearch {
  display: flex;
  flex-wrap: wrap;
  max-height: 150px;
  margin-bottom: 1px;
  column-gap: 4px;
  row-gap: 4px;
  overflow: auto;
  padding: 8px;
}

.searchInput {
  width: 115px;
  padding: 4px 8px;
  border: none;
  font-weight: 500 !important;
  outline: 1px solid #D9D9D9;
  border-radius: 2px;
}

.assigneeInput {
  width: 175px;
}

.searchIcon {
  width: 16px;
  height: 16px;
  color: #787878;
  margin: 0 3px 3px 0;
}
.searchInput:hover,
.searchInput:focus {
  border: none;
}

.fullWidth {
  width: 100%;
  margin-right: 0px;
  margin-bottom: 2px;
}

.resultsIcon {
  width: 48px;
  height: 48px;
  margin-bottom: 15px;
  stroke-width: 1px;
}

.noResults {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.upgradeOption {
  position: sticky;
  background-color: #edcbf5 !important;
  top: 134px;
  left: 0;
  z-index: 10;
  text-decoration: none;
}

.upgradeOption strong {
  color: #9232bc;
  font-weight: 600;
  text-decoration: underline;
  text-decoration-color: transparent;
}

.upgradeIcon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.upgradeOption .withLink {
  text-decoration: underline;
  text-decoration-color: transparent;
}

.upgradeOption strong:hover {
  text-decoration-color: #9232bc;
}
