/* The Globals here get used in admin_settings_chrome too! */

.container {
  display: flex;
  justify-content: center;
  padding: 2em;
  max-width: 100vw;
  margin: 0 auto;
  gap: 64px;
}

.content {
  flex-grow: 1;
  max-width: 790px;
}

.internalContent {
  background: #fff;
  border-radius: 15px;
  border: 2px solid #d9e7f4;
  padding: 1em 2.5em 2.5em 2.5em;
  margin-bottom: 6.25rem;
}

.sidebar {
  flex-shrink: 0;
  padding-top: 7.8em;
  max-width: 170px;
  word-break: break-word;
}

.sidebar li {
  margin-bottom: 0.4em;
}

.sidebar ul {
  margin-bottom: 1.8em;
}

.organizationLink,
.organizationLinkActive {
  color: inherit !important;
  text-decoration: none;
}

.organizationTabLink {
  color: inherit !important;
  text-decoration: none;
}

.organizationLinkActive {
  font-weight: bold;
}

.sidebar h3 {
  font-size: 1rem;
}

.heading {
  font-size: 2rem !important;
  font-weight: bold !important;
  margin: 0.2em 0 0.6em 0;
}

.submenu {
  font-size: 1rem;
}

.submenu li {
  margin: 0 0 0.875rem 0;
}

.submenuHeading {
  text-transform: uppercase;
  color: #3f71e0;
  font-size: 0.875rem !important;
  font-weight: bold;
  margin: 0 0 1em 0;
}

.lock {
  opacity: 0.7;
  width: 14px;
  height: 14px;
  margin-left: 10px;
  margin-top: 3px;
}

@media (max-width: 767px) {
  .container {
    flex-direction: column;
    gap: 0;
  }

  .sidebar {
    padding-top: 0;
  }

  .content {
    max-width: 100%;
  }
}

@media (min-width: 767px) and (max-width: 991px) {
  .content {
    max-width: 500px;
  }
}
