.card {
    display: block;
    border-radius: 4px;
    font-weight: 400;
    margin: 6px 12px 6px 12px;
    padding: 8px;
}

.safariCard {
    margin: 6px 12px 6px 12px;
}

.alertTitle {
    margin-bottom: 4px;
    padding-top: 4px;
    font-size: 14px;
    font-weight: bold;
    color: #19222A;
    display: flex;
    align-items: center;
    gap: 4px;
}

.alertTitleRed {
    color: #d46060;
}

.checkCircle {
    height: 16px;
    width: 16px;
    fill: #52C41A;
    color: white;
}

.alertDescription {
    margin-bottom: 0;
    font-size: 12px;
}

.alertIcon {
    display: flex;
    align-items: center;
    position: absolute;
    top: 12px;
    right: 8px;
    cursor: pointer;
}

.alertIconBlue {
    color: #3b6ed8;
    display: flex;
    align-items: center;
}

.alertIconRed {
    color: #d46060;
    display: flex;
    align-items: center;
}

.alertSvg {
    height: 15px;
    width: 15px;
    margin-left: 4px;
}

.verticalCenter {
    display: block;
}