.loaderIcon path {
  animation-timing-function: pathar;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
}

.loaderIcon path:nth-child(1) {
  stroke: #ffffff;
  animation-name: spin1;
}
.loaderIcon path:nth-child(3) {
  stroke: #e6e6e6;
  animation-name: spin3;
}
.loaderIcon path:nth-child(5) {
  stroke: #d9d9d9;
  animation-name: spin5;
}
.loaderIcon path:nth-child(7) {
  stroke: #cccccc;
  animation-name: spin7;
}
.loaderIcon path:nth-child(2) {
  stroke: #bfbfbf;
  animation-name: spin2;
}
.loaderIcon path:nth-child(4) {
  stroke: #b3b3b3;
  animation-name: spin4;
}
.loaderIcon path:nth-child(6) {
  stroke: #a6a6a6;
  animation-name: spin6;
}
.loaderIcon path:nth-child(8) {
  stroke: #999999;
  animation-name: spin8;
}

@keyframes spin1 {
  0% {
    stroke: #ffffff;
  }
  12.5% {
    stroke: #e6e6e6;
  }
  25% {
    stroke: #d9d9d9;
  }
  37.5% {
    stroke: #cccccc;
  }
  50% {
    stroke: #bfbfbf;
  }
  62.5% {
    stroke: #b3b3b3;
  }
  75% {
    stroke: #a6a6a6;
  }
  87.5% {
    stroke: #999999;
  }
  100% {
    stroke: #ffffff;
  }
}
@keyframes spin3 {
  0% {
    stroke: #e6e6e6;
  }
  12.5% {
    stroke: #d9d9d9;
  }
  25% {
    stroke: #cccccc;
  }
  37.5% {
    stroke: #bfbfbf;
  }
  50% {
    stroke: #b3b3b3;
  }
  62.5% {
    stroke: #a6a6a6;
  }
  75% {
    stroke: #999999;
  }
  87.5% {
    stroke: #ffffff;
  }
  100% {
    stroke: #e6e6e6;
  }
}
@keyframes spin5 {
  0% {
    stroke: #d9d9d9;
  }
  12.5% {
    stroke: #cccccc;
  }
  25% {
    stroke: #bfbfbf;
  }
  37.5% {
    stroke: #b3b3b3;
  }
  50% {
    stroke: #a6a6a6;
  }
  62.5% {
    stroke: #999999;
  }
  75% {
    stroke: #ffffff;
  }
  87.5% {
    stroke: #e6e6e6;
  }
  100% {
    stroke: #d9d9d9;
  }
}
@keyframes spin7 {
  12.5% {
    stroke: #bfbfbf;
  }
  25% {
    stroke: #b3b3b3;
  }
  37.5% {
    stroke: #a6a6a6;
  }
  50% {
    stroke: #999999;
  }
  62.5% {
    stroke: #ffffff;
  }
  75% {
    stroke: #e6e6e6;
  }
  87.5% {
    stroke: #d9d9d9;
  }
  100% {
    stroke: #cccccc;
  }
}
@keyframes spin2 {
  0% {
    stroke: #bfbfbf;
  }
  12.5% {
    stroke: #b3b3b3;
  }
  25% {
    stroke: #a6a6a6;
  }
  37.5% {
    stroke: #999999;
  }
  50% {
    stroke: #ffffff;
  }
  62.5% {
    stroke: #e6e6e6;
  }
  75% {
    stroke: #d9d9d9;
  }
  87.5% {
    stroke: #cccccc;
  }
  100% {
    stroke: #bfbfbf;
  }
}
@keyframes spin4 {
  0% {
    stroke: #b3b3b3;
  }
  12.5% {
    stroke: #a6a6a6;
  }
  25% {
    stroke: #999999;
  }
  37.5% {
    stroke: #ffffff;
  }
  50% {
    stroke: #e6e6e6;
  }
  62.5% {
    stroke: #d9d9d9;
  }
  75% {
    stroke: #cccccc;
  }
  87.5% {
    stroke: #bfbfbf;
  }
  100% {
    stroke: #b3b3b3;
  }
}
@keyframes spin6 {
  0% {
    stroke: #a6a6a6;
  }
  12.5% {
    stroke: #999999;
  }
  25% {
    stroke: #ffffff;
  }
  37.5% {
    stroke: #e6e6e6;
  }
  50% {
    stroke: #d9d9d9;
  }
  62.5% {
    stroke: #cccccc;
  }
  75% {
    stroke: #bfbfbf;
  }
  87.5% {
    stroke: #b3b3b3;
  }
  100% {
    stroke: #a6a6a6;
  }
}
@keyframes spin8 {
  0% {
    stroke: #999999;
  }
  12.5% {
    stroke: #ffffff;
  }
  25% {
    stroke: #e6e6e6;
  }
  37.5% {
    stroke: #d9d9d9;
  }
  50% {
    stroke: #cccccc;
  }
  62.5% {
    stroke: #bfbfbf;
  }
  75% {
    stroke: #b3b3b3;
  }
  87.5% {
    stroke: #a6a6a6;
  }
  100% {
    stroke: #999999;
  }
}
