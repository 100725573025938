.controlsContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.controlsContainer button {
  font-weight: 800;
}

.zoomButton,
.shareButton,
.originalSize,
.fitToPage {
  border-radius: 7px;
  border: 1px solid #d9d9d9;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
}

.zoomButton {
  width: fit-content;
  max-width: 112px;
  transition: width ease-in-out 600ms;
  color: #848484;
  border-radius: 7px 0 0 7px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.zoomButton:last-child {
  border-radius: 0 7px 7px 0 !important;
}

.originalSize,
.buttonGroup,
.fitToPage {
  color: #848484;
  margin-right: 5px;
}

.activeItem {
  color: #3f71e0;
}

.zoomButton:focus {
  color: #848484;
  background-color: white;
  border: 1px solid #d9d9d9;
}

.zoomButton:hover,
.zoomButton:focus:hover,
.originalSize:hover,
.originalSize:focus,
.fitToPage:hover,
.fitToPage:focus {
  color: #1a49b0;
  border-color: #1a49b0;
}

.shareButton:hover,
.shareButton:focus {
  border-color: #d9d9d9;
}

.zoomIcon,
.shareIcon {
  width: 14px;
  height: 14px;
}

.shareIcon {
  margin-right: 4px;
  margin-bottom: 2px;
}

.activeZoom {
  color: #3f71e0;
  border-color: #3f71e0;
}

@media (max-width: 640px) {
  :global(.isPdf.sidebarOpen) .shareButton,
  :global(.isPdf.sidebarOpen) .originalSize,
  :global(.isPdf.sidebarOpen) .fitToPage {
    font-size: 12px;
  }
}

@media (max-width: 560px) {
  :global(.isPdf.sidebarOpen) .shareButton,
  :global(.isPdf.sidebarOpen) .originalSize,
  :global(.isPdf.sidebarOpen) .fitToPage {
    font-size: 12px;
  }
}

@media (max-width: 535px) and (min-width: 470px) {
  :global(.isPdf.sidebarOpen) .shareButton span {
    display: none;
  }

  :global(.isPdf.sidebarOpen) .shareIcon {
    margin-right: 0px;
  }
}

@media (max-width: 500px) and (min-width: 470px) {
  :global(.isPdf.sidebarOpen) .shareButton,
  :global(.isPdf.sidebarOpen) .originalSize,
  :global(.isPdf.sidebarOpen) .fitToPage {
    font-size: 10px;
  }
}
