.whereTo {
  padding-left: 3px;
  display: block;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-transform: capitalize;
}

.moveMenu {
  min-width: 100%;
  max-height: 328px;
  height: fit-content;
  overflow-y: scroll;
}

.moveMenuSidebar {
  max-width: 318px;
}

.dropdownMenuItem {
  max-width: 100%;
}

.itemName {
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.markAsButton {
  width: auto;
  min-width: 0;
}

.markAsButton button {
  border-radius: 20px;
}

.markAsButton button:first-child {
  /* 100% of container - 30px of button arrow */
  max-width: calc(100% - 30px);
}

.markAsButton button:first-child span {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.markAsDropdown {
  max-width: 150px;
  max-height: 200px;
  overflow: auto;
}

.markAsDropdown li span {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menuToggleIcon {
  min-width: 20px;
  min-height: 20px;
}

.overlay {
  max-width: 100%;
}

.overlay * {
  overflow-x: hidden;
}

.adminTooltipOverlay,
.tooltipOverlay {
  visibility: hidden;
}

@media (min-width: 951px) and (max-width: 1350px) {
  .adminMarkAsButton {
    max-width: calc(100% - 160px);
  }
  .adminMarkAsButton button:first-child {
    font-size: 11px !important;
  }
  .adminTooltipOverlay {
    visibility: visible;
  }
}
