.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #ffffff;
  flex-grow: 1;
  background-image: url('./images/bike.png'), url('./images/success_lane.png'),
    url('./images/road.png');
  background-repeat: no-repeat, no-repeat, repeat-x;
  background-size: 565px, 267px, 608px;
  background-position: bottom left, bottom right, bottom center;
  font-family: 'Inter', sans-serif;
}

.container :global(.ant-legacy-form-item-label) {
  font-family: 'Inter', sans-serif;
}

@media (max-width: 830px) {
  .container {
    background-image: url('./images/bike.png'), none, url('./images/road.png');
  }
}

@media (max-width: 750px) {
  .container {
    background-size: 395px, 267px, 425px;
  }
}
