.outerContainer {
  width: 30vw;
}

.info {
  line-height: 24px;
  padding-bottom: 20px;
}

.install {
  margin: 10px 0 20px 0;
}

.title {
  margin: 0;
  font-weight: 700;
  line-height: 20px;
  font-size: 13px;
}

.hintOuter {
  max-width: 80%;
}

.wordPressTabOuter
  :global(.ant-steps-item-custom
    > .ant-steps-item-container
    > .ant-steps-item-icon) {
  background: rgb(24, 144, 255);
  border-color: rgb(24, 144, 255);
}

.wordPressTabOuter :global(.ant-steps-item-description) {
  padding-top: 10px;
  max-width: 80%;
}

.wordPressTabOuter :global(.ant-alert-description p) {
  margin-bottom: 0;
}

.wordPressTabOuter
  :global(.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon) {
  color: #fff;
  font-size: 16px;
}
