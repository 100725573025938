.selectedProjectsList .selectedProject {
  max-width: 200px;
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.selectedProjectsList {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.moveToFolderDropdown {
  border-radius: 8px;
}

.removeFolderIcon,
.disableIcon,
.enableIcon,
.deleteIcon {
  width: 14px;
  height: 14px;
}

.deleteIcon {
  color: var(--colorSeverityCritical);
}

.enableIcon {
  color: var(--colorSuccess);
}

.disableIcon {
  color: #ccc;
}

.closeModal {
  margin-left: 30px;
}

.selectedProjects {
  min-width: 150px;
}

.projectFolderBar {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
  background-color: white;
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0 10px 10px 0 rgba(0, 57, 128, 0.2);
  bottom: 40px;
  opacity: 1;
  z-index: 100;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid #d5d1d1;
}

.enableMenuItem {
  color: var(--colorSuccess);
}

.disbleMenuItem {
  color: #ccc;
}

.deleteMenuItem {
  color: var(--colorSeverityCritical);
}

.moveToFolderButton,
.updateButton {
  margin-left: 1px;
}
