.dragWrapper {
  position: absolute;
  top: 18px;
  left: 18px;
  cursor: grab;
  width: 524px;
  display: flex;
  height: 18px;
  align-items: center;
  z-index: 1;
}

.isDragging {
  cursor: grabbing;
}

.dragIcon {
  color: #ababab;
}

.projectName {
  margin-left: 10px;
  line-height: 18px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  color: #ababab;
}

.noDragging {
  display: none;
}

@media (min-width: 601px) {
  .projectName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 280px;
  }
}

@media (max-width: 600px) {
  .dragWrapper {
    display: none;
  }
  .noDragging {
    position: absolute;
    top: 15px;
    left: 32px;
    display: inline;
    margin-left: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: calc(100% - 76px);
    display: block;
    white-space: nowrap;
  }
}
