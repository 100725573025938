.container {
  display: flex;
  width: 70px;
}

.topFilterList {
  background: #363d47;
  width: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
}

.listContainer {
  display: flex;
  justify-content: center;
  width: 60px;
  padding: 2px;
  background: #24282f;
  border-radius: 3px;
}

.listInner {
  background: #24282f;
  border-radius: 2px;
}

.filterHead {
  width: 56px;
  background: url('../../../../assets/images/sidebar-sprites.png');
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  opacity: 0.3;
  cursor: pointer;
  border-radius: 2px;
  padding: 8px 0 9px 0;
}

.filterHead:hover {
  opacity: 1;
}

.listInner .my {
  background-position-x: -147px;
  background-position-y: -136px;
}

.listInner .all {
  background-position-x: -247px;
  background-position-y: -136px;
}

.my,
.all {
  padding: unset;
  padding-top: 29px;
  height: 50px;
}

.allListItem {
  margin-top: 5px;
}

.filterName {
  font-weight: 300;
  font-size: 9.7px;
  text-transform: uppercase;
  color: white;
  margin-bottom: 2px;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}

.activeFilter {
  background: #3f8ec8;
}

.activeFilter .filterHead,
.expandedFilter .filterHead {
  opacity: 1;
}

.sub {
  opacity: 1;
}

.sub {
  background: transparent;
  justify-content: space-around;
}

.listInner:not(.activeFilter) .sub:hover {
  background: #39414d;
}

.sub .filterName {
  display: -webkit-box;
  opacity: 1;
  max-width: 52px;
  line-height: 12px;
  max-height: 24px;
  overflow: hidden;
  margin: 0 auto;
  word-wrap: break-word;
  hyphens: auto;
  -webkit-line-clamp: 2;
}

.count {
  display: block;
  border-radius: 8px;
  margin: 2px auto 2px;
  width: 29px;
  text-align: center;
  padding: 3px 0;
  background: #181c20;
  color: white;
  font-weight: 700;
  font-size: 10px;
  line-height: 1em;
}

.activeCount {
  background: #15679b;
}

.filterList {
  border-radius: 3px;
}

.expandedOuter {
  border-radius: 3px 3px 0 0;
}

@media (max-device-width: 770px) and (max-resolution: 95dpi),
  (max-device-width: 450px) and (max-resolution: 336dpi) {
  .container {
    display: none;
  }
}

.lock {
  background: unset;
  padding: unset;
  height: 50px;
}

.lockListItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 5px;
}

.lockIcon {
  width: 28px;
  height: 28px;
  stroke: #fff;
  margin: 3px auto 0px auto;
}
