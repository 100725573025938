.multiSelectFilter {
  display: block;
}

@media (max-width: 520px) {
  .multiSelect {
    width: 180px;
  }
}

@media (min-width: 520px) {
  .multiSelect {
    width: 350px;
  }
}

.multiSelectFilter :global(.ant-select-selector) {
  max-height: 60px;
  overflow-y: auto;
}

.error {
  height: 60px;
  display: flex;
}

.retryButton {
  margin: auto;
}
