.iframe {
  display: block;
  width: 100%;
  aspect-ratio: 1 / 1;
  border: none;
  max-height: 100vh;
  margin-block: auto;
}

.mobileWraper {
  display: none;
}

.hideOnMobile {
  display: block;
}

@media (max-width: 990px) {
  .iframe {
    display: none;
  }
  .mobileWraper {
    display: flex;
  }
  .registerWrapper {
    flex-direction: column;
  }
  .hideOnMobile {
    display: none;
  }
}

.backToWebsite a {
  color: #ffffff;
  text-align: center;
}

.mobileWraper {
  align-items: center;
  background-color: #3f71e0;
  padding: 20px;
}

.mobileWraper a {
  font-weight: bold;
}

.mobileWraper .backToWebsite {
  margin-left: auto;
  padding-right: 20px;
}
