.generalForm :global(.ant-form-item-label label) {
  height: 20px;
  color: #666666;
  font-family: inherit;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 20px;
}

.generalForm :global(.ant-form-item) {
  margin-bottom: 16px;
}

.switchWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.switch {
  margin-right: 10px;
}

.switchWrapper :global(.ant-form-item) {
  flex-direction: row-reverse !important;
  flex-flow: nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 0;
}

.switchWrapper :global(.ant-row.ant-form-item-row) {
  flex-direction: row-reverse !important;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 0;
}

@media only screen and (min-width: 992px) {
  .switchWrapper :global(.ant-row.ant-form-item-row) {
    flex-flow: nowrap;
  }
}

.switchWrapper :global(.ant-col.ant-form-item-control) {
  width: max-content;
}

.switchWrapper :global(.ant-form-item-label label) {
  font-weight: 300 !important;
  margin-bottom: 0;
}

.switchWrapper :global(.ant-form-item-control) {
  flex-grow: unset;
}

.ghost {
  border-color: transparent;
  background-color: transparent;
  margin-left: 1em;
  box-shadow: none;
}

.actions {
  display: flex;
  justify-content: left;
  margin-top: 16px;
}

.container :global(.ant-legacy-form-item-required::before) {
  display: none;
}

.button {
  padding: 0 30px;
}

.label {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  cursor: default;
}

.spanLabel {
  cursor: default;
}

.guestCta {
  display: block;
}

.labelStyle p {
  margin: 8px 0px;
}

.labelStyle label {
  font-weight: 900 !important;
  margin-bottom: 5px;
  color: #19222a;
}

.blueWrapper {
  background-color: var(--colorInfoBg);
  border: 1px solid var(--colorInfoBorder);
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
  margin-block: 8px;

  & p {
    margin: 0;
    color: var(--alertColorText);
  }
}

.feedbackUrlNote {
  color: #373737;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 24px;
}

.nameInput {
  font-weight: 400;
}

.helpContainer {
  margin-bottom: 8px;
  display: flex;
  color: #3f71e0;
  text-decoration: none;
  font-weight: 400;
  column-gap: 8px;
}

.helpContainer a:first-child {
  margin-right: 8px;
  display: flex;
  margin-bottom: 1px;
  align-items: center;
}

.helpContainer a,
.helpContainer a:hover,
.helpContainer a:visited,
.helpContainer a:active {
  color: #3f71e0;
  text-decoration: none;
}

.helpContainer a:hover {
  color: #49d8f5;
}

.projectName :global(.ant-form-item-explain) {
  margin-top: 0px;
}

.ownerdropdown .spanLabel {
  margin-left: 10px;
}

