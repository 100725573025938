.detailsRadio :global(.ant-radio-button-wrapper) {
  font-size: 12.5px;
}
.detailsRadio label {
  color: #3f71e0 !important;
  background: transparent;
  border: 2px solid #3f71e0 !important;
  outline: none !important;
  box-shadow: none !important;
}

.detailsRadio label:first-child {
  border-radius: 6px 0 0 6px !important;
}
.detailsRadio label:last-child {
  border-radius: 0 6px 6px 0 !important;
}
.detailsRadio label:last-child::before {
  background-color: #3f71e0 !important;
}

.detailsRadio :global(.ant-radio-button-wrapper-checked) {
  background-color: #3f71e0 !important;
  color: white !important;
}

.detailsRadio :global(.ant-radio-button-wrapper-checked)::before {
  background-color: #3f71e0 !important;
}

.default,
.mobileView {
  margin-right: 3px;
}

.mobileView {
  display: none;
}

.detailsRadio {
  padding: 15px;
  background-color: #f5eeed;
  max-width: 340px;
  width: 100%;
}

@media (max-width: 400px) {
  .detailsRadio {
    font-size: 12px;
  }

  .detailsRadio label {
    padding: 0 6px;
  }

  .longCounts label,
  .oneLongCount label {
    padding: 0 4px;
  }
}

.integrationsLabel {
  display: flex;
  align-items: center;
}

.integrationsLabel > svg {
  margin-left: 6px;
}

.loaderIcon {
  width: 18px;
  height: 18px;
  margin-left: 5px;
}

.alertIcon circle {
  color: #faad14;
  fill: #faad14;
}

.alertIcon line {
  color: white;
}

.tooltip .refresh {
  display: flex;
  justify-content: space-between;
  min-width: 90px;
  padding: 0 0 12px 0;
}
