.switchWrapper {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-bottom: 0 !important;
}

.switchWrapper :global(.ant-form-item-control-wrapper) {
  margin-right: 1em;
}

.modalContainer :global(.ant-form-item-label label) {
  font-weight: 900;
}

.ghost {
  border-color: transparent !important;
  background-color: transparent !important;
  margin-left: 1em !important;
  box-shadow: none !important;
}

.actions {
  display: flex;
  justify-content: center;
  margin-top: 1.5em;
}

.container :global(.ant-tabs-nav::before) {
  width: fit-content;
}

.container :global(.ant-form-item-required::before) {
  display: none;
}

.modalContainer {
  max-width: 480px;
}

.modalContainer :global(.ant-modal-title) {
  padding-block: 12px;
}

.overrideTextShadow :global(.ant-tabs-tab-btn) {
  text-shadow: none !important;
}
