.antdOverrides button {
    box-shadow: none;
}

/* Override the text shadow that is applied to tabs*/
.antdOverrides p {
    margin-top: 0;
}

/* The user onboarding requires this div to be display flex*/
:global(#user_onboarding_container) .antdOverrides {
    display: flex;
    width: 100%;
}

/* required for the overflow scroll to work correctly*/
:global(#kanbanBoard) .antdOverrides {
    height: 100%
}

:global([data-tour-elem="controls"]) {
    display: flex !important;
    justify-content: flex-end !important;
    gap: 8px;
}

:global([data-tour-elem="controls"] button) {
    margin-right: 0px;
    margin-left: 0px;
}