.closeContainer {
  min-width: 30px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f5eeed;
  color: #d46060;
  cursor: pointer;
}

.positioned {
  position: absolute;
}

.whiteBackground {
  background-color: white;
}

.closeIcon {
  width: 14px;
  height: 14px;
  display: block !important;
}
