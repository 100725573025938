.navOuter {
  position: fixed;
  width: 100vw;
  z-index: 1050;
}

.navbarWrapper {
  padding: 8px 1.25rem;
  border-bottom: 2px solid var(--borderColor);
  background-color: var(--darkWhite);
  position: relative;
}

:global(.ant-breadcrumb li:last-child) {
  color: var(--colorTextBase);
}

.navbarWrapper.navHidden {
  border: none;
}

.logoTop {
  width: max-content;
  max-width: 120px;
  min-width: 32px;
  height: 32px;
  display: flex;
  filter: var(--invert-white);
}

.logoTop img {
  height: 100%;
}

.closeButton {
  position: absolute;
}

.logoOverlay {
  position: relative;
}

.logoOverlay::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
}

@media screen and (max-width: 640px) {
}

.upgradeWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.upgradeWrapper p {
  margin: 0;
}

.upgradeWrapper a {
  margin: 0;
  color: #3f71e0;
  padding-left: 6px;
  font-weight: 800;
}

.upgradeWrapper a:hover span {
  color: #3f71e0;
  text-decoration: underline;
}

.overlay :global(.ant-divider) {
  height: 3px;
  margin: 6px;
}

.mobileMenu:after {
  content: '';
  display: block;
  border: 1px solid #ececf3;
  width: 90%;
  top: 1rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.mobileMenu :global(.ant-menu-item-divider) {
  border-width: 1px;
  width: 90%;
  background-color: #ececf3;
  height: 1px;
  margin: 1rem auto;
}

.overlay :global(.ant-dropdown-menu-submenu-title),
.mobileMenu :global(.ant-menu-title-content) {
  display: flex;
}

.overlay :global(.ant-dropdown-menu-title-content > span),
.overlay :global(.ant-dropdown-menu-title-content),
.mobileMenu :global(.ant-menu-title-content) {
  display: inline-flex;
  align-items: center;
  column-gap: 12px;
}

:global(.ant-dropdown-menu-title-content) {
  font-weight: bold;
}

.mobileMenu :global(li.ant-menu-item),
.mobileMenu :global(li.ant-menu-submenu-vertical > div) {
  display: flex !important;
  align-items: center;
}

.mobileMenu :global(li.ant-menu-submenu-vertical .ant-menu-submenu-arrow) {
  display: none;
}

.overlay :global(.ant-dropdown-menu-title-content a),
.overlay :global(.ant-dropdown-menu-submenu-title a),
.mobileMenu :global(.ant-menu-title-content a),
.mobileMenu :global(.ant-menu-submenu-title a) {
  line-height: 1.5;
  color: inherit;
  padding: 0;
}

.overlay :global(.ant-dropdown-menu-title-content a:focus) {
  text-decoration: none !important;
}

.overlay :global(li.ant-dropdown-menu-item),
.overlay :global(.ant-dropdown-menu-submenu-title) {
  padding: 8px 12px;
}

.overlay :global(.ant-dropdown-menu-submenu-title svg),
.overlay
  :global(.ant-dropdown-menu-item svg)
  .mobileMenu
  :global(.ant-menu-submenu-title svg),
.mobileMenu :global(.ant-menu-item svg) {
  padding-inline: 2px;
}

.mobileMenu :global(.ant-menu-item > .ant-menu-title-content:has(.ant-btn)) {
  color: var(--navigationLink);
}

.overlay :global(.ant-dropdown-menu-title-content),
.overlay :global(.ant-dropdown-menu-title-content),
.mobileMenu :global(.ant-menu-title-content) {
  color: var(--colorTextBase);
  font-size: 14px;
  font-weight: bold;
}

.overlay :global(.ant-dropdown-menu.ant-dropdown-menu-sub) {
  max-width: 300px;
}

.overlay :global(.ant-dropdown-menu-title-content) :global(.ant-btn-link),
.mobileMenu :global(.ant-menu-title-content) :global(.ant-btn-link) {
  color: var(--navigationLink) !important;
  font-weight: bold;
  padding-inline: 0;
  display: flex;
  align-items: center;
}

.navOuter :global(.ant-popover) {
  z-index: 1050;
}

.navOuter :global(.member-class) {
  background-color: #fbbaa8;
}

.navOuter :global(.guest-class) {
  background-color: #b5e7f9;
}

.navOuter :global(.collab-class) {
  background-color: #bcdb91;
}

.navOuter :global(.collab-class) span,
.navOuter :global(.guest-class) span,
.navOuter :global(.member-class) span {
  text-transform: uppercase;
  color: var(--colorPrimary);
  font-weight: 600;
}

.greyWrapper {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 6px;
  padding: 6px 10px;
  border-radius: 100px;
  background-color: var(--colorBgAccent);
}

.greyWrapper > a:not(:global(.ant-dropdown-trigger)) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.greyWrapper svg {
  color: var(--highContrastIcons);
  transition: all 0.3s ease-in;
}

.greyWrapper > a:not(:global(.ant-dropdown-trigger)):hover svg {
  color: #0a2d50;
}

.impersonating {
  background-color: var(--colorError) !important;
}

.impersonating a,
.impersonating span,
.impersonating div {
  color: #fff !important;
}

.impersonating a {
  font-weight: 600;
  text-decoration: underline;
}

.mobileMenu {
  background-color: #ffffff;
  position: fixed;
  width: 100%;
  z-index: 1049;
  top: 3rem;
  opacity: 1;
  padding: 2rem 0 1rem 0;
  transition: all 0.25s ease-in;
}

.mobileMenu.mobileOpen {
  opacity: 1;
  overflow: hidden;
  transform: translateY(0);
  border-bottom: 2px solid #ded3d1;
}

.mobileMenu.mobileHidden {
  transform: translateY(-100%);
  opacity: 0;
}

.mobileMenu :global(> div > ul:first-of-type) {
  padding-top: 12px;
}

.mobileMenu
  :global(.ant-menu-item
    > .ant-menu-item-icon:has(+ .ant-menu-title-content > .ant-btn)),
.overlay
  :global(.ant-dropdown-menu-item
    > .ant-dropdown-menu-item-icon:has(+ .ant-dropdown-menu-title-content
      > .ant-btn)) {
  color: var(--navigationLink);
}

.navWrapper :global(.ant-dropdown > .ant-dropdown-menu) {
  background-color: var(--darkWhite);
}

.navWrapper .navButton {
  display: flex;
  align-items: center;
  height: inherit;
  gap: 8px;
  cursor: pointer;
}

.overlay :global(a:hover) {
  text-decoration: none;
}

.navWrapper :global(.ant-breadcrumb a:hover),
.navWrapper :global(.ant-breadcrumb a:focus) {
  background-color: rgba(0, 0, 0, 0.06);
  text-decoration: none;
}

.navWrapper .organizationName span {
  font-weight: bold;
  padding: 0 4px;
  color: var(--navigationLink);
  cursor: pointer;
  border-radius: 4px;
}

.navWrapper .organizationName span:hover {
  background-color: rgba(0, 0, 0, 0.06);
}

.navWrapper :global(.ant-breadcrumb .ant-breadcrumb-separator) {
  margin-inline: 0;
}

.navWrapper :global(.ant-breadcrumb li:last-of-type) {
  font-weight: normal;
}

.navWrapper :global(.ant-breadcrumb .ant-breadcrumb-separator svg) {
  display: block;
}

.breadcrumbsContainer ol {
  align-items: center;
  column-gap: 4px;
}

.overlay :global(.ant-dropdown-menu-item:hover),
:global(.ant-dropdown-menu-item:hover),
.overlay :global(.ant-dropdown-menu-submenu-title:hover) {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.06) !important;
}

:global(.ant-dropdown-menu-item:hover a),
:global(.ant-dropdown-menu-item:hover a:focus) {
  text-decoration: none;
}

.pageTitle span,
.organizationName span,
.mobileTitle span {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mobileTitle span {
  max-width: 65vw;
}

.organizationName span {
  max-width: 280px;
}

.pageTitle span {
  max-width: 300px;
}

:global(.ant-dropdown-menu-item.ant-dropdown-menu-item-disabled:hover) {
  background-color: unset !important;
}

.contentStyle {
  padding: 10px;
  list-style-type: none;
  background-color: var(--cardColor);
  background-clip: padding-box;
  border-radius: 8px;
  outline: none;
  box-shadow: 0px 10px 10px 0px rgba(0, 57, 128, 0.2);
  display: flex;
  gap: 8px;
}

.menuStyle {
  border-radius: 0px;
  box-shadow: none;
  padding: 0px;
}

@media screen and (max-width: 1100px) {
  .organizationName span,
  .pageTitle span {
    max-width: 200px;
  }
}
