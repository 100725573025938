.pickerContainer {
  font-family: 'Segoe UI', 'Noto Color Emoji', 'Apple Color Emoji', 'Emoji',
    sans-serif;
  max-width: 374px;
  min-width: 374px;
  border-radius: 8px;
}

.darkMode {
  background-color: #1b1d21;
}

.categoryIcons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 3px;
}

.categoryIcon {
  cursor: pointer;
  width: 30px;
  height: 30px;
  color: #848484;
  padding: 0px 6px 10px 6px;
  border-bottom: 2px solid transparent;
}

.darkMode .categoryIcon {
  color: #818385;
  padding: 5px 6px;
  margin-bottom: 2px;
  border-radius: 4px;
}

.categoryIcon:not(.active):hover {
  color: black;
}

.darkMode .categoryIcon:hover {
  color: #f8f8f8;
}

.categoryIcon.active {
  color: #3f71e0;
  border-color: #3f71e0;
}

.darkMode .categoryIcon.active {
  background-color: #35373b;
  color: #f8f8f8;
  border-color: transparent;
}

.searchInput {
  margin-bottom: 5px;
  padding: 6px 10px;
  width: 100%;
  border-radius: 4px;
  border-width: 1px;
  margin-top: 2px;
}

.darkMode .searchInput {
  background-color: #1b1d21;
  color: #d1d2d3;
  caret-color: #d1d2d3;
}

.darkMode .searchInput::placeholder {
  color: #d1d2d3;
}

.emojisContainer {
  overflow-y: scroll;
  max-height: 340px;
  min-height: 340px;
}

.wrappedEmojis {
  display: flex;
  flex-wrap: wrap;
}

.categoryLabel {
  text-transform: capitalize;
  font-family: 'Inter', sans-serif;
  position: sticky;
  top: -1px;
  left: 0;
  background: white;
}

.darkMode .categoryLabel {
  background: #1b1d21;
  color: #d1d2d3;
}

.emojiItem {
  padding: 0 6px;
  font-size: 27px;
  cursor: pointer;
  border-radius: 4px;
}

.emojiItem-0:hover {
  background-color: #b9e98a;
}

.emojiItem-1:hover {
  background-color: #b5e0fe;
}

.emojiItem-2:hover {
  background-color: #f9f064;
}

.emojiItem-3:hover {
  background-color: #f3c1fd;
}

.emojiItem-4:hover {
  background-color: #ffe2b0;
}

.emojiItem-5:hover {
  background-color: #e0dfff;
}

.skinToneIcon,
.skinToneButton {
  width: 16px;
  height: 16px;
  position: relative;
  border-radius: 100%;
}

.skinToneButton {
  cursor: pointer;
}

.skinTone-1 .skinToneIcon,
.skinToneButton-1 {
  background: #ffc93a;
}

.skinTone-2 .skinToneIcon,
.skinToneButton-2 {
  background: #ffdab7;
}

.skinTone-3 .skinToneIcon,
.skinToneButton-3 {
  background: #e7b98f;
}

.skinTone-4 .skinToneIcon,
.skinToneButton-4 {
  background: #c88c61;
}

.skinTone-5 .skinToneIcon,
.skinToneButton-5 {
  background: #a46134;
}

.skinTone-6 .skinToneIcon,
.skinToneButton-6 {
  background: #5d4437;
}

.skinToneItem {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
}

.skinToneIcon::after,
.skinToneButton::after {
  content: '';
  mix-blend-mode: overlay;
  background: linear-gradient(rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0));
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-radius: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: inset 0 -2px 3px #000, inset 0 1px 2px #fff;
}

.pickerUpper {
  padding: 10px 10px 0 10px;
}

.preview {
  display: flex;
  align-items: center;
}
.darkMode .previewBox {
  background-color: #3d3f43;
  border-radius: 0 0 8px 8px;
}

.previewEmoji {
  font-size: 40px;
}

.preview .name {
  font-family: 'Inter', sans-serif;
  margin-left: 10px;
}

.previewBox {
  padding: 0px 20px 0 10px;
  border-top: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.darkMode .preview {
  color: #d1d2d3;
}

.darkMode .previewBox {
  border-color: transparent;
}

.skinToneOverlay {
  background: white;
  position: absolute;
  bottom: 40px;
  right: 40px;
  cursor: pointer;
  box-shadow: 0px 0px 8px -3px;
}

.skinToneOverlay {
  border-radius: 8px;
  min-width: 140px;
  padding-inline-start: unset !important;
}

.sidebarSkinToneOverlay {
  bottom: 25px;
}

.darkMode .skinToneOverlay {
  background-color: #1b1d21;
  color: #d1d2d3;
}

.darkMode .skinToneOverlay {
  box-shadow: 0px 0px 2px 0px;
}

.darkMode .skinToneItem {
  color: #d1d2d3;
}

.hiddenSkinToneOptions {
  display: none;
}

.skinToneItem.active {
  background-color: #cccccc69;
  font-weight: 600;
}

.skinToneItem:hover {
  background-color: #cccccc69;
}

.darkMode .skinToneItem.active {
  background-color: #3d3f43;
}
