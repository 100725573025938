.usersContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px 4px 12px;
}

.usersHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  color: inherit;
  font-weight: 800;
}

.emptyUsersMessage {
  padding: 0px 8px;
}

.usersButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  color: inherit;
}

.userButton {
  padding: 4px 8px 4px 4px;
}

.userListItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.userTaskCount * {
  color: #19222a;
}

.userName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: start;
  width: 100%;
  font-weight: normal;
  color: var(--colorTextBase);
}

.navIcon {
  width: 14px;
  height: 14px;
  color: var(--blueColorIcons);
}

.avatar {
  flex: none;
}

.member-class {
  background-color: #fbbaa8;
}

.guest-class {
  background-color: #b5e7f9;
}

.collab-class {
  background-color: #bcdb91;
}

.collab-class span,
.guest-class span,
.member-class span {
  text-transform: uppercase;
  color: #153c56;
  font-weight: 600;
}

.usersInviteContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--colorPrimary);
  font-weight: 800;
}

.collapseItem > :first-child:hover {
  background: rgba(0, 0, 0, 0.06);
}

.collapseItem > :first-child:hover * {
  color: var(--colorText) !important;
}

.collapseItem > :first-child {
  color: var(--navigationLink);
  padding-left: 8px !important;
  border-radius: 12px !important;
  margin-bottom: 5px;
}
