.isCommentMention {
  cursor: text;
  z-index: 1;
  position: relative;
  word-break: break-all;
}

.mention,
.notMention {
  padding: 0px 2px;
  white-space: nowrap;
  border-radius: 2px;
}

.mention {
  background: #20c0e720 !important;
}

.isPrivateComment {
  background: #fff6d9;
}

.notOnProject,
.noUserFound {
  background: #dbdbdb;
  opacity: 0.7;
  color: #000000;
}
