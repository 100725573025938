.avatar {
  color: rgb(22, 60, 85);
  font-weight: 900;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar :global(.ant-avatar-string) {
  line-height: 0.1em;
  left: auto !important;
  position: relative !important;
  transform: none !important;
}

.pinkAvatar {
  background-color: rgb(251, 186, 168);
  color: #163c55;
  font-weight: 700;
}

.blueAvatar {
  background-color: rgb(181, 231, 249);
}

.greenAvatar {
  background-color: rgb(188, 219, 145);
}

.defaultAvatar {
  background-color: rgb(cc, cc, cc);
}

.default {
  font-size: 18px;
}

.small {
  font-size: 14px;
}

.large {
  width: 128px;
  height: 128px;
  line-height: 128px;
  font-size: 18px;
}

.overlayBox {
  line-height: 1.3;
}
