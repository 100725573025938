.tabHeader {
  font-weight: 400;
}

.userList {
  margin: 25px 15px 0px 0px;
}

.inviteUrlBox {
  display: flex;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  justify-content: space-between;
  max-width: 90%;
  min-width: 440px;
}

.inviteUrlInput {
  cursor: default;
}

.inviteUrlForm {
  display: flex;
  justify-content: space-between;
}

.copySpan {
  display: block;
  margin: auto 0;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 0.2px;
  padding-right: 10px;
  color: #49d8f5;
  cursor: pointer;
}

.inviteUrlInput:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.inviteUrlInput {
  height: 40px;
  font-family: 'Courier New', monospace !important;
  font-weight: 100;
  letter-spacing: 0.7px;
  font-size: 15px;
  width: 100%;
  border: none;
}

.inviteUrlLink {
  color: #49d8f5;
}

.reverseFlexDirection {
  display: flex;
  flex-direction: column-reverse;
}

.space {
  width: 100%;
}

.divider::before,
.divider::after {
  border-color: #848484;
}

.space button {
  border-radius: 0 6px 6px 0;
}

.anyoneWithLink {
  margin-top: 10px;
}

.shareDeliverableBanner {
  background-color: #e6f4ff;
  border-radius: 12px;
  border-color: #91caff;
  display: flex;
  background-image: url('./circles.png');
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
}

.shareDeliverableBannerInfo {
  padding: 26px;
}

.shareDeliverableBannerHeader {
  margin-top: 5px;
}

.videoOuter {
  padding: 12px;
}

.video {
  width: 100%;
  height: 100%;
  border: 0;
}

.manageAccessContainer {
  display: flex;
  flex-direction: column-reverse;
}
