.container {
  background: var(--darkWhite);
  padding: 8px 16px 16px 16px;
  /* setting min-width prevents overflow with grid displays */
  min-width: 0;
}

.descriptionContainer {
  margin-bottom: 8px;
  height: 100%;
}
