.removeIcon {
  color: #bfbfbf;
  max-width: 17px;
}

.membersBox {
  display: flex;
  justify-content: space-between;
  padding: 10px 0 5px 0;
  align-items: center;
}

.membersLeft {
  max-width: 45%;
  width: 100%;
  display: flex;
  align-items: center;
}

.membersName {
  padding-left: 10px;
  font-weight: 300;
  min-width: 200px;
}

.controlsBox {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  max-width: 150px;
}

.displayName {
  position: relative;
}

.notConfirmed {
  font-style: oblique;
  font-size: 12px;
  position: absolute;
  left: 0;
  top: 14px;
  width: 130px;
  color: rgb(132, 132, 132);
}

.invited {
  font-weight: 500;
}

.invitedBox {
  max-width: 100px;
  width: 100%;
  text-align: center;
}

.userSettings {
  max-width: 52.5%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.controlsPadded {
  padding: 4px 0;
}

.roleSelect .ant-select-dropdown {
  width: 137px !important;
}

.roleSelect .ant-select-dropdown .ant-select-dropdown-menu {
  width: 137px !important;
}
.removeIcon {
  cursor: pointer;
}

.filterBox {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  gap: 16px;
}

.searchIcon,
.closeIcon {
  color: rgb(191, 191, 191);
  width: 14px;
}

.searchBar,
.searchBar input {
  font-weight: 400;
}

.searchBar input:nth-child(2)::placeholder {
  font-weight: 300;
}

.searchBar {
  max-width: 45%;
}

.filterContainer {
  display: flex;
  align-items: center;
}

.filterLabel {
  color: rgb(55, 55, 55);
  padding-right: 10px;
  cursor: default;
}

.secondaryButton {
  font-weight: 300;
}

.roleDisplay {
  font-weight: 400;
}

.alertBox {
  margin-top: 15px;
}

@media (max-width: 768px) {
  .filterBox {
    flex-direction: column;
  }

  .searchBar {
    max-width: 100%;
  }
}