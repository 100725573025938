.logoInputsContainer {
  display: flex;
  justify-content: space-between;
}

.logosHeader {
  font-weight: 400;
  letter-spacing: 0.7px;
}

.container {
  margin: 40px 0 0 0;
}

.alert {
  margin: 0 0 10px 0;
}

.alertMessage {
  margin-bottom: 0;
}
.owner {
  cursor: pointer;
}

@media (max-width: 767px) {
  .logoInputsContainer {
    flex-direction: column;
  }
}