.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  height: 100vh;
  width: 100%;
  background-image: url('../../assets/images/bike.png'),
    url('../../assets/images/success_lane.png'),
    url('../../assets/images/road.png');
  background-repeat: no-repeat, no-repeat, repeat-x;
  background-size: 565px, 267px, 608px;
  background-position: bottom left, bottom right, bottom center;
}

@media (max-width: 830px) {
  .container {
    background-image: url('../../assets/images/bike.png'), none,
      url('../../assets/images/road.png');
  }
}

@media (max-width: 980px) {
  .container {
    background-size: 395px, 180px, 425px;
  }
}

.headerLogo {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 120px;
  height: 40px;
  background: url('../../../../assets/images/bugherd-logo-black.png');
}

.dropdown {
  font-size: 18px;
  font-weight: 400;
  padding: 0 7px;
}

.dropdown :global(.ant-select-selector) {
  border-bottom: 2px solid #3f71e0 !important;
}

.multiple :global(.ant-select-selector) {
  border: 2px solid #3f71e0 !important;
}

.dropdownError :global(.ant-select-selector) {
  border-bottom: 2px solid #d0021b !important;
}

.dropdown :global(.ant-select-arrow) {
  color: #3f71e0;
}

.unused {
  color: #a5a5a5;
  font-weight: 300;
}

.multiple {
  width: 60%;
  margin-top: 15px;
}

.marketingSourceInput {
  margin-top: 15px;
  border: 2px solid #3f71e0;
}

.marketingSourceInput:focus {
  border: 2px solid #3f71e0;
}

.surveyOuter {
  margin: 15vh auto 0 auto;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  text-align: center;
}

.surveyHeader {
  font-weight: 900;
  color: #3e3e3e;
  margin-bottom: 30px;
  font-size: 24px;
}

.surveyTagline {
  margin-bottom: 90px;
  font-size: 18px;
  color: #7d7d7d;
}

.lineOne,
.lineTwo {
  line-height: 35px;
}

.lineOne,
.lineTwo,
.lineThree {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 3em;
}

.lastLine {
  margin-bottom: 2.5em;
}

.lineThree {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2.5em;
}

.submitButton:disabled,
.submitButton:disabled:hover {
  background-color: #c8c8c8;
}

.surveyButton {
  margin-top: 25px;
  color: #49d8f5;
}

.errorMessage {
  margin-top: 30px;
  height: 24px;
  width: 168px;
  color: #d0021b;
  font-size: 14px;
  letter-spacing: 0;
  text-align: center;
}

@media (max-width: 700px) {
  .headerLogo {
    width: 88px;
    height: 33px;
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .surveyHeader {
    margin-bottom: 15px;
    line-height: 1.3;
  }

  .surveyTagline {
    margin-bottom: 40px;
  }

  .lineOne,
  .lineTwo,
  .lineThree {
    font-size: 14px;
    line-height: 2.5;
  }

  .dropdown {
    font-size: 14px;
  }
}

@media (max-height: 400px) and (max-width: 700px) {
  .container {
    height: 140vh;
  }

  .fullSurveyShown {
    height: 200vh;
  }
}

@media (max-width: 400px) and (max-height: 700px) {
  .container {
    height: 115vh;
  }

  .fullSurveyShown {
    height: 165vh;
  }
}
