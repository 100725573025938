.sendButton {
  padding: 0 30px;
}

.emailBox {
  padding-bottom: 15px;
  width: 500px;
}

.instructionsOuter {
  margin-top: 30px;
}
