.screenshotModal {
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
}

.adminModalWrap {
  top: 48px;
}

.screenshotModal :global(.ant-modal-body) {
  padding: 5px;
}

.screenshotModal :global(.ant-modal-content) {
  background: transparent;
}

.modalScreenshotContainer {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.modalBugPinContainer {
  position: absolute;
  width: 34px;
  height: 36px;
  margin-top: -32px;
  margin-left: -16px;
  background-repeat: no-repeat;
  background-size: 34px 36px;
}

.inAppPin {
  cursor: pointer;
}

.modalImage {
  width: 100%;
  height: auto;
}

.actual .modalImage {
  max-height: none;
  max-width: none;
}

.actual .modalScreenshotContainer {
  min-height: min-content;
}

.pinContainer {
  position: relative;
}

.viewOptions {
  position: fixed;
  bottom: 1em;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
}

.imageCanvas {
  position: absolute;
  top: 0;
  left: 0;
}

.loading {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
}
