.container {
  background: var(--darkWhite);
  padding: 8px 16px 8px 16px;
}

.detailsHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.columnName {
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 80px;
}

.priorityIndicator {
  width: fit-content;
  color: var(--darkWhite);
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  border-radius: 3px;
  padding: 1px 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.priorityIndicator a {
  color: var(--darkWhite);
}

.notSet {
  background: #ccc;
}
.critical {
  background: #d46060;
}
.important {
  background: #e8b019;
}
.normal {
  background: #60c5d4;
}
.minor {
  background: #8bb863;
}

.description {
  border: none;
  outline: none;
  resize: none;
  padding: 4px 4px;
  border: 1px solid transparent;
}

.creator {
  color: var(--colorGrey3);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 70px;
}

.taskInfo,
.taskLink {
  padding-right: 3px;
}

.titleHeader {
  z-index: 9;
  width: 100%;
  position: sticky;
  top: 0;
}

.titleHeaderExpanded {
  position: absolute;
  padding: 0 !important;
}

.titleHeader .container {
  padding-bottom: 8px;
}

.viewInTaskBoard {
  display: flex;
  text-decoration: none;
  font-size: 12px;
  padding: 0;
  align-items: center;
  font-weight: 500;
}

.linkIcon {
  stroke-width: 2px;
  margin-bottom: 2px;
}

.viewTaskBoardInner {
  padding-right: 4px;
}

.containerExpanded {
  padding: 0 !important;
}

.priorityIndicatorExpanded {
  margin: 8px 16px;
}
