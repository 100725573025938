.modal :global(.ant-modal-header) {
  padding: 24px;
  text-align: center;
}

.modal :global(.ant-modal-title) {
  font-size: 16px;
}

.modal :global(.ant-modal-body) {
  padding: 0;
}

.modal {
  color: pink;
}

.modal :global(.ant-tabs-tab-active) {
  font-weight: unset;
}

.emptyState {
  display: flex;
  overflow-y: auto;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.emptyInnerTop {
  padding: 30px 0 0 0;
  text-align: center;
}

.aboutOuter {
  display: flex;
  justify-content: center;
}

.websiteLink {
  text-decoration: none;
}

.inviteLink {
  color: #20c0e7;
  cursor: pointer;
}

h2 {
  margin-bottom: 0.8em;
}

.installOuter {
  background: white;
  border-radius: 4px;
  box-shadow: 0px 0px 5px 5px rgb(204, 204, 204);
  max-width: 650px;
}

.installOuter :global(.ant-tabs-nav-wrap) {
  display: flex;
  justify-content: center;
}

.footer {
  height: 154px;
}

.footerImage {
  width: 100%;
}
