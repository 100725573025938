.enabled,
.disabled {
  margin-left: 1em;
}

.disabled {
  color: #b7b7b7;
}

.enabled {
  font-weight: 600;
  color: #20c0e7;
}

.action {
  margin-top: 4em;
}

.icon {
  color: #20c0e7;
  margin-left: 0.5em;
}

.action button:first-child {
  margin-right: 1em;
}
