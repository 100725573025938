.toTaskBoard {
  display: none;
}

.cardContainer {
  display: flex;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  background: #fff;
  cursor: pointer;
  align-items: center;
  color: #666666;
  font-weight: 500;
  border-top: 1px solid #d9e7f4;
}

.firstInGroup {
  margin-top: -12px;
  border-top: 1px solid #d9e7f4;
}

.cardContainer:hover .toTaskBoard {
  display: block;
}

.cardContent {
  display: flex;
  justify-content: space-between;
  padding: 15.5px 25px 15.5px 10px;
  align-items: center;
  width: calc(100% - 45px);
}

.cardInner {
  display: flex;
  align-items: center;
  width: 100%;
  transition: box-shadow 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.activeTask {
  box-shadow: 0px 0px 0px 2px #3f71e0;
}

.cardID {
  font-weight: 500;
  color: white;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  min-width: 35px;
  height: 35px;
  margin: 5px 0 5px 5px;
}

.priority0 {
  background-color: #ccc;
}
.priority1 {
  background-color: #d46060;
}
.priority2 {
  background-color: #e8b019;
}
.priority3 {
  background-color: #60c5d4;
}
.priority4 {
  background-color: #8bb863;
}

.cardEnd {
  display: flex;
  align-items: center;
}

.description {
  display: flex;
  max-width: 100%;
  align-items: center;
  column-gap: 5px;
}

.descriptionAndProgress {
  max-width: calc(100% - 65px);
}

.taskDescription {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  font-weight: 500;
}

.isListBar .taskDescription {
  max-width: unset;
}

.toTaskBoard {
  font-weight: 500;
  cursor: pointer;
  margin-right: 8px;
  position: absolute;
  right: 2px;
  z-index: 4;
  background: #ffffffeb;
  padding: 8px;
}

.separatorName {
  background: #fff;
  margin-left: 5px;
  padding: 0 5px;
  font-size: 10.5px;
  text-transform: uppercase;
  font-weight: 600;
  color: #19222a;
  letter-spacing: -0.5px;
  border: 1px solid hsl(209, 55%, 90%);
  border-radius: 3px 3px 0px 0px;
  border-bottom: none;
}
.separatorOuter {
  position: sticky;
  top: 0;
  z-index: 9;
}

.separatorOuter:not(:first-child) {
  margin-top: -12px;
}

.listSeparator {
  display: flex;
  align-items: center;
  justify-content: center;
}

.lastInGroup .cardContent {
  border-bottom: 1px solid transparent;
}

.taskListOuter {
  position: relative;
  padding-bottom: 12px;
  height: 100%;
}

.bulkRequestProgress {
  font-size: 12px;
  font-weight: 400;
  color: #ccc;
  display: flex;
  align-items: center;
  padding-left: 5px;
}

.archiving {
  padding-left: 5px;
}

.isSelected {
  background-color: #d9e7f4;
}

.checkboxWrapper {
  visibility: hidden;
  opacity: 0;
  transition: all 0.18s ease-in;
  margin-right: 6px;
  cursor: pointer;
}

.cardContainer:hover .checkboxWrapper,
.isSelected .checkboxWrapper,
.showAllCheckboxes .checkboxWrapper {
  visibility: visible;
  opacity: 1;
}
