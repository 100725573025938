.avatarBlock {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.button {
  padding: 0 30px;
}

.noAvatar {
  line-height: 1.3;
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
}
