.openWebsite {
  display: flex;
  align-items: center;
  color: #3f71e0;
  font-weight: bold;
}

.openWebsite:hover {
  color: #1a49b0;
}

.websiteIcon {
  width: 20px;
  height: 20px;
  color: var(--blueColorIcons);
}
