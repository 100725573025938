.container {
  display: flex;
  max-width: 95%;
  margin: 10px 0 5px 0;
  flex-wrap: wrap;
  position: relative;
  min-height: 28px;
}

.reactionEmoji {
  font-size: 13px;
  margin-bottom: -1px;
}

.reactionEmoji span {
  font-size: 14px;
  margin-left: 4px;
}

.emojiButton {
  padding: 4px 7px;
  margin: 0 4px 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px !important;
  background-color: #f1f2f2;
  border: 1px solid #f1f2f2;
  color: #666666;
}

.container .emojiButton:hover {
  background-color: white;
  border: 1px solid #666666;
  color: #666666 !important;
}

.reactedTo,
.container .reactedTo:hover {
  background-color: #e1eff4;
  border: 1px solid #1a49b0;
  color: #1a49b0 !important;
}

.whoReacted {
  color: white;
  margin: 5px 0;
  font-size: 12px;
}

.others {
  font-weight: 600;
  color: var(--colorPrimary);
}

.reactedWith {
  margin-left: 5px;
  color: #b3b3b3;
}

.emojiTooltipContent {
  text-align: center;
}

.tooltipEmojiReaction {
  font-size: 40px;
  background-color: white;
  border-radius: 5px;
  padding: 0 10px;
}

.tooltipEmojiReaction:not(:first-child) {
  margin-left: 4px;
}

.reactedUser {
  font-weight: 600;
}
