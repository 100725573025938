.description {
  border: none;
  outline: none;
  resize: none;
  font-weight: normal;
  font-size: 15px;
  height: 100%;
}

.description textarea {
  margin-bottom: 3px;
  box-shadow: none;
  height: 100%;
}

.maxCountReached:focus {
  box-shadow: 0px 0px 4px #f5222d;
}

.descriptionDisplay {
  border: 1px solid transparent;
  padding: 4px 4px 0px 4px;
  min-height: 94px;
  max-height: 142px;
  overflow-y: auto;
  font-weight: normal;
  font-size: 15px;
  border-radius: 4px;
  position: relative;
  color: var(--colorTextBase);
}

.viewIsExpanded {
  max-height: unset;
  min-height: unset;
  max-height: unset;
  overflow-y: auto;
  height: 100%;
}

.hideDescriptionActions {
  display: none;
}

.descriptionDisplay span {
  word-break: break-word;
  white-space: pre-line;
}

.descriptionDisplay:hover {
  border: 1px solid #ccc;
  box-shadow: inset 0 0 2px #ccc;
}

.editable {
  height: 100% !important;
}

.editedIsExpanded.editable :global(textarea) {
  height: 100% !important;
  overflow-y: auto !important;
  max-height: unset !important;
}

.uneditable:hover {
  border: 1px solid transparent;
  box-shadow: inset 0 0 2px #ffffff;
}

.descriptionActions {
  position: relative;
  height: 25px;
  width: 100%;
}

.undoButton {
  position: absolute;
  right: 0px;
  padding-top: 2px;
  color: #3f71e0;
  z-index: 1;
  border: none;
}

.undoIcon {
  width: 14px;
  height: 14px;
  margin-top: 2px;
  border: none;
}

.moreButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 13px;
  height: 22px;
  color: #3f71e0;
}

.readMoreOuter {
  position: sticky;
  margin-bottom: 10px;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
}

.readMoreOuter .line {
  position: absolute;
  top: -5px;
  height: 1px;
  background: radial-gradient(
    circle,
    rgba(230, 230, 230, 1) 10%,
    rgba(255, 255, 255, 1) 35%
  );
}

.mentionText {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  white-space: pre-line;
  word-break: break-word;
}

.descriptionExpanded {
  min-height: 0;
  height: 100%;
  max-height: unset;
}

.readMoreButton {
  padding: 4px 15px;
}

.readMoreButton,
.readMoreButton:focus,
.readMoreButton:hover,
.readMoreButton:active,
.readMoreButton:visited {
  color: #3f71e0;
}

:global(.rightToLeft) .description {
  direction: rtl;
}
