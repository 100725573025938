.priceSelect {
  padding: 10px 0 20px 0;
  margin-top: 0;
  text-align: right;
}

.priceSelect a {
  text-decoration: none;
}

.priceSelect h4 {
  border-bottom: none;
  box-shadow: none;
}

.priceSelect .active h4 {
  border: none;
}

.priceSelect em {
  font-weight: 600;
  white-space: nowrap;
  padding-top: 5px;
}

.saveAlert {
  font-style: normal;
  display: block;
  text-align: right;
  padding-bottom: 0;
}

.plans .active {
  border-color: #20c0e7;
}

.plan {
  border: 2px solid transparent;
  padding: 20px;
  box-shadow: 0px 0px 10px #cccccc;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 570px;
}

.plan li {
  padding: 4px 0;
}

.plan li span {
  display: flex;
  align-items: center;
  text-align: left;
}

.plan li svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.header h2 {
  margin-bottom: 10px;
}

.paymentInfo {
  color: #c0bbbb;
  font-size: 14px;
}

.price {
  font-size: 24px;
  font-weight: 700;
  color: #666666;
}

.payPeriod {
  text-align: right;
}
.featuresBox {
  display: flex;
  justify-content: space-between;
}

.usageExceeded,
.usageExceeded:hover {
  background-color: transparent;
  color: #a79f9f;
  border: 1px solid #c0bbbb;
  cursor: default;
  font-weight: 500;
  opacity: 1;
  text-shadow: none;
}
.payPeriod li {
  padding: 4px 0;
}

.payPeriod span {
  display: flex;
  align-items: center;
}

.payPeriod svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.customContactUs,
.customContactUs:hover {
  border-color: #c0bbbb;
  margin-bottom: 20px;
}

.customContactUs a {
  text-decoration: none;
}

@media (max-width: 991px) {
  .featuresBox {
    flex-direction: column-reverse;
  }

  .header {
    flex-direction: column;
    align-items: center;
  }

  .plan {
    width: 100%;
  }

  .priceSelect,
  .saveAlert {
    text-align: center;
  }
}
