.heroText {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}

.usageAndActions {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 20px;
}

@media screen and (min-width: 992px) {
  .usage {
    width: 55%;
  }
}

@media screen and (max-width: 992px) {
  .usageAndActions {
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin-bottom: 1rem;
  }

  .heroText {
    justify-content: center;
  }
}

.additionalMemberLabel {
  text-transform: capitalize;
  padding: 0;
}

.groupingHorizontal {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
