.tabsContainer {
  color: #19222a;
}

.tabsContainer h3 {
  font-weight: bold;
  color: #19222a;
  padding: 0;
  margin: 1.5rem 0 1rem 0;
}

.tabsContainer p {
  margin-bottom: 16px;
}

.orgHeader {
  letter-spacing: 0.2px;
}

.ant-tabs-tab-active {
  color: #49d8f5;
}

.tabsContainer :global(.ant-tabs-tab-btn) {
  text-shadow: none !important;
}
