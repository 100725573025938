.loader {
  height: 100vh;
  width: 100vw;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  top: 0;
  left: 0;
}

.wrapper,
.emptyWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  max-width: 900px;
  margin: 30px auto 0 auto;
  padding-bottom: 6.25rem;
  background-color: var(--colorBgBase);
}

.emptyWrapper {
  text-align: center;
  display: block;
}

.myProject {
  padding-left: 10px;
}

.favourites {
  text-transform: capitalize;
}

.title {
  display: flex;
  justify-content: center;
  font-weight: bold;
}

.topContainer {
  display: flex;
  margin: 10px;
  margin-bottom: 38px;
  justify-content: center;
  align-items: center;
}

.searchInputContainer {
  flex-grow: 1;
  text-align: center;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.searchInputContainer .addButton {
  position: absolute;
  top: -4px;
  right: 10px;
}

.searchInput {
  width: 100%;
}

.searchInput {
  max-width: 300px;
}

.addProjectContainer {
  width: 150px;
  text-align: right;
}

.button {
  font-weight: 100;
}
.processButton {
  font-weight: 100;
}

.cardBox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: flex-start;
  width: 100%;
}
.search {
  opacity: 0.7;
  width: 14px;
  height: 14px;
}

.alert {
  opacity: 0.7;
  width: 14px;
  height: 14px;
}
._elev_io {
  background: white;
}

.container {
  position: relative;
}

.pagination {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}

.controlsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

@media (max-width: 768px) {
  .controlsContainer {
    align-items: center;
  }
}

.topPagePosition {
  margin: 0 8px;
}

.bottomPagePosition {
  margin-top: 45px;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

@media (max-width: 820px) {
  .searchInputContainer {
    display: block;
    text-align: unset;
  }

  .searchInput {
    max-width: calc(100% - 64px);
    margin-bottom: 20px;
  }

  .topPagePosition {
    text-align: center;
  }
}

@media (max-width: 900px) {
  .cardBox {
    max-width: calc(100% - 20px);
    margin: 0 auto;
  }
}

.projectSetupPendingBanner {
  background-color: var(--colorInfoBg);
  border: 1px solid var(--colorInfoBorder);
  color: var(--colorText);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
}

.pulseIndicator sup {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  animation: pulse 2s infinite;
  position: absolute;
  top: 0px;
  right: -4px;
  z-index: 1;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
    scale: 0.75;
  }
  70% {
    box-shadow: 0 0 0 5px rgba(255, 0, 0, 0);
    scale: 1;
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    scale: 0.75;
  }
}

.closeModal {
  margin-left: 30px;
}

.modalFooter {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addFolderLabel,
.addFolderInput {
  margin-bottom: 10px;
}

.selectParentFolder {
  width: 220px;
}

.addFolderButton {
  margin-left: 1px;
}

.sortBy {
  margin-right: 10px;
  margin-left: 10px;
}
.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 25px;
}

.manageFolderMenuItem svg {
  width: 14px;
  height: 14px;
}

.folderNameInputWrapper {
  padding-left: 10px;
  max-width: 70%;
  width: 100%;
  margin: 12px 0 0.8em 0;
}

.favouritesMenuItem svg {
  stroke: var(--colorSeverityImportant);
  fill: var(--colorSeverityImportant);
  width: 20px;
  height: 20px;
  margin-left: -2px;
}

.favouritesMenuItem.active svg {
  stroke: white;
  fill: white;
}

.favouritesMenuItem.active {
  background-color: var(--colorSeverityImportant) !important;
}

.favouritesMenuItem.active span {
  color: white;
}

.allProjectsMenuItem svg,
.newFolderMenuItem svg,
.sortOverlay .menuItem .menuIcon,
:global(.antd-container).subMenuItem .menuIcon {
  width: 20px;
  height: 20px;
  margin-left: -2px;
}

.allProjectsMenuItem.active {
  background-color: var(--colorPrimary) !important;
}

.allProjectsMenuItem.active span {
  color: white;
}

.allProjectsMenuItem.active svg {
  stroke: white;
}

.sortOverlay .menuItem {
  background-color: white;
}

.sortOverlay .menuItem.active div,
:global(.antd-container).subMenuItem.active,
.sortOverlay .menuItem.active,
.subMenuItem.active,
.sortOverlay .newFolderMenuItem {
  color: var(--colorPrimary) !important;
}

.sortOverlay .menuItem div {
  display: flex;
  align-items: center;
}

.suffixIcon circle {
  stroke: var(--colorSeverityCritical);
  fill: var(--colorSeverityCritical);
}

.suffixIcon path {
  stroke: white;
}
