.copyCode {
  cursor: default;
}

.embedBox {
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}

textarea.copyCode {
  font-family: 'Courier New', monospace !important;
  font-weight: 100;
  letter-spacing: 0.2px;
  font-size: 13px;
  resize: none;
  border: none;
  min-height: 65px;
}

.copySpan {
  display: block;
  margin: auto 0;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 0.2px;
  padding-right: 10px;
  color: #3f71e0;
  cursor: pointer;
  font-weight: 900;
}

.copyCode:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.embedCopy {
  padding-bottom: 5px;
  width: 100%;
  max-width: 70px;
}

.embedCopyBox {
  display: flex;
  flex-direction: row-reverse;
  background-color: white;
}
