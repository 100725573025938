.heading {
  color: #d0021b;
}

.container {
  max-width: 460px;
  margin: 0 auto;
}

.container h2 {
  line-height: 1.5em;
}

.container h1 {
  margin-bottom: 1.7em;
}
