.user {
  display: flex;
  max-height: 40px;
  padding-right: 5px;
}

.selected {
  background: rgba(32, 192, 231, 0.314);
  border-radius: 5px;
}

.avatarContainer {
  max-width: 40px;
  max-height: 40px;
  padding: 4px;
  margin-right: 4px;
}

.allUsers__control,
.allUsers__input,
.membersOnly__control,
.membersOnly__input {
  transition: padding ease 0.2s;
}

.allUsers__control textarea,
.allUsers__input textarea,
.membersOnly__control textarea,
.membersOnly__input textarea {
  border: 1px solid #d9d9d9;
}

.allUsers__input,
.membersOnly__input {
  font-weight: normal !important;
  padding: 5px 10px 5px 10px !important;
  border-color: #d9d9d9;
  border-radius: 5px;
}

.allUsers__input:focus,
.membersOnly__input:focus {
  padding-bottom: 15px !important;
}

.allUsers__input:focus {
  border: 1px solid #3f71e0;
  box-shadow: 0px 0px 0px 2px rgba(32, 192, 231, 0.2);
}

.allUsers__input:focus-visible,
.membersOnly__input:focus-visible {
  outline: none;
}

.allUsers__input {
  background-color: white !important;
  color: #19222a;
}

.membersOnly__input {
  background: #fff6d9 !important;
  border-color: #efdba7;
  box-shadow: 0px 0px 0px 2px rgba(239, 219, 167, 0.2);
}

.allUsers__highlighter,
.membersOnly__highlighter {
  line-height: 1.5;
  padding: 6px 11px 20px 11px;
  word-break: break-all;
}

.allUsers__suggestions,
.membersOnly__suggestions {
  left: 0;
  margin: 18px auto 0 auto;
  border-radius: 5px;
  max-width: 95%;
  box-shadow: 0px 0px 25px 4px rgba(0, 0, 0, 0.25);
  right: auto;
  z-index: 11 !important;
}

.allUsers__suggestions__list,
.membersOnly__suggestions__list {
  max-height: 145px;
  overflow: auto;
  margin: 5px 5px 2px 5px !important;
}

.allUsers__suggestions__item__display,
.membersOnly__suggestions__item__display {
  color: #666666;
  margin: auto 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100% - 40px);
}

.mentions {
  background: linear-gradient(to bottom, rgb(255, 255, 255) 97%, #20c0e7 98%);
}
