.actionContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  flex-wrap: wrap;
  gap: 4px;
}

.adminContainer {
  border-bottom: 1px solid #dbe3e6;
  padding-bottom: 20px;
}

.moreActionsContainer {
  min-width: 85px;
}

.active {
  color: #3f71e0;
}

.markAsContainer {
  min-width: 38%;
  max-width: calc(100% - 90px);
}

.moreActionsSmallView {
  min-width: 0px;
}
