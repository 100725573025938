.nameWrapper {
  display: flex;
  justify-content: space-between;
}

.nameWrapper > div {
  width: 48%;
}

.form {
  width: 100%;
  max-width: 300px;
}

.actions {
  text-align: center;
  margin: 2.4em 0 0.5em 0;
}
