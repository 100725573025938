.componentContainer {
  margin-bottom: 10px;
}
.missing {
  margin: 40px 4px 0px 4px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.logoBox {
  width: 125px;
  height: 125px;
  box-shadow: 0px 2px 8px rgb(199, 199, 199);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}

.logo {
  max-width: 95px;
  max-height: 45px;
}

.moreInfo {
  max-width: 400px;
  margin-left: 20px;
}

.title {
  font-weight: 500;
  text-transform: uppercase;
}

.about {
  margin-top: 10px;
}

.missingImg {
  width: 100px;
  height: 100px;
  stroke: #d8d8d8;
}

.surveyAnchor,
.surveyAnchor:active,
.surveyAnchor:visited,
.surveyAnchor:hover {
  text-decoration: none;
  color: white;
}

@media (max-width: 850px) {
  .moreInfo {
    margin-left: 10px;
  }
  .logoBox {
    width: 100px;
    height: 100px;
  }
  .missingImg {
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 725px) {
  .logoBox {
    width: 75px;
    height: 75px;
  }
  .missingImg {
    width: 60px;
    height: 60px;
  }
  .about {
    font-size: 12px;
  }
}
