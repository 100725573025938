.container {
  background-color: #f6f9fc;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  min-height: 100vh;
  min-width: 100vw;

  @media (width < 992px) {
    flex-direction: column;
  }
}

@media (max-width: 700px) {
  .container {
    justify-content: unset;
    padding-top: 2rem;
  }
}

.tryBugherdOnContainer {
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  background-image: url('./assets/background_bottom_image.png');
}

.websiteErrorContainer {
  background-color: #f6f9fc;
  display: flex;
  justify-content: center;
  padding: 1rem;
  min-height: 100vh;
  min-width: 100vw;
  padding-top: 100px;
}

.containerWrapper {
  max-width: 400px;
}

.content {
  text-align: center;
  margin-bottom: 2rem;
}

.stepCard {
  background-color: #fff;
  color: #19222a;
  text-decoration: none;
  border-radius: 12px;
  box-shadow: 0px 10px 10px 0px rgba(0, 57, 128, 0.2);
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  column-gap: 24px;
  align-items: center;
  transition: all 0.3s ease;
  cursor: pointer;
  opacity: 0;
  transform: translateY(-10px);
  animation: fadeIn 0.5s forwards;
  text-align: start;
  max-width: 396px;

  & img {
    border-radius: 6px;
    height: 100px;
    width: 100px;
    object-fit: cover;
  }

  &:hover {
    box-shadow: 0px 10px 10px 0px rgba(0, 57, 128, 0.1);
  }

  & h4 {
    font-size: 16px;
  }

  & p {
    margin-bottom: 0;
  }
}

.containerWrapper > .stepCard:nth-child(2) {
  animation-delay: 0.1s;
}

.containerWrapper > .stepCard:nth-child(3) {
  animation-delay: 0.25s;
}

.containerWrapper > .stepCard:nth-child(4) {
  animation-delay: 0.45s;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.flexContainerWrapper {
  display: flex;
  @media (width < 1200px) {
    flex-direction: column;
  }
}

.contentWrapper {
  padding: 50px 5%;

  & h3 {
    font-size: 1.2rem;
  }

  & h4 {
    margin-block: 1rem;
  }

  & .projectName {
    margin-top: 2rem;
  }
}

.errorContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.withSpacing {
  gap: 12px;
}

.inputContainer {
  min-width: auto;
}

.inputButtonContainer {
  display: flex;
  gap: 8px;
  min-width: 280px;

  & :global(.ant-form-item-control-input) {
    min-width: 290px;
  }
}

.nextButton {
  margin-bottom: 16px;
}

.backButton {
  color: #3f71e0;
}

.backButton:hover {
  color: #1a49b0 !important;
}

.greyText {
  color: #848484;
}

.smallLink {
  font-size: 12px;
  font-weight: bold;
  margin-block: 0.4rem;
  color: #1677ff;
}

.stepOne {
  margin: 12rem auto auto auto;
  padding: 2rem;

  & p,
  & h2 {
    margin-block: 0.4rem;
  }

  & .stepCard {
    margin-block: 1rem;
  }
}

.assetUpload {
  & :global(.ant-upload) {
    display: flex;
    background-color: #d9e7f4;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    padding: 0.5rem;
    margin-block: 1rem;
    cursor: pointer;
  }

  & :global(.ant-upload-select) {
    border-width: 3px;
    border-style: dotted;
    border-color: #a8c1d9;
    border-radius: 12px;
  }

  & .uploadIcon {
    width: 50px;
    height: 50px;
  }
}

.assetIsUploading {
  pointer-events: none;
}

.imageWrapper {
  display: none;
  opacity: 0;
  transform: translateX(10px);
  animation: fadeInLeft 0.6s forwards;
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);

  & :global(.ant-image .ant-image-img-placeholder) {
    border-radius: 100px;
  }

  @media (width > 992px) {
    display: block;
    aspect-ratio: 1 /1;
    position: relative;
    height: 90vh;
    width: auto;
  }

  & img {
    max-height: 90vh;
    width: auto;
    aspect-ratio: 1 /1;
  }
}

@keyframes fadeInLeft {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.figmaButton {
  background-color: #1e1e1e;
  color: #fff;
  margin-top: 0.5rem;

  &:hover {
    background-color: #373737;
  }
}

.inputWrapper {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  margin-block: 1.5rem;
}

.waveHand {
  display: inline-block;
  transform-origin: bottom center;
  transform: rotate(-7deg);
  animation: wave 1s 3;
  animation-delay: 0.6s;
}

@keyframes wave {
  0%,
  100% {
    transform: rotate(-7deg);
  }
  50% {
    transform: rotate(7deg);
  }
}

.figmaProgress {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 12px;
}

.nameInput {
  max-width: 265px;
}

.iframeWrapper {
  height: 100vh;
  width: 100vw;
}

.iframeContainer {
  display: flex;
  height: 100%;
  width: 100%;
  border: none;
}

.invalidUrlMessage {
  max-width: 236px;
}

.disclaimerContainer {
  background-color: #f6f9fc;
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  min-height: 100vh;
  min-width: 100vw;
  line-height: 32px;

  @media (width < 992px) {
    flex-direction: column;
  }
}

.disclaimerContainer h2 {
  line-height: 32px;
}

.disclaimerContainer p {
  font-size: 16px;
}

.disclaimerTextContent {
  max-width: 30%;
  margin-top: 100px;
  margin-left: 100px;
  margin-right: 85px;
}

h2 {
  line-height: 32px;
}

.setupDescription {
  max-width: 500px;
}
