html {
  background: #e3eff8;
}

body {
  background: #e3eff8;
}

.title {
  color: #19222a;
  font-size: 24px;
  font-weight: 900;
  text-align: center;
  margin: 0;
}

.clearOrg {
  margin-top: 15px;
  text-align: center;
}

.form {
  margin: 1rem 0;
}

.form a {
  color: #3f71e0;
}

.emailSentContainer {
  text-align: center;
}

.loginLinkForm {
  text-align: center;
}

.center {
  text-align: center;
}

.rightAlign {
  text-align: right;
}

.submit {
  margin: 15px 0;
  background-color: #3f71e0;
}

.errorMessage {
  margin: 15px 0;
}

.loginErrorMessage {
  text-align: center;
  margin: 15px 0;
}

.socialSsoIcon {
  float: left;
}

.emailLoginLink {
  width: 100%;
  border-radius: 5px;
  margin-top: 5px;
  background-color: #3f71e0;
  font-size: 14px;
  justify-content: center;
  color: #ffffff;
}

.submitSso {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #e5e4e5;
  margin-top: 5px;
  font-size: 14px;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

.submitSso:hover,
.submitSso:focus,
.submitSso:visited {
  background-color: rgba(26, 73, 176, 0.15);
  border: 1px solid #3f71e0;
  color: var(--colorPrimary) !important;
  text-decoration: none;
}

.submit:hover {
  background-color: #1a49b0;
}

.submit:focus {
  background-color: #1a49b0;
}

.submit:active {
  background-color: #1a49b0;
}

.intro {
  text-align: center;
  color: #19222a;
  margin: 15px 0 0 0;
}

.intro strong {
  font-weight: 600;
}

.successIcon {
  width: 50px;
  height: 50px;
}

.success {
  text-align: center;
}

.successIcon {
  margin: 0 0 15px 0;
}

.closeCopy {
  margin: 15px 0 0 0;
}

.continueButton {
  margin: 20px 0;
}

.footerImage {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: 0 0 15px 15px;
  bottom: 0;
}

.form :global(.ant-form-item-explain) {
  margin-top: 3px;
  margin-bottom: 5px;
  line-height: 1.3;
}

.form :global(.ant-form-item-extra) {
  margin-top: 3px;
  margin-bottom: 5px;
  line-height: 1.3;
}

.ssoDivider {
  width: 100%;
  height: 20px;
  border-bottom: 1px solid black;
  text-align: center;
  margin: 0 0 18px 0;
}

.ssoDividerText {
  background-color: #fff;
  padding: 0 10px;
  position: relative;
  top: 8px;
}

.warning {
  margin-top: 20px;
}

.warning :global(.ant-alert-message) {
  font-size: 13px;
}

.submitSocialSso {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #e5e4e5;
  margin-top: 5px;
  font-size: 14px;
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

.newToBugherd {
  margin-top: 20px;
  color: #19222a;
  text-align: center;

  & a {
    color: #3f71e0;
    padding-left: 3px;
  }
}

.staggerInAnimation {
  opacity: 0;
  animation: fadeInUp 0.5s ease forwards;
  animation-delay: calc(
    var(--base-delay) + var(--delay-increment) * var(--index, 0)
  );
}

.backToWebsite {
  text-align: center;
  position: absolute;
  bottom: -3rem;
  left: 50%;
  transform: translateX(-50%);

  & a {
    color: #ffffff;
    text-align: center;
  }
}

@media screen and (max-width: 990px) {
  .backToWebsite {
    display: none;
  }
}
