.tagsOuter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 4px 0;
  border-bottom: 1px solid #dbe3e6;
  width: 100%;
  min-height: 24px;
}

.tagsInner {
  flex-grow: 1;
}

.tagsBox {
  max-width: 100%;
}

.tagsWrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
}

.tag {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.tag:hover {
  opacity: unset;
  background-color: rgb(255, 255, 255);
}

.tag .tagContent {
  display: flex;
  align-items: center;
  max-width: 120px;
}

.innerTag {
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
}

.addTag {
  border: 1px dashed rgba(1, 1, 1, 0.2);
  cursor: pointer;
  background-color: rgb(248, 248, 248);
  color: #848484;
}

.tagIcon,
.addTagIcon {
  width: 12px;
  margin-right: 4px;
  flex-shrink: 0;
}

.tagsAutoComplete {
  width: 100%;
  margin-bottom: 4px;
}

.tagsInput {
  width: 76px;
  padding-left: 4px;
}

.optionTag {
  display: flex;
  align-items: center;
}

.inputBox {
  display: flex;
  flex-grow: 1;
}

.optionTag :global(.ant-select-item-option-content) {
  display: flex;
}

.loaderIcon {
  width: 14px;
  margin-right: 5px;
}
