.empty {
  text-align: center;
  height: 100%;
  padding: 30px;
}

.emptyUserTasksImage {
  margin-top: 50px;
  height: 180px;
  width: 100%;
  background-repeat: no-repeat, no-repeat, repeat-x;
  background-size: contain;
  background-position: center;
  background-image: url('../../../assets/images/empty-state.png');
}

.emptyUserCommentsImage {
  margin-top: 50px;
  height: 99px;
  width: 100%;
  background-repeat: no-repeat, no-repeat, repeat-x;
  background-size: contain;
  background-position: center;
  background-image: url('../../../assets/images/no_comments.png');
}

.emtpyHeader {
  color: #373737;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 15px;
  margin-top: 15%;
}

.emptyHelp {
  color: #373737;
  font-size: 14px;
}

.emptyContainer {
  height: 446px;
  width: 100%;
  border-radius: 4px;
  background-color: #e6e6e6;
  margin-top: 24px;
}
