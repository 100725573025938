.inviteRole .ant-select-dropdown {
  width: 137px !important;
}

.inviteRole .ant-select-dropdown .ant-select-dropdown-menu {
  width: 137px !important;
}

.inviteRoleWrapper {
  min-width: unset;
  margin-bottom: 0px;
}

.resultContainer :global(.ant-select-item-option-content) {
  display: flex;
}

.inviteHelp {
  padding-left: 6px;
}

.inviteInfo {
  width: 95%;
}

.infoIcon {
  padding: 2.5px 0;
}

.emailInviteForm {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.emailField {
  width: 100%;
  margin-bottom: 0px;
}

.mailIcon,
.closeIcon {
  color: rgb(191, 191, 191);
  width: 14px;
}

.closeIcon {
  cursor: pointer;
}

.inviteRole {
  min-width: 100px;
  font-size: 14px;
  margin-right: 1rem;
}

.inviteBoldInfo {
  font-weight: 500;
}

.infoIcon {
  color: blue;
  padding-right: 5px;
}

.inviteFormat {
  font-size: 12px;
  margin-bottom: 0;
}

.inviteFormatBox {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
}

.inviteEmail {
  width: 100%;
}

.resultContainer {
  display: flex;
  align-items: center;
}

.autoCompName,
.autoCompEmail {
  font-size: 12px;
  padding-left: 6px;
}

.autoCompEmail {
  color: rgb(132, 132, 132);
  font-weight: 300;
}

.autoCompName {
  font-weight: 400;
}

.upgradeMessage {
  margin-bottom: 1em;
}

.planLink {
  color: #406cff;
  line-height: 16px;
  font-weight: 600;
  padding: 0;
}

.ssoContainer {
  display: flex;
  background-color: #ECECF2;
  border-radius: 10px;
  padding: 10px;
}

.ssoContainer p {
  margin-bottom: 0;
}

.upgradeArrow {
  margin: 0 5px;
  width: 20px;
  position: relative;
}

.ssoText {
  font-weight: bold;
  color: #9232BC;
}

.upgradePopoverContainer {
  float: right;
  top: 20px;
  position: relative;
}

@media (max-width: 768px) {
  .emailInviteForm {
    flex-direction: column;
  }
}