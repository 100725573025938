.navItem {
  display: flex;
  align-items: center;
  width: fit-content;
  color: var(--navigationLink);
  min-width: 32px;
  overflow: hidden;
  transition: all 0.25s ease-in-out;
  font-weight: 800;
  width: 100%;
}

.itemAndCount {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  gap: 5px;
  position: relative;
}

.navItemLabel {
  overflow: hidden;
  text-overflow: ellipsis;
}

.count {
  background-color: #d9e7f4;
  color: #19222a;
  outline: 1px solid transparent;
  border-radius: 12px;
  font-size: 12px;
  padding: 0px 4px;
  display: block;
  height: 20px;
  line-height: 20px;
  text-align: center;
  box-shadow: 0 0 0 1px #ffffff;
  padding: 0 8px;
}

.active .navItem {
  background-color: var(--colorBgAccent);
  color: var(--colorTextBase);
  outline: 1px solid transparent;
}

.active .navItem:hover {
  background-color: var(--colorHoverNav);
}

.collapsed .navItem {
  justify-content: center;
  max-width: 30px;
}

.withDivider {
  margin-bottom: 0px;
}

.hideCount {
  visibility: hidden;
}

.collapsed .count {
  display: none;
}

@media (max-width: 640px) {
  .itemAndCount {
    display: none;
  }
}

.pulseIndicator {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  animation: pulse 2s infinite;
  position: absolute;
  top: 0px;
  left: -2px;
  z-index: 1;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
    scale: 0.75;
  }
  70% {
    box-shadow: 0 0 0 5px rgba(255, 0, 0, 0);
    scale: 1;
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    scale: 0.75;
  }
}
