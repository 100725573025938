@media (max-width: 520px) {
  .description {
    width: 150px;
  }
}

@media (min-width: 520px) {
  .description {
    width: 300px;
  }
}

.inputOuter {
  position: relative;
}

.hasFilter {
  margin-bottom: 5px;
}

.characterCount {
  opacity: 0;
  position: absolute;
  right: 0;
  bottom: -17px;
  font-size: 11px;
  color: #ccc;
  font-weight: 500;
  transition: opacity 0.2s ease;
}

.hasFilter .characterCount {
  opacity: 1;
}
