.tagIcon,
.newTagIcon {
  width: 18px;
  height: 18px;
}

.dropdown {
  position: absolute;
  max-height: 200px;
  overflow: hidden scroll;
  max-width: 300px;
  border-radius: 7px;
  padding: 4px;
  z-index: 100;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
  background-color: white;
}

.item {
  display: flex;
  align-items: center;
  padding: 0 5px;
  border-radius: 4px;
  cursor: pointer;
}

.item:hover,
.selected {
  background-color: #f5f5f5;
  color: inherit;
}

.active {
  background-color: #3f71e0;
  color: white;
}

.display {
  padding: 8px 4px 8px 0;
  max-width: 260px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 5px;
}

.severity-0 {
  color: #cccccc;
}

.severity-0:hover,
.selected.severity-0,
.active.severity-0 {
  background-color: #cccccc;
  color: white;
}

.severity-1 {
  color: #d46060;
}

.severity-1:hover,
.selected.severity-1,
.active.severity-1 {
  background-color: #d46060;
  color: white;
}

.severity-2 {
  color: #e8b019;
}

.severity-2:hover,
.selected.severity-2,
.active.severity-2 {
  background-color: #e8b019;
  color: white;
}

.severity-3 {
  color: #60c5d4;
}

.severity-3:hover,
.selected.severity-3,
.active.severity-3 {
  background-color: #60c5d4;
  color: white;
}

.severity-4 {
  color: #8bb863;
}

.severity-4:hover,
.selected.severity-4,
.active.severity-4 {
  background-color: #8bb863;
  color: white;
}
