.attachIcon {
  width: 12px;
  height: 12px;
  color: #666;
  margin-right: 5px;
}

.removeIcon {
  width: 16px;
  height: 16px;
  color: #666;
}

.removeIcon:hover {
  cursor: pointer;
  stroke: #666;
}

.container {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.listInfoBox {
  position: relative;
  width: calc(100% - 17px);
  display: flex;
  justify-content: space-between;
}

.nameAndPreviewBox {
  display: flex;
  width: 90%;
}

.fileNameBox {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.linkContainer {
  margin-left: 5px;
}

.outerProgress {
  position: absolute;
  width: 100%;
  border-radius: 1.5px;
  height: 3px;
  background-color: #ccc;
  top: 22px;
  left: 0px;
}

.innerProgress {
  background-color: #20c0e7;
  border-radius: 1px;
  height: 3px;
}

.completing {
  background-color: #5ec82e;
}
.completed {
  display: none;
}
