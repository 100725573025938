.attachmentsOuter {
  display: flex;
  flex-direction: column;
}

.upload {
  width: 100%;
}

.uploadButton .uploadIcon {
  width: 12px;
  margin-right: 4px;
}

.uploadButton {
  border: 1px dashed rgba(1, 1, 1, 0.2);
  cursor: pointer;
  background-color: rgb(248, 248, 248);
  max-width: 90px;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.uploadButton:hover {
  opacity: unset;
  background-color: white;
  color: rgb(0, 0, 0);
}

.attachmentsInner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 4px 0 0 0;
  border-bottom: 1px solid #dbe3e6;
  width: 100%;
  min-height: 24px;
}

.innerContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.attachIcon {
  min-width: 12px;
  width: 12px;
  margin-right: 4px;
  transform: rotate(-90deg);
}

.tag {
  display: flex;
  align-items: center;
  max-width: 100%;
  margin-bottom: 4px;
}

.tag:hover {
  opacity: unset;
  background-color: white;
  color: rgb(0, 0, 0);
}

.tagContent {
  display: flex;
  align-items: center;
  text-decoration: none;
  max-width: calc(100% - 12px);
}

.attachmentsBox {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.filename {
  display: block;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.overlayBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background: #f8f8f8;
  border-radius: 3px;
  border: 1px dashed #ccc;
  text-align: center;
  padding: 20px;
  margin: 4px 0;
}

.dragText {
  margin: 0;
}
.overlayIcon {
  margin: 0 auto;
}

.draggingAttachments {
  padding-bottom: 30px;
}

.alert {
  margin-bottom: 4px;
  width: 100%;
}

.previewLink {
  text-decoration: none;
  max-width: calc(100% - 15px);
}

.alert {
  margin: 2px 0 4px 0;
}

.upload :global(.ant-upload) {
  width: 100%;
}

.uploading {
  position: relative;
  max-width: 100%;
}

.progressOuter {
  width: 100%;
  position: absolute;
  bottom: 1px;
  left: 1px;
  height: 2px;
}

.progressInner {
  background-color: #3f71e0;
  height: 100%;
}

.almost {
  background-color: #5ec82e;
}

.loaderIcon {
  margin-right: 5px;
  width: 14px;
  height: 14px;
}

.videoElement {
  max-width: 100%;
  max-height: 100%;
}

.previewContainer {
  display: none;
}

.notImage {
  max-width: 80vw;
  max-height: 80vh;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tableContainer {
  max-width: 90vw;
  max-height: 90vh;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: white;
}

.csvTable {
  width: 100%;
  border-collapse: collapse;
  font-size: 1em;
  font-family: 'Arial', sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.csvTable thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
  position: sticky;
  top: 51px;
  z-index: 1;
}

.csvTable th,
.csvTable td {
  padding: 12px 15px;
  border: 1px solid #dddddd;
}

.csvTable tbody tr {
  border-bottom: 1px solid #dddddd;
}

.csvTable tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.csvTable tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.csvTable tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

.paginationControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  background: white;
}

.paginationControls label {
  margin-right: 10px;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.perPageLabel {
  margin-left: 10px;
  margin-right: 30px;
}

.controlButton {
  margin-left: 10px;
}

.jsonContainer {
  max-height: 80vh;
  width: 80vw;
  overflow: auto;
  text-align: left;
}

.tooltipPreview {
  width: 200px;
  height: 115px;
  position: relative;
  cursor: pointer;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tooltipPreview img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay:hover .playCircle {
  transform: scale(1.1);
}

.overlay:hover {
  background-color: #00000059;
}

.playCircle {
  position: absolute;
  width: 55px;
  height: 55px !important;
  border-radius: 50%;
  padding: 4px;
  background-color: #ffffff5e;
  transition: transform 0.25s ease-in-out !important;
  z-index: 1;
}

.innerCircle {
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100% !important;
}

.playIcon {
  width: 40%;
  height: 40%;
  fill: black;
}

.previewLoaderIcon {
  width: 40%;
  height: 40%;
}

.toolbar {
  position: fixed;
  display: flex;
  bottom: 32px;
  inset-inline-start: 50%;
  padding: 12px;
  color: #fff;
  font-size: 20px;
  background-color: black;
  border-radius: 100px;
  transform: translateX(-50%);
  gap: 12px;
}

.toolbarIcon {
  width: 20px;
  height: 20px;
  color: white;
  margin: 0 12px;
}

.overlay button svg {
  width: 14px;
  height: 14px;
}

.textPreview {
  width: 100%;
  height: 100%;
  background: white;
  font-family: courier;
  text-align: left;
  padding: 10px;
  border-radius: 10px;
  overflow: auto;
}

.toolbarIconContainer {
  position: relative;
  cursor: pointer;
}

.toolbarIconContainer:not(.disabled):hover .toolbarIcon {
  color: #3f71e0;
}

.toolbarIconContainer.disabled,
.toolbarIconContainer.disabled a {
  cursor: not-allowed;
}

.toolbarIconContainer.disabled .toolbarIcon {
  color: #ccc;
}

.toolbarIconTooltip {
  display: none;
  position: absolute;
  bottom: 45px;
  left: 50%;
  background: black;
  color: white;
  padding: 8px;
  border-radius: 5px;
  font-size: 12px;
  transform: translateX(-50%);
  width: max-content;
}
.toolbarIconTooltip code {
  padding: 2px 2px;
  color: #faad14;
  background-color: rgba(25, 34, 42, 0.88);
  border: 1px solid #373737;
  white-space: nowrap;
  font-size: 12px;
}

.toolbarIconTooltip::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  border: 6px solid transparent;
  border-top-color: black;
  transform: translateX(-50%);
}

.toolbarIconContainer:hover .toolbarIconTooltip {
  display: block;
}
